import {
  ClosingSequence,
  isMobileDevice,
  toastNotSignedIn,
} from "./helpers/utils";
import { useHistory, useLocation, useParams } from "react-router-dom";

import AuxiliaryContent from "./AuxiliaryContent";
import Content from "./Content";
import HoverBar from "./HoverBar";
import PremiumLockScreen from "./PremiumLockScreen";
import React from "react";
import ReactMarkdown from "react-markdown";
import Video from "./Video";
import { parseSSEStream } from "./helpers/utils";
import { useSelector } from "react-redux";

const ScreenRenderer = (props) => {
  const challenge = useSelector((state) => state.challenge);
  const companies = useSelector((state) => state.companies);
  const lesson = useSelector((state) => state.lesson);
  const notes = useSelector((state) => state.notes);
  const user = useSelector((state) => state.user);

  const { randomAdScreen, screen, screens } = props;

  const currLocation = useLocation();
  const tutorialType = currLocation.pathname.includes("lessons")
    ? "lessons"
    : "challenges";

  let history = useHistory();
  let { tutorialSlug } = useParams();

  if (screen.kind == "locked") {
    return <PremiumLockScreen />;
  }

  const normalizedSlug = screen.slug.replace(/[^a-zA-Z0-9_-]/g, "");
  const screenSlugs = screens.map((s) => s.slug);

  const [isThinking, setIsThinking] = React.useState(false);

  const [aiResp, setAiResp] = React.useState("");
  const [chatResp, setChatResp] = React.useState("");
  const [showHoverBar, setShowHoverBar] = React.useState(false);
  // const [testPrompt, setTestPrompt] = React.useState(
  //   [
  //     "We'll run these tests to check your solution.",
  //     "We should test our code! Here's what we've got.",
  //     "Curious how we'll test? Here's how.",
  //   ][Math.floor(Math.random() * 3)]
  // );
  const [videoPrompt, setVideoPrompt] = React.useState(
    [
      "Here's a video of us explaining the solution.",
      "Tired of reading? Watch this video explanation!",
    ][Math.floor(Math.random() * 2)]
  );
  const [walkthroughPrompt, setWalkthroughPrompt] = React.useState(
    [
      "Here's our guided, illustrated walk-through.",
      "We'll now take you through what you need to know.",
      "Here's how we would solve this problem...",
    ][Math.floor(Math.random() * 3)]
  );

  const CHAT_PLACEHOLDERS = [
    "What is the time complexity of this algorithm?",
    "What is the space complexity of this algorithm?",
    "What is the Big O of this algorithm?",
    "Give me a question to test my understanding of this passage's content.",
    "Translate this to Spanish.",
    "I don't get it, can you break this down for me?",
    "Can you explain this in a different way?",
    "Can you give me an example of this?",
    "Can you give me a real-world example of this?",
  ];

  const [chatPlaceholder, setChatPlaceholder] = React.useState(
    `Ask a question about the above (e.g. ${
      CHAT_PLACEHOLDERS[Math.floor(Math.random() * CHAT_PLACEHOLDERS.length)]
    })`
  );

  const sendMsg = async (kind, screen_id, message) => {
    let setterFunc;
    if (kind === "ai") {
      setterFunc = setAiResp;
    } else {
      setterFunc = setChatResp;
    }
    setIsThinking(() => true);
    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;

      const resp = fetch("/chat_prompt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          kind: "inline",
          message,
          previous_msg: chatResp || "",
          screen_id: screen_id,
        }),
      }).then(async (response) => {
        if (response.status === 401) {
          toastNotSignedIn("get more help");
          return;
        }

        if ((kind === "chat" && chatResp) || (kind === "ai" && aiResp)) {
          setterFunc("");
        }

        const reader = response.body.getReader();

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setIsThinking(() => false);
            console.log("finished streaming");
            break;
          }

          // Convert Uint8Array to string and decode it
          const s = new TextDecoder().decode(value);
          const text = parseSSEStream(s);
          setterFunc((prev) => prev + text); // Update state correctly
        }
      });
    } catch (err) {
      setIsThinking(() => false);

      console.error(err);
    }
  };

  const aiRespBox = () => {
    return aiResp ? (
      <div
        className="w-100 alert alert-primary"
        id={`${screen.id}-ai-response`}
        role="alert"
      >
        {/* render chatResp as markdown */}
        <Content
          screen={{
            content: aiResp,
            kind: "info screen",
            full_screen: true,
            slug: "ai-response",
          }}
          isAdScreen={false}
          isFirstScreen={false}
          runCode={props.runCode}
          tutorialSlug={tutorialSlug}
        />
      </div>
    ) : null;
  };

  const chatInline = () => {
    return (
      <>
        {/* show the response from the AI in a pretty box */}
        {chatResp ? (
          <div
            className="w-100 alert alert-primary"
            id={`${screen.id}-chat-response`}
            role="alert"
          >
            {/* render chatResp as markdown */}
            <Content
              screen={{
                content: chatResp,
                kind: "info screen",
                full_screen: true,
                slug: "chat-response",
              }}
              isAdScreen={false}
              isFirstScreen={false}
              runCode={props.runCode}
              tutorialSlug={tutorialSlug}
            />
          </div>
        ) : null}
        {showHoverBar ? (
          // full width input field like a chat box to ask questions about the above content
          <div className="w-100 input-group pb-3">
            <input
              type="text"
              className="form-control"
              id={screen.id + "-chat"}
              placeholder={chatPlaceholder}
              aria-label={chatPlaceholder}
              aria-describedby="basic-addon2"
              // make it styled so it looks like just a line
              style={{
                border: "none",
                borderBottom: "1px solid #ced4da",
                borderRadius: "0",
                background: "transparent",
              }}
              // handle enter button to send
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMsg("chat", screen.id, e.target.value);
                }
              }}
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={() => {
                  sendMsg(
                    "chat",
                    screen.id,
                    document.getElementById(screen.id + "-chat").value
                  );
                }}
              >
                {isThinking ? (
                  <i className="fas fa-spinner"></i>
                ) : (
                  <i className="fas fa-paper-plane"></i>
                )}
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return screen.full_screen ? (
    <section
      id={normalizedSlug}
      className={`mb-4 w-100 lesson-screen screens-container position-relative ${
        ["multiple choice", "swipe", "order", "fill in"].includes(screen.kind)
          ? "accent-container"
          : ""
      } ${showHoverBar ? "bg-light" : ""}`}
      key={normalizedSlug}
      onMouseOver={(e) => setShowHoverBar(true)}
      onMouseLeave={(e) =>
        // if not scrolling, setHoverBar
        setShowHoverBar(false)
      }
    >
      {showHoverBar ? (
        <HoverBar
          screen={screen}
          user={user}
          tutorialSlug={tutorialSlug}
          tutorialType={tutorialType}
          lesson={lesson}
          challenge={challenge}
          notes={notes}
          sendMsg={sendMsg}
        />
      ) : null}
      {aiRespBox()}
      <Content
        screen={screen}
        isAdScreen={randomAdScreen == screen}
        isFirstScreen={screens[0] == screen}
        runCode={props.runCode}
        tutorialSlug={tutorialSlug}
      />
      <ClosingSequence
        isLastScreen={screens[screens.length - 1] == screen}
        challenge={challenge}
        tutorialSlug={tutorialSlug}
      />
      {chatInline()}
    </section>
  ) : (
    <section
      id={normalizedSlug}
      className={`mb-4 screens-container position-relative ${
        ["multiple choice", "swipe", "order", "fill in"].includes(screen.kind)
          ? "accent-container"
          : ""
      } ${showHoverBar ? "bg-light" : ""}`}
      key={normalizedSlug}
      onMouseOver={(e) => setShowHoverBar(true)}
      onMouseLeave={(e) => setShowHoverBar(false)}
    >
      {showHoverBar ? (
        <HoverBar
          screen={screen}
          user={user}
          tutorialSlug={tutorialSlug}
          tutorialType={tutorialType}
          lesson={lesson}
          challenge={challenge}
          notes={notes}
          sendMsg={sendMsg}
        />
      ) : null}
      {aiRespBox()}
      <Content
        challenge={challenge}
        companies={companies}
        isAdScreen={randomAdScreen == screen}
        isFirstScreen={screens[0] == screen}
        isLastScreen={screens[screens.length - 1] == screen}
        nextArr={props.nextArr}
        jsSolutionCode={props.jsSolutionCode}
        solutionCode={challenge ? challenge.solutionCode : ""}
        screen={screen}
        screenSlugs={screenSlugs}
        revealSolution={(e) => {
          e.preventDefault();
          setScreenView("solution");
        }}
        tutorialSlug={tutorialSlug}
      />
      {!isMobileDevice() && screen.kind === "question" ? (
        <>
          <h3 className="font-weight-bold mt-4">
            Try to solve this here or in <em>Interactive Mode</em>.
          </h3>
          <p className="small cursor-pointer">
            <strong
              data-tip="<div style='max-width: 24rem;'>Our editors contain the boilerplate and test cases required. We allow you the flexibility to play with the code as much as you want. <br /><br /><strong>Interactive Mode</strong> gives you the traditional
                  multi-panel coding exercise layout found on many sites. If you
                  prefer slides, more space, or easier access to the tests and
                  solutions, switch over. Any edits below will be saved to local
                  storage on modern browsers.</div>"
              data-html={true}
            >
              How do I practice this challenge?{" "}
              <i className="fas fa-info-circle"></i>
            </strong>
          </p>
          <div className="row mb-4">
            <div className={challenge.video ? "col-6" : "col-12"}>
              <button
                type="button"
                className="btn shadow bg-gray small rounded-lg px-4 py-2 w-100"
                onClick={() =>
                  history.push(`/challenge_slides/${tutorialSlug}`)
                }
              >
                Solve in Interactive
              </button>
            </div>

            {challenge && challenge.video ? (
              <div className="col-6">
                <button
                  type="button"
                  className="btn shadow bg-gray small rounded-lg px-4 py-2 w-100"
                  onClick={() =>
                    history.push(
                      `/challenges/${tutorialSlug}/video?scrollToTop=true`
                    )
                  }
                >
                  Watch Solution Video
                </button>
              </div>
            ) : null}
          </div>
        </>
      ) : null}
      {screen.kind !== "info screen" || (screen.code && screen.code.length) ? (
        <AuxiliaryContent
          editorVal={props.editorVal}
          runCode={props.runCode}
          screen={screen} // this should toggle between lesson code and my code
          submitChallengeEdit={props.submitChallengeEdit}
        />
      ) : null}
      {chatInline()}
      {screen.kind === "question" && challenge && challenge.video ? (
        <>
          <h3 className="font-weight-bold mt-4">{videoPrompt}</h3>
          <p className="small">
            To change the speed of the video or see it in full screen, click the
            icons to the right of the progress bar.
          </p>
          <Video video={challenge.video} user={user} />
        </>
      ) : null}
      {screen.kind === "question" ? (
        <>
          <h3 className="font-weight-bold pt-4">{walkthroughPrompt}</h3>
          <p className="small cursor-pointer">
            <strong
              data-tip="<div style='max-width: 24rem;'>We'll do our best to explain this problem in a visual,
              understandable manner. You can also see these steps as slides in <strong>Interactive Mode</strong>.<br /><br />If you ever get confused, please leave a comment or your questions in the accompanying <strong>Discussion</strong> by using the button by the tutorial title. If you think
              this can be better, you can leave feedback in any of the submenus by hovering over the content. We'll do our best to
              address your needs as soon as possible.</div>"
              data-html={true}
            >
              How do I use this guide? <i className="fas fa-info-circle"></i>
            </strong>
          </p>
        </>
      ) : null}
      <ClosingSequence
        isLastScreen={screens[screens.length - 1] == screen}
        challenge={challenge}
        tutorialSlug={tutorialSlug}
      />
    </section>
  );
};

export default ScreenRenderer;
