import React from "react";
import { toastNotSignedIn } from "./helpers/utils";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default (props) => {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    const tutorialNavContainer = document.getElementById(
      "tutorial-nav-container"
    );
    if (tutorialNavContainer) {
      tutorialNavContainer.style.width =
        document.getElementById("tutorial-menu-pillar").clientWidth.toString() +
        "px";
    }
  });

  return (
    <div
      id="tutorial-nav-header"
      className="w-100 d-flex justify-content-start align-items-center border-bottom px-4 pt-4 pb-0 cursor-pointer"
    >
      <i className="fa fa-bookmark mr-2" />{" "}
      <h3
        className="py-2 my-0"
        onClick={() => {
          user
            ? history.push("/bookmarks")
            : toastNotSignedIn("save and use bookmarks");
        }}
      >
        Bookmarks
      </h3>
    </div>
  );
};
