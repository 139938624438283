import LessonCode from "../AuxiliaryContent/LessonCode";
import React from "react";
import { toast } from "react-toastify";

const removeMd = require("remove-markdown");

export function isMobileDevice() {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
}

export function extractOnlyText(s) {
  return removeMd(s).trim();
}

export function determineCanonicalUrl(newLang, challenge) {
  // For now, let's make it URL based and not local-storage
  let currLoc = window.location.href;
  currLoc = currLoc.replace("/daily", "/" + challenge.slug);

  // handling replacing langs
  const CHALLENGE_URL_REGEX = /(\/(?:challenges|lessons|codemode|collaborate|challenge_slides|lesson_slides)\/[\w\-]{2,})(\/javascript|\/python|\/java|\/ruby|\/go|\/cpp|\/csharp|\/php)?(\/.*)?/g;
  const matches = CHALLENGE_URL_REGEX.exec(currLoc);

  if (matches[2] && matches[3]) {
    // has language and screen in URL
    return `${matches[1]}/${newLang}${matches[3]}`;
  } else if (matches[2]) {
    // has language and no screen in URL
    return `${matches[1]}/${newLang}`;
  } else if (matches[1] && matches[3]) {
    // has no language and a screen in URL
    return `${matches[1]}/${newLang}${matches[3]}`;
  } else {
    // has no language and no screen in URL
    // TODO fix
    return `${matches[0]}/${newLang}`;
  }
}

export function extractCodeFromMd(code) {
  const matches = /\`\`\`\w{0,10}\n([\s\S]*?)\`\`\`/g.exec(code);
  if (matches && matches.length && matches.length > 1) {
    return matches[1].trim();
  } else {
    return code.trim();
  }
}

export function extractLangFromCodeBlock(code) {
  const matches = /\`\`\`(\w{0,10})\n([\s\S]*?)\`\`\`/g.exec(code);
  if (matches) {
    return matches[1] && matches[1].toLowerCase();
  } else {
    return code;
  }
}

export function getUrlLanguage() {
  // Must match first / before but ending / is optional
  const href = window.location.href;

  if (href.includes("_slides")) {
    // slides put lang in middle like /lesson_slides/:tutorialSlug/:language/:screenSlug
    // match either /python/ or /python if end of line
    const matches = href.match(
      /(?!-)(\/python(\/|$)|\/javascript(\/|$)|\/java(\/|$)|\/ruby(\/|$)|\/go(\/|$)|\/cpp(\/|$)|\/csharp(\/|$)|\/php(\/|$))(?!-)/
    );

    if (matches && matches.length && matches.length > 1) {
      return matches[0].replace(/\//g, "");
    }
  } else {
    const matches = href.match(
      /(?!-)\/(python|javascript|java|ruby|go|cpp|csharp|php)\/?(?!-)/
    );

    if (matches && matches.length && matches.length > 1) {
      return matches[0].replace(/\//g, "");
    }
  }

  return "";
}

export const LANG_TO_CM_MODE_MAP = {
  js: "javascript",
  javascript: "javascript",
  py: "python",
  python: "python",
  java: "text/x-java",
  C: "text/x-csrc",
  c: "text/x-csrc",
  cs: "text/x-csharp",
  csharp: "text/x-csharp",
  cpp: "text/x-c++src",
  "c++": "text/x-c++src",
  go: "go",
  ruby: "ruby",
  sql: "text/x-sql",
  text: "text",
  php: "text/x-php",
  ts: "text/typescript",
  typescript: "text/typescript",
};

export const MD_MODE_TO_LANG_MAP = {
  js: "javascript",
  javascript: "javascript",
  py: "python",
  python: "python",
  "text/x-java": "java",
  java: "java",
  c: "c",
  cpp: "cpp",
  "c++": "cpp",
  "text/x-csrc": "c",
  "text/x-csharp": "csharp",
  csharp: "csharp",
  cs: "csharp",
  go: "go",
  ruby: "ruby",
  sql: "sql",
  php: "php",
  text: "text",
  ts: "typescript",
  typescript: "typescript",
};

export const ALGO_LANG_ID_TO_JUDGE_MAP = {
  34: "python",
  29: "javascript",
  26: "java",
  22: "go",
  38: "ruby",
  10: "cpp",
  16: "csharp",
  68: "php",
};

export const LANG_TO_ALGODAILY_ID = {
  go: 22,
  java: 26,
  javascript: 29,
  python: 34,
  ruby: 38,
  cpp: 10,
  csharp: 16,
  cpp: 10,
  php: 68,
};

export const commentsMap = {
  javascript: "//",
  python: "#",
  java: "//",
  go: "//",
  ruby: "#",
  cpp: "//",
  php: "//",
};

export function titleize(sentence) {
  const acronyms = ["php", "css", "sql", "js", "ts"];

  if (!sentence.split) return sentence;
  var _titleizeWord = function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    result = [];

  // handle acronyms
  if (acronyms.includes(sentence)) {
    return sentence.toUpperCase();
  }

  sentence.split(" ").forEach(function (w) {
    result.push(_titleizeWord(w));
  });
  return result.join(" ");
}

export function sqlToJsDate(date) {
  const jsDate = date.replace(" ", "T");
  return new Date(jsDate)
    .toISOString()
    .slice(0, 19)
    .replace(/-/g, "/")
    .replace("T", " ");
}

export function getCompletionsFromLocalStorage(completed, guide, screens) {
  let completions = Array(screens.length).fill(false);

  if (completed) {
    return Array(screens.length).fill(true);
  }

  if (localStorage) {
    for (let i = 0; i < screens.length; i++) {
      const screen = screens[i];
      completions[i] =
        localStorage.getItem(`${guide.slug}-${screen.slug}`) || false;
    }
    return completions;
  }

  return Array(screens.length).fill(false);
}

export function wipeCompletionsFromLocalStorage(guide, screens) {
  if (localStorage && screens && screens.length) {
    for (let i = 0; i < screens.length; i++) {
      const screen = screens[i];
      localStorage.removeItem(`${guide.slug}-${screen.slug}`);
    }
  }
}

export function greetingByDate(user) {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 12) {
    return `Good morning${user && user.name ? `, ${user.name}` : ""}!`;
  } else if (curHr < 18) {
    return `Good afternoon${user && user.name ? `, ${user.name}` : ""}!`;
  } else {
    return `Good evening${user && user.name ? `, ${user.name}` : ""}!`;
  }
}

export function levelByDifficulty(diff) {
  if (diff > 0 && diff < 4) {
    return <span className="small text-success">Easy</span>;
  } else if (diff < 7) {
    return <span className="small text-warning">Medium</span>;
  } else {
    return <span className="small text-danger">Hard</span>;
  }
}

export function ClosingSequence(props) {
  const { isLastScreen, challenge, tutorialSlug } = props;

  const [concludingPrompt, setConcludingPrompt] = React.useState(
    [
      "That's all we've got! Let's move on to the next tutorial.",
      "Great job getting through this. Let's move on.",
      "Alright, well done! Try another walk-through.",
      "You're doing a wonderful job. Keep going!",
      "Got more time? Let's keep going.",
    ][Math.floor(Math.random() * 5)]
  );

  return isLastScreen && challenge && challenge.solutionCode ? (
    <>
      <h3 className="font-weight-bold mt-4">This is our final solution.</h3>
      <p className="small">
        To visualize the solution and step through the below code, click{" "}
        <strong>Visualize the Solution</strong> on the right-side menu or the{" "}
        <strong>VISUALIZE</strong> button in <em>Interactive Mode</em>.
      </p>
      <LessonCode
        challenge={challenge}
        isTestSubmission={true}
        language={extractLangFromCodeBlock(challenge.solutionCode)}
        screenCode={challenge.solutionCode}
      />
      {/* <CodeSnippet
        languageCode={extractLangFromCodeBlock(challenge.solutionCode)}
        code={extractCodeFromMd(challenge.solutionCode)}
      /> */}
      <h3 className="font-weight-bold">{concludingPrompt}</h3>
      <p>
        If you had any problems with this tutorial, check out{" "}
        <a href={`/forum/threads/${tutorialSlug}`}>
          the main forum thread here
        </a>
        .
      </p>
    </>
  ) : null;
}

export function postCompletion(
  tutorialType,
  tutorialSlug,
  challengeBody,
  callback
) {
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  if (tutorialType === "challenges") {
    try {
      fetch("/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: challengeBody,
      }).then(callback);
    } catch (e) {
      console.log(e);
    }
  } else if (tutorialType === "lessons") {
    return fetch(`/api/${tutorialType}/${tutorialSlug}/read`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then(callback);
  }
}

export const LANG_TO_BOILERPLATE_MAP = {
  js: `// No Javascript sample provided for this snippet.
// Use this to test your own JS code.

console.log("hello, world");`,
  javascript: `// No Javascript sample provided for this snippet.
// Use this to test your own JS code.

console.log("hello, world");`,
  py: `# No Python sample provided for this snippet.
# Use this to test your own Python code.

print("hello, world")`,
  python: `# No Python sample provided for this snippet.
# Use this to test your own Python code.

print("hello, world")`,
  java: `// No Java sample provided for this snippet.
// Use this to test your own Java code.

public class Main {
  public static void main(String[] args) {
      System.out.println("hello, world");
  }
}`,
  C: "text/x-csrc",
  c: "text/x-csrc",
  csharp: `// No C# sample provided for this snippet.
// Use this to test your own C# code.
  
class Program {         
  static void Main(string[] args)
  {
    System.Console.WriteLine("Hello World!");
  }
}
`,
  cpp: `// No C++ sample provided for this snippet.
// Use this to test your own C++ code.

#include <iostream>

int main() {
    std::cout << "hello, world" << std::endl;
    return 0;
}
  `,
  go: `// No Go sample provided for this snippet.
// Use this to test your own Go code.

package main

import "fmt"

func main() {
    fmt.Println("hello, world")
}
  `,
  ruby: `# No Ruby sample provided for this snippet.
# Use this to test your own Go code.

puts "hello, world"`,
  sql: "text/x-sql",
  text: "text",
};

export const LANGUAGES = [
  "code",
  "bash",
  "c",
  "c++",
  "cpp",
  "c#",
  "clojure",
  "cs",
  "css",
  "elixir",
  "erlang",
  "go",
  "haskell",
  "java",
  "js",
  "javascript",
  "kotlin",
  "lua",
  "objective-c",
  "perl",
  "php",
  "python",
  "r",
  "ruby",
  "rust",
  "scala",
  "shell",
  "sql",
  "swift",
  "ts",
  "typescript",
  "text",
];

export function loadLanguageUtils(language) {
  if (!language) {
    return;
  }

  if (["javascript", "js", "ts", "typescript"].includes(language)) {
    require("codemirror/mode/javascript/javascript");
    require("codemirror/addon/hint/javascript-hint");
  } else if (
    ["cpp", "java", "c", "csharp", "c++", "cs", "objective-c", "c#"].includes(
      language
    )
  ) {
    require(`codemirror/mode/clike/clike`);
  } else if (["python", "py"].includes(language)) {
    require(`codemirror/mode/python/python`);
  } else if (["code", "text", "bash"].includes(language)) {
    require(`codemirror/mode/markdown/markdown`);
  } else if (LANGUAGES.includes(language)) {
    require(`codemirror/mode/${language}/${language}`);
  }
}

export function readjustForSlides() {
  if (window.location.pathname.includes("_slides")) {
    // adjust height of aux content
    const navHeight =
      (document.getElementById("algodaily-nav") &&
        document.getElementById("algodaily-nav").clientHeight) ||
      0;
    const outputContainer =
      (document.getElementById("curr-output-container") &&
        document.getElementById("curr-output-container").offsetHeight + 8) ||
      8; // 8 for gutter
    const footerHeight =
      (document.getElementById("algodaily-footer") &&
        document.getElementById("algodaily-footer").clientHeight) ||
      0;
    const toolbarHeight =
      (document.getElementById("toolbar") &&
        document.getElementById("toolbar").clientHeight) ||
      0;

    const editor = document.querySelector("#aux-content .CodeMirror");
    if (editor) {
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      editor.style.height =
        vh -
        (navHeight + footerHeight + toolbarHeight + outputContainer) +
        "px";
      editor.style.setProperty("border-radius", "0px", "important");
      document.querySelector(".container-with-top").style.marginTop =
        navHeight + "px";
    }

    document.getElementById("content-container") &&
      document
        .getElementById("content-container")
        .style.setProperty("font-size", "1.1rem", "important");

    document.getElementById("screens-container") &&
      document
        .getElementById("screens-container")
        .style.setProperty("font-size", "1.1rem", "important");

    const mainContent = document.querySelector(".screens-container");
    const splitScreen = document.querySelector(".split-screen");
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );

    if (mainContent) {
      mainContent.style.height = vh - (navHeight + footerHeight) + "px";
    }
    if (splitScreen) {
      splitScreen.style.height = vh - (navHeight + footerHeight) + "px";
    }

    window.scrollTo(0, 0);
    $("#content-container").scrollTop(0);
  }
}

export function strToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export function adjustForNav() {
  const containerWithTop = document.querySelector(".container-with-top");
  const navHeight = document.getElementById("algodaily-nav")?.clientHeight;
  if (containerWithTop && navHeight) {
    containerWithTop.style.marginTop = navHeight + "px";
  }
}

export function shuffle(arr) {
  let array = [...arr];
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function toastNotSignedIn(action) {
  return toast.error(
    <span>
      Please{" "}
      <a href="/users/sign_in" target="_blank">
        sign in
      </a>{" "}
      or{" "}
      <a href="/users/sign_up" target="_blank">
        sign up
      </a>{" "}
      to {action}!
    </span>,
    {
      toastId: action,
    }
  );
}

export function tutorialContainerSize(courseCurriculumOpen, screenListOpen) {
  let _tutorialContainerSize = "col-lg-7";
  if (
    courseCurriculumOpen &&
    // check TutorialMenu
    !screenListOpen
  ) {
    _tutorialContainerSize = "col-lg-9";
  } else if (!courseCurriculumOpen && screenListOpen) {
    _tutorialContainerSize = "col-lg-10";
  } else if (!courseCurriculumOpen && !screenListOpen) {
    _tutorialContainerSize = "col-lg-12";
  }
  return _tutorialContainerSize;
}

export function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export function parseSSEStream(stream) {
  const events = stream.split("event: token\n").filter(Boolean); // Split and filter out empty strings
  let message = "";

  for (const event of events) {
    const dataMatch = event.match(/data: (.+)/);
    if (dataMatch && dataMatch[1]) {
      const data = dataMatch[1].trim();

      // If the data starts with a '+', it indicates a space should be added before it
      // if (data.startsWith("+")) {
      //   message += " " + decodeURIComponent(data.substring(1));
      // } else {
      message += decodeURIComponent(data.replace(/\+/g, " "));
      // }
    }
  }

  return message;
}
