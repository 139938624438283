import "./FlashCards.css";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  extractOnlyText,
  isMobileDevice,
  toastNotSignedIn,
} from "./helpers/utils";

import CodeSnippet from "./CodeSnippet";
import { Helmet } from "react-helmet";
import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import remarkDisableTokenizers from "remark-disable-tokenizers";
import { useSelector } from "react-redux";

const promptRenderer = {
  text: (props) => {
    return <span style={{ fontSize: "2rem" }}>{props.value}</span>;
  },
  code: (pr) => <CodeSnippet languageCode={pr.language} code={pr.value} />,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const FlashCards = () => {
  const [flipped, setFlipped] = React.useState(false);
  const [flashCard, setFlashCard] = React.useState({
    front: "Loading...",
    back: "Loading...",
  });
  const [next, setNext] = React.useState({});
  const [queryFilters, setQueryFilters] = React.useState([]);
  const [queryFiltersList, setQueryFiltersList] = React.useState([]);
  const [taggings, setTaggings] = React.useState([]);

  const user = useSelector((state) => state.user);

  let { flashCardSlug } = useParams();
  let query = useQuery();
  let history = useHistory();

  function adjustQueryParams() {
    let newQueryFilters = query.get("filters");
    if (newQueryFilters) {
      const splitQueryFilters = newQueryFilters.replace(/,,/g, ",").split(",");
      if (
        queryFilters &&
        splitQueryFilters.length &&
        JSON.stringify(queryFilters.sort()) !==
          JSON.stringify(splitQueryFilters.sort())
      ) {
        setQueryFilters(splitQueryFilters);
        setQueryFiltersList(Array.from(splitQueryFilters));
        fetchFC();
      }
    }
  }

  const fetchFC = () => {
    const flashCardPath =
      queryFilters && queryFilters.length
        ? `${flashCardSlug}/${queryFilters}`
        : flashCardSlug;
    const randomPath =
      queryFilters && queryFilters.length ? `random/${queryFilters}` : "random";

    fetch(`/api/flash_cards/${flashCardPath || randomPath}`)
      .then((res) => {
        // Stream response object stream for status
        if (!res.ok) {
          console.log("throwing");
          throw res;
        }
        return res.json();
      })
      .then((obj) => {
        setFlashCard(obj);
        setFlipped(false);
        setNext(obj.next);
      })
      .catch((e) => {
        return history.push("/flash-cards/random");
      });
  };

  React.useEffect(() => {
    // MAJOR HACK TO FIX GLOBAL MENU HIGHLIGHT
    document.getElementById("courseDropdownLi") &&
      document.getElementById("courseDropdownLi").classList.remove("active");
    document.getElementById("toolsDropdownLi") &&
      document.getElementById("toolsDropdownLi").classList.add("active");

    // Get taggings
    fetch("/api/flash_cards/taggings")
      .then((res) => {
        // Stream response object stream for status
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((obj) => {
        setTaggings(obj);
      });

    try {
      adjustQueryParams();
      fetchFC();
    } catch (e) {
      console.log(e);
    }
  }, []);

  React.useEffect(() => {
    try {
      fetchFC();
    } catch (e) {
      console.log(e);
    }
  }, [flashCardSlug]);

  React.useEffect(() => {
    adjustQueryParams();
  });

  return (
    <>
      <Helmet>
        <title>
          {(flashCard && flashCard.front && "AlgoDaily - " + flashCard.front) ||
            "Flash cards for coding interviews, free questions. Programming interview questions for software engineers."}
        </title>
        <meta
          name="description"
          content={
            (flashCard &&
              flashCard.back &&
              extractOnlyText(flashCard.back.slice(0, 200))) ||
            "Flash cards for coding interviews, free questions. Programming interview questions for software engineers."
          }
        />
        <meta
          property="og:url"
          content={
            (flashCard &&
              flashCard.back &&
              `https://algodaily.com/flash-cards/${flashCard.slug}`) ||
            "https://algodaily.com"
          }
        />
        <meta
          property="og:title"
          content={
            (flashCard &&
              flashCard.front &&
              "AlgoDaily - " + flashCard.front) ||
            "Flash cards for coding interviews, free questions. Programming interview questions for software engineers."
          }
        />
        <meta
          property="og:description"
          content={
            (flashCard &&
              flashCard.back &&
              extractOnlyText(flashCard.back.slice(0, 200))) ||
            "Flash cards for coding interviews, free questions. Programming interview questions for software engineers."
          }
        />
        <meta
          property="og:image"
          content={`https://algodaily.com/img/socialshare-${parseInt(
            Math.random() * 8
          )}.png`}
        />
        <link
          rel="canonical"
          href={
            (flashCard &&
              flashCard.back &&
              `https://algodaily.com/flash-cards/${flashCard.slug}`) ||
            "https://algodaily.com"
          }
        />
      </Helmet>

      <div
        className={`border bg-white no-gutters rounded-lg row d-flex flex-column align-items-center mb-4 ${
          isMobileDevice() ? "p-2" : "p-5"
        }`}
      >
        <p className="small mb-5">
          Click or tap on the question itself to flip the card.
        </p>
        <p className="lead">{flipped ? "ANSWER" : "PROMPT"}</p>
        {flashCard.taggings ? (
          <p className="mb-3">Tags: {flashCard.taggings}</p>
        ) : null}
        <div
          className={`flash-card ${
            isMobileDevice() ? "p-2" : "p-5"
          } text-left cursor-pointer`}
          onClick={() => setFlipped(!flipped)}
        >
          <ReactMarkdown
            plugins={[
              [
                remarkDisableTokenizers,
                { inline: ["url", "reference", "blockquote"] },
              ],
            ]}
            source={flipped ? flashCard.back : flashCard.front}
            escapeHtml={false}
            renderers={promptRenderer}
          />
        </div>
        {flashCard.reference_link ? (
          <small className="mt-4">
            <strong>Source: </strong>
            <Link
              to={
                flashCard.reference_link.includes("https://algodaily.com")
                  ? flashCard.reference_link.replace(
                      "https://algodaily.com",
                      ""
                    )
                  : flashCard.reference_link
              }
            >
              {flashCard.reference_link.includes("https://algodaily.com")
                ? flashCard.reference_link
                : `https://algodaily.com${flashCard.reference_link}`}
            </Link>
          </small>
        ) : null}
      </div>
      <div className="w-100 text-center">
        <button
          className="genric-btn info e-large mx-auto mb-4 d-block"
          onClick={() =>
            user
              ? history.push(
                  `/flash-cards/${next.slug}${
                    queryFiltersList.length
                      ? `?filters=${queryFiltersList.join(",")}`
                      : ""
                  }`
                )
              : toastNotSignedIn("use our flash cards")
          }
        >
          {user ? (
            <Link
              to={`/flash-cards/${next.slug}${
                queryFiltersList.length
                  ? `?filters=${queryFiltersList.join(",")}`
                  : ""
              }`}
              className="link-unstyled border-0"
            >
              Go to next card
            </Link>
          ) : (
            "Go to next card"
          )}
        </button>
        <div className="small col-lg-8 my-4 mx-auto">
          <p className="lead">Filters</p>
          <p>
            Press the tags to filter, or{" "}
            <a
              className="mr-1 d-inline-block font-weight-bold cursor-pointer"
              onClick={() => {
                history.push(location.pathname);
              }}
            >
              click here to reset
            </a>
            .
          </p>
          {taggings.map((f) => (
            <a
              className={`mr-1 d-inline-block ${
                queryFiltersList.includes(f)
                  ? "bg-ocean-blue text-white"
                  : "bg-white border"
              } cursor-pointer genric-btn small`}
              onClick={() => {
                // We do it this way because URL is source of truth for query state
                if (queryFiltersList.includes(f)) {
                  let currQueryFilters = query.get("filters");
                  if (currQueryFilters) {
                    const newWithFilters =
                      location.pathname +
                      location.search.replace(f, "").replace(/,,/g, ",");
                    return history.push(newWithFilters);
                  }
                } else {
                  let currQueryFilters = query.get("filters");
                  if (currQueryFilters) {
                    const newWithFilters =
                      location.pathname + location.search + `,${f}`;
                    return history.push(newWithFilters);
                  }

                  const newWithFilters = location.pathname + `?filters=${f}`;
                  history.push(newWithFilters.replace(/,,/g, ","));
                }
              }}
            >
              {f}
            </a>
          ))}
        </div>
        <span className="small m-auto d-block">
          All materials copyright {new Date().getFullYear()} AlgoDaily
          Incorporated.
        </span>
      </div>
    </>
  );
};

export default FlashCards;
