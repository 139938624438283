import Griddle, {
  ColumnDefinition,
  RowDefinition,
  plugins,
} from "griddle-react";
import React, { Component } from "react";

class ChallengeIndex extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const styleConfig = {
      icons: {
        TableHeadingCell: {
          sortDescendingIcon: "▼",
          sortAscendingIcon: "▲",
        },
      },
      classNames: {
        Row: "row-class bg-white",
        Table: `table my-2`,
        TableHeading: "thead bg-white",
        Filter: "form-control float-left mb-2 col-lg-6 mb-4",
        Pagination: "float-right input-group mb-2 col-lg-6 col-sm-12 mb-4",
        PageDropdown: "form-control",
        NextButton: "input-group-append genric-btn info",
        PreviousButton: "input-group-prepend genric-btn info",
        NoResults: "w-100 row p-2 my-5 bg-light",
      },
      styles: {
        Filter: { fontSize: 18 },
      },
    };

    const NewLayout = ({ Table, Pagination, Filter, SettingsWrapper }) => (
      <div className="table-responsive p-4 bg-white shadow rounded-lg">
        <Filter />
        <Pagination />
        <Table />
        <Filter />
        <Pagination />
      </div>
    );

    const self = this;
    const titleComponent = function ({ value }) {
      const link = <a href={`/challenges/${value.get(0)}`}>{value.get(1)}</a>;
      if (
        self.props.completed &&
        self.props.completed.indexOf(value.get(0)) > -1
      ) {
        return (
          <s>
            <span style={{ color: "#0000AA" }}>{link}</span>
            <br />
            {value.get(2)} <a href={`/challenges/${value.get(0)}`}></a>
          </s>
        );
      } else {
        return (
          <span>
            <strong>
              <span style={{ color: "#0000AA" }}>{link}</span>
            </strong>
            <br />
            {value.get(2)} <a href={`/challenges/${value.get(0)}`}></a>
          </span>
        );
      }
    };

    return (
      <Griddle
        data={this.props.challenges}
        showSettings={false}
        styleConfig={styleConfig}
        plugins={[plugins.LocalPlugin]}
        pageProperties={{
          currentPage: 1,
          pageSize: 30,
        }}
        components={{
          Layout: NewLayout,
        }}
        sortProperties={[{ id: "Day", sortAscending: true }]}
      >
        <RowDefinition>
          <ColumnDefinition id="Day" />
          <ColumnDefinition
            id="Img"
            customComponent={(val) => (
              <img
                src={val.value}
                style={{ width: "12rem", height: "12rem", objectFit: "cover" }}
              />
            )}
          />
          <ColumnDefinition id="Title" customComponent={titleComponent} />
          <ColumnDefinition id="Lvl" />
        </RowDefinition>
      </Griddle>
    );
  }
}

export default ChallengeIndex;
