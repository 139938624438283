import {
  LANG_TO_CM_MODE_MAP,
  extractCodeFromMd,
  extractLangFromCodeBlock,
  sqlToJsDate,
} from "./helpers/utils";

import CodeSnippet from "./CodeSnippet";
import PremiumLockScreen from "./PremiumLockScreen";
import React from "react";
import Video from "./Video";
import { actions } from "./redux/StateReducer";
import { useDispatch } from "react-redux";

const Solution = (props) => {
  const dispatch = useDispatch();
  const [completions, setCompletions] = React.useState([]);

  const userCompletions = completions.map((completion) => {
    const completionLang = extractLangFromCodeBlock(completion.code);

    return (
      <div className="mb-4">
        <div
          className="p-2 text-dark font-weight-bold border-0 m-0 small border-bottom-0"
          style={{
            backgroundColor: "#f0f2f7",
            borderRadius: "0.5em 0.5em 0 0",
          }}
        >
          Submitted {sqlToJsDate(completion.created_at)} in{" "}
          {LANG_TO_CM_MODE_MAP[completionLang].toUpperCase()}
          {completion.performance ? (
            <>
              <br />
              <strong>Performance: </strong>
              {parseFloat(completion.performance).toFixed(2)} ms
            </>
          ) : null}
        </div>

        <CodeSnippet
          languageCode={completionLang}
          code={extractCodeFromMd(completion.code)}
          hasHeader={true}
        />
      </div>
    );
  });

  React.useEffect(() => {
    if (props.user && props.user.paid_active) {
      const token = document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content");

      try {
        fetch(`/api/challenges/${props.challenge.id}/solutions`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        })
          .then((response) => response.json())
          .then((parsed) => {
            setCompletions(parsed);
            $("#content-container").scrollTop(0);
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.challenge.id]);

  React.useEffect(() => {
    $("#content-container").scrollTop(0);
  });

  return (
    <div className="p-4 px-5">
      <h3 className="font-weight-bold">AlgoDaily Solution</h3>

      {props.challenge.video ? (
        <Video video={props.challenge.video} user={props.user} />
      ) : null}

      {props.user && props.user.paid_active && props.challenge.has_trace ? (
        <button
          className="genric-btn info text-white border-0 w-100"
          id="code-viz-tab"
          onClick={() =>
            dispatch({ type: actions.SET_VIZ_CONTAINER, payload: true })
          }
        >
          <span
            id="code-viz-tab-text"
            onClick={() =>
              dispatch({ type: actions.SET_VIZ_CONTAINER, payload: true })
            }
          >
            Visualize our solution <i className="fa fa-eye"></i>{" "}
          </span>
        </button>
      ) : (
        <button className="genric-btn bg-secondary mb-1 border-0 w-100">
          <span>
            Visualize our solution <i className="fa fa-eye"></i>{" "}
          </span>
        </button>
      )}
      <CodeSnippet
        hasHeader={true}
        languageCode={extractLangFromCodeBlock(props.challenge.solutionCode)}
        code={extractCodeFromMd(props.challenge.solutionCode)}
      />
      <h3 className="font-weight-bold">Community Solutions</h3>
      {props.user && props.user.paid_active ? (
        userCompletions.length ? (
          userCompletions
        ) : (
          <p>No community solutions available.</p>
        )
      ) : (
        <>
          <p>Community solutions are only available for premium users.</p>

          <PremiumLockScreen />
        </>
      )}
    </div>
  );
};

export default Solution;
