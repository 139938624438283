import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { FeedbackFish } from "@feedback-fish/react";
import { Link } from "react-router-dom";
import { RIEInput } from "riek";
import { actions } from "./redux/StateReducer";
import { toast } from "react-toastify";
import { toastNotSignedIn } from "./helpers/utils";

const HoverBar = (props) => {
  const dispatch = useDispatch();

  const challenge = useSelector((state) => state.challenge);
  const lesson = useSelector((state) => state.lesson);
  const notes = useSelector((state) => state.notes);
  const user = useSelector((state) => state.user);

  const { screen, tutorialSlug, tutorialType, sendMsg } = props;

  const [foundNotes, setFoundNotes] = useState(
    notes.filter((n) => n.screen_id === screen.id)
  );

  const [noteAdded, setNoteAdded] = useState(foundNotes.length >= 1);
  const [visible, setVisible] = useState(false);
  const [optionsLoadMap, setOptionsLoadMap] = useState({});

  React.useEffect(() => {
    setFoundNotes(notes.filter((n) => n.screen_id === screen.id));
    setNoteAdded(foundNotes.length >= 1);
  }, [challenge, lesson, tutorialType, tutorialSlug, screen, notes]);

  const dropdownMenu = () => (
    <div
      class="list-group m-0 cursor-pointer"
      style={{
        zIndex: 999,
      }}
    >
      <a
        class="list-group-item list-group-item-action small"
        onClick={() => {
          sendMsg("ai", screen.id, "Simplify this passage's content for me:");
          setVisible(false);
        }}
        style={{
          zIndex: 999,
        }}
      >
        Summarize this
      </a>
      <a
        class="list-group-item list-group-item-action small"
        onClick={() => {
          sendMsg(
            "ai",
            screen.id,
            "Expand on this passage's content for me. I want to know more:"
          );
          setVisible(false);
        }}
        style={{
          zIndex: 999,
        }}
      >
        Expand on this
      </a>
      <a
        class="list-group-item list-group-item-action small"
        onClick={() => {
          sendMsg(
            "ai",
            screen.id,
            "Give me a sample code snippet for this passage's content:"
          );

          setVisible(false);
        }}
        style={{
          zIndex: 999,
        }}
      >
        Get code snippet
      </a>
      <a
        class="list-group-item list-group-item-action small"
        onClick={() => {
          sendMsg(
            "ai",
            screen.id,
            "Give me a question to test my understanding of this passage's content:"
          );

          setVisible(false);
        }}
        style={{
          zIndex: 999,
        }}
      >
        Get a question
      </a>
      {/* <a
        class="list-group-item list-group-item-action small"
        onClick={() => {
          // scroll to id={screen.id + "-chat"} element with a 200px offset
          const id = screen.id + "-chat";
          const yOffset = -200;
          const element = document.getElementById(id);
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }}
        style={{
          zIndex: 999,
        }}
      >
        Ask a question
      </a> */}
    </div>
  );

  return (
    <div
      className="position-absolute"
      style={{
        top: "0",
        right: "0",
        zIndex: 999,
      }}
      onMouseOver={(e) => setVisible(true)}
      onMouseLeave={(e) => setVisible(false)}
    >
      {visible ? (
        user?.admin ? (
          <>
            <RIEInput
              value={screen.sequence}
              change={(data) => {
                console.log(data);
              }}
              propName="sequence"
              validate={() => {}}
            />{" "}
            |{" "}
            <a href={`/admin/screen/${screen.id}/edit`} target="_blank">
              Edit Screen
            </a>{" "}
            |{" "}
            <a
              href={`/admin/${tutorialType.slice(0, -1)}/${
                lesson && tutorialType === "lessons" ? lesson.id : challenge.id
              }/edit`}
              target="_blank"
            >
              Edit Tutorial
            </a>{" "}
            |{" "}
            <Link
              to={`/${tutorialType}/${
                lesson && tutorialType === "lessons"
                  ? lesson.slug
                  : challenge.slug
              }/edit`}
            >
              New Editor
            </Link>
          </>
        ) : (
          dropdownMenu()
        )
      ) : (
        <div className="cursor-pointer bg-white shadow px-2 small">
          <i className="fa fa-bars text-ocean-blue" />
        </div>
      )}
    </div>
  );
};

export default HoverBar;
