import "./AuxiliaryContent.css";

import React, { Component } from "react";

import FillIn from "../FillIn";
import MultipleChoice from "../MultipleChoice";
import MyCodeTab from "./MyCodeTab";
import Order from "../Order";
import ReactMarkdown from "react-markdown/with-html";
import Swipe from "../Swipe";
import { connect } from "react-redux";
import { executableCodeRenderer } from "../helpers/markdown";

const codeTabs = require("../helpers/code-tabs.js");

class AuxiliaryContent extends Component {
  getCodeFromLocalStorage() {
    const codeFromLocalStorage = localStorage.getItem(
      `challenge-${this.props.challenge.slug}-entry-${this.props.language}`
    );
    if (codeFromLocalStorage) {
      return codeFromLocalStorage;
    } else {
      return "";
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.screen.id !== nextProps.screen.id ||
      this.props.language !== nextProps.language ||
      this.props.challenge.id !== nextProps.challenge.id ||
      this.props.challenge.language_content_id !==
        nextProps.challenge.language_content_id
    );
  }

  render() {
    const isSlides = this.props.mode === "slides";

    // check if challenge was loaded and not default
    const codeFromLocalStorage =
      this.props.challenge && this.props.challenge.slug
        ? this.getCodeFromLocalStorage()
        : null;

    let content;
    let lessonTabName = "";
    if (
      this.props.screen.kind == "question" ||
      this.props.screen.kind == "info screen"
    ) {
      // for code blocks and code tabs
      content = (
        <ReactMarkdown
          // for arrays not wrapped in ticks
          plugins={[codeTabs]}
          source={this.props.screen.code}
          renderers={executableCodeRenderer({
            ...this.props,
            codeFromLocalStorage,
          })}
          escapeHtml={false}
        />
      );
      lessonTabName = "Scratchpad";
    } else if (this.props.screen.kind == "multiple choice") {
      content = (
        <MultipleChoice screen={this.props.screen} isSlides={isSlides} />
      );
      lessonTabName = "Multiple Choice";
    } else if (this.props.screen.kind == "swipe") {
      content = <Swipe screen={this.props.screen} isSlides={isSlides} />;
      lessonTabName = "True or False";
    } else if (this.props.screen.kind == "fill in") {
      content = <FillIn screen={this.props.screen} isSlides={isSlides} />;
      lessonTabName = "Fill In";
    } else if (this.props.screen.kind == "order") {
      content = <Order screen={this.props.screen} isSlides={isSlides} />;
      lessonTabName = "Sequence";
    } else if (this.props.screen.kind == "ad") {
      lessonTabName = "AlgoDaily";
      content = fullCourseAd;
    }

    const shouldShowContent = !(
      this.props.screen.kind === "question" ||
      (this.props.screen.kind === "info screen" &&
        (!this.props.screen.code || this.props.screen.code === ""))
    );

    return (
      <div className="bg-white editor-fixed" id="aux-content">
        {shouldShowContent ? (
          content
        ) : (
          <MyCodeTab
            challenge={this.props.challenge}
            // changeLanguage={this.props.changeLanguage}
            codeFromLocalStorage={codeFromLocalStorage}
            language={this.props.language}
            editorVal={this.props.editorVal}
            runCode={this.props.runCode}
            screenCode={this.props.screen.code}
            user={this.props.user}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  challenge: state.challenge,
  language: state.language,
  lesson: state.lesson,
});
export default connect(mapStateToProps)(AuxiliaryContent);
