import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { toastNotSignedIn } from "./helpers/utils";
import { useHistory } from "react-router-dom";

const GenerateCustomCourse = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [background, setBackground] = useState("");
  const [language, setLanguage] = useState("");
  const [interests, setInterests] = useState("");
  const [isPolling, setIsPolling] = useState(false);

  const user = useSelector((state) => state.user);

  const languages = [
    "JavaScript",
    "Python",
    "Java",
    "C++",
    "C#",
    "Go",
    "PHP",
    "Typescript",
    "Ruby",
  ]; // Array of programming languages

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsPolling(true);

    if (!user || !user.id) {
      setIsPolling(false);
      toastNotSignedIn("to generate a custom course");
      return;
    }

    if (!title || title.length < 10) {
      alert(
        "Please enter a valid title (minimum 10 characters) for your course."
      );
      setIsPolling(false);
      return;
    }

    if (!description || description.length < 50) {
      alert(
        "Please enter a valid description (minimum 50 characters) for your course."
      );
      setIsPolling(false);
      return;
    }

    if (!background || background.length < 30) {
      alert("Please enter your coding background (minimum 30 characters).");
      setIsPolling(false);
      return;
    }

    if (!language) {
      alert("Please select a programming language.");
      setIsPolling(false);
      return;
    }

    if (!interests) {
      alert("Please enter your interests.");
      setIsPolling(false);
      return;
    }

    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch("/api/courses/generate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        title,
        description,
        background,
        language,
        interests,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          toast.error(data.error);
          setIsPolling(false);
          return;
        }

        setIsPolling(false);
        history.push(`/courses/${data.slug}`);
      })
      .catch((err) => {
        setIsPolling(false);

        console.error("Error generating course:", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          Generate a Custom Coding Course | AlgoDaily - Ace the Coding Interview
        </title>
        <meta
          name="description"
          content="Generate a custom, personalized, interactive coding course on AlgoDaily.com"
        />
      </Helmet>
      <div
        class="border bg-white no-gutters rounded-lg row d-flex align-items-stretch mb-4"
        style={{
          // webkit
          WebkitAlignItems: "stretch",
        }}
      >
        <div className="container m-4">
          <h1>Generate a Custom Coding Course</h1>
          <div className="alert alert-info" role="alert">
            <p>
              You can now generate a custom, personalized interactive coding
              courses on AlgoDaily.com tailor-made for you! Tell us what you
              want to learn, what knowledge or skills you're coming in with,
              what programming language you want us to teach in, and what your
              interests are. Within minutes, you'll have a fully-fleshed,
              multi-lesson interactive course that is made just for you.
            </p>
            <p>
              <strong>Note:</strong>{" "}
              <em>You must be logged in to generate a course</em>. Free users
              are limited to 1 generation per month, and are able to generate
              the course outline and first lesson but will be unable to advance
              past the first lesson.{" "}
              <a href="/subscriptions/new" target="_blank">
                Premium users
              </a>{" "}
              can generate 5 courses each month and have full access to all
              lessons in each course.
            </p>
            <small>
              <strong>Also note:</strong> this is a beta feature, so please
              excuse any minor issues as the technology gets better.
            </small>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group row">
              <label
                htmlFor="courseTitle"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                Course Title
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="courseTitle"
                  placeholder="What should your course be about?"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="courseGoals"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                Course Goals
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  id="courseGoals"
                  rows="3"
                  placeholder="What are your description with this course?"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="codingBackground"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                Coding Background
              </label>
              <div className="col-sm-10">
                <textarea
                  className="form-control"
                  id="codingBackground"
                  rows="3"
                  placeholder="What is your background with coding?"
                  value={background}
                  onChange={(e) => setBackground(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="programmingLanguage"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                Course Language
              </label>
              <div className="col-sm-10">
                <select
                  className="form-control"
                  id="programmingLanguage"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  <option value="" disabled>
                    Select a language
                  </option>
                  {languages.map((lang, index) => (
                    <option key={index} value={lang}>
                      {lang}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row">
              <label
                htmlFor="interests"
                className="col-sm-2 col-form-label font-weight-bold"
              >
                Interests
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="interests"
                  placeholder="What are your interests?"
                  value={interests}
                  onChange={(e) => setInterests(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="genric-btn info e-large px-2 px-lg-3 mt-2"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontSize: "1rem",
              }}
            >
              Generate My Interactive Course{" "}
              {isPolling ? <i className="fa fa-spinner fa-spin"></i> : null}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default GenerateCustomCourse;
