import { Link, useHistory } from "react-router-dom";
import {
  extractOnlyText,
  postCompletion,
  toastNotSignedIn,
} from "./helpers/utils";

import React from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

const SectionLessonCard = (props) => {
  let history = useHistory();

  const { lesson, idx, updateRenderedLesson, user } = props;

  const metaInfo = (tut) => (
    <div className="position-absolute" style={{ top: "1rem", right: "1rem" }}>
      {!(user && user.paid_active) && (
        <img
          src={`https://storage.googleapis.com/algodailyrandomassets/marketing/${
            tut.locked ? "lock" : "free"
          }.png?b=1`}
          style={{
            width: "2.2rem",
            height: "2.2rem",
            WebkitFilter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
            filter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
          }}
          title="This tutorial is free"
        />
      )}
      {tut.video ? (
        <img
          src="https://storage.googleapis.com/algodailyrandomassets/marketing/video.png?b=1"
          className="ml-2"
          style={{
            width: "2.2rem",
            height: "2.2rem",
            WebkitFilter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
            filter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
          }}
          title="This tutorial has video"
        />
      ) : null}
    </div>
  );

  let lessonExcerpt = lesson.excerpt ? extractOnlyText(lesson.excerpt) : "";
  if (lessonExcerpt.match(/^Introduction\n/)) {
    lessonExcerpt = lessonExcerpt.replace(/^Introduction\n/, "");
  }
  if (lessonExcerpt.startsWith(lesson.title)) {
    console.log("get rid of title in excerpt");
    lessonExcerpt = lessonExcerpt.replace(lesson.title, "");
  }

  return (
    <div className="col-lg-4 mb-4" key={`lesson-${idx}`}>
      <div
        className={`card ${
          lesson.completed ? "bg-white" : "text-dark bg-light"
        } border-0 shadow rounded-lg position-relative`}
        style={
          lesson.completed
            ? {
                color: "silver",
              }
            : null
        }
      >
        <img
          src={lesson.image_url}
          className="card-img-top rounded-top bg-white cursor-pointer"
          style={{
            objectFit: "cover",
            height: "12em",
            opacity: lesson.completed ? 0.3 : 1,
          }}
          onClick={() => history.push(`/lessons/${lesson.slug}`)}
        />
        {metaInfo(lesson)}

        <div
          className="card-body py-auto cursor-pointer"
          onClick={() => history.push(`/lessons/${lesson.slug}`)}
        >
          <small>{(idx + 1).toString()}. LESSON</small>

          <h4
            className="font-weight-bold"
            title={lesson.title ? lesson.title : ""}
          >
            {" "}
            <Link to={`/lessons/${lesson.slug}`} style={{ color: "inherit" }}>
              {lesson.title ? lesson.title : <Skeleton />}
            </Link>
          </h4>

          <p
            className="small"
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 6,
              lineClamp: 6,
              WebkitBoxOrient: "vertical",
            }}
          >
            {lesson.excerpt ? lessonExcerpt : <Skeleton />}
            ...
          </p>
        </div>
        <div className="card-footer d-flex justify-content-between small">
          <span>{parseInt(lesson.reading_time)} minutes</span>
          <span
            className="cursor-pointer"
            onClick={() => {
              if (!user) {
                return toastNotSignedIn("track progress");
              }

              if (lesson) {
                if (lesson.completed) {
                  const token = document
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content");

                  return fetch(`/api/lessons/${lesson.slug}/unread`, {
                    method: "post",
                    headers: {
                      "Content-Type": "application/json",
                      "X-CSRF-Token": token,
                    },
                  }).then((res) => {
                    if (!res.ok) {
                      throw res;
                    }

                    updateRenderedLesson(lesson, false);
                  });
                }

                postCompletion("lessons", lesson.slug, null, (lc) => {
                  updateRenderedLesson(lesson, true);
                  toast.success(
                    <span>
                      <strong>{lesson.title}</strong> is marked as completed!
                    </span>
                  );
                });
              }
            }}
          >
            {lesson.completed ? (
              <>
                <i className="far fa-check-square pr-1"></i> Completed
              </>
            ) : (
              <>
                <i className="far fa-square pr-1"></i> Mark Completed
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SectionLessonCard;
