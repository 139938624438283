import ChatWindow, { scrollToBottom } from "./ChatWindow";

import React from "react";
import ReactDOM from "react-dom/client";

const { createRoot } = ReactDOM;

export default class ChatPrompt {
  constructor(rootNode, user) {
    // make this a singleton
    if (ChatPrompt.instance) {
      return ChatPrompt.instance;
    } else {
      ChatPrompt.instance = this;
    }

    // check if rootNode is a DOM node
    if (rootNode instanceof Element) {
      this.rootNode = rootNode;
      this.createChatPromptNode();

      this.user = user;
      this.visible = false;
    }
  }

  createChatPromptNode() {
    if (!document.getElementById("chat-prompt")) {
      // create a div to render the chat prompt into
      // and prepend it to the root node
      const chatPrompt = document.createElement("div");
      chatPrompt.id = "chat-prompt";
      this.rootNode.prepend(chatPrompt);
      this.chatPromptNode = chatPrompt;
    }
  }

  chatPromptOpen() {
    return this.user
      ? localStorage?.getItem("showChatbot") === "true" || false
      : false;
  }

  show() {
    this.createChatPromptNode();

    if (!this.root) {
      this.root = createRoot(this.chatPromptNode);
    }

    this.root.render(<ChatWindow user={this.user} floating={true} />);

    this.visible = true;
    localStorage?.setItem("showChatbot", "true");
    scrollToBottom();
  }

  hide() {
    this.visible = false;
    this.root.unmount();
    this.root = null;
    localStorage?.setItem("showChatbot", "false");
  }
}
