import React from "react";

// This component is rendered standalone and doesn't have normal app state
const UserCourseSendSelector = (props) => {
  const [saved, setSaved] = React.useState(false);
  const [courseId, setCourseId] = React.useState(props.course_id);
  const [courses, setCourses] = React.useState([]);
  const [newsletter, setNewsletter] = React.useState([]);
  const [dayOn, setDayOn] = React.useState(props.day_on);

  const [view, setView] = React.useState(props.view || "article");
  const [language, setLanguage] = React.useState(
    props.language || "javascript"
  );
  const [courseCurriculumOpen, setCourseCurriculumOpen] = React.useState(
    (localStorage && localStorage.getItem("adCourseCurriculumOpen")) || true
  );

  const AVAILABLE_NEWSLETTER_SLUGS = props.paid_active
    ? [
        "data-structures-and-algorithms",
        "systems-design-and-architecture",
        "object-oriented-programming",
        "machine-learning-fundamentals",
      ]
    : ["data-structures-and-algorithms"];

  function updateSettings(e) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/settings`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        brochure: {
          language: $("#languageSwitch").val(),
          // huge hack, these should be separate functions
          view:
            e.target.checked !== undefined
              ? e.target.checked === true
                ? "interactive"
                : "article"
              : $("#viewSwitch").prop("checked")
              ? "interactive"
              : "article",
        },
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          // hack

          setView(obj.view);
          setLanguage(obj.language);

          $("#languageSwitch").val(obj.language);

          if (obj.view === "interactive") {
            $("#viewSwitch").prop("checked", true);
          } else {
            $("#viewSwitch").prop("checked", false);
          }

          setSaved(true);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const fetchCourseNewsletter = (courseId) => {
    try {
      fetch(`/api/courses/${courseId}/newsletter`)
        .then((res) => {
          // Stream response object stream for status
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((obj) => {
          setNewsletter(obj.newsletter);
          setSaved(false);

          if (dayOn > obj.newsletter.length) {
            setDayOn("");
          }

          document.getElementById("devise-content").scrollTo(0, 0);
        });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    try {
      fetch("/api/courses")
        .then((res) => {
          // Stream response object stream for status
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((arr) => {
          setCourses(arr);

          fetchCourseNewsletter(courseId);
        });
    } catch (e) {
      console.log(e);
    }
  }, []);

  return (
    <div className="w-100">
      <h4 className="font-weight-bold">Newsletter Subscription</h4>
      {!props.paid_active ? (
        <p>
          All users can get the DS & Algos daily challenge, but only{" "}
          <a href="/subscriptions/new">premium members</a> are able to get the
          newsletters for other crash courses.
        </p>
      ) : null}
      <div className="row">
        <div className="col-lg-6">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Newsletter name</label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              onChange={(e) => {
                setCourseId(e.target.value);
                fetchCourseNewsletter(e.target.value);
                setDayOn("");
              }}
              value={courseId}
            >
              {courses && courses.length ? (
                courses.map((c) => (
                  <option
                    value={c.id}
                    disabled={!AVAILABLE_NEWSLETTER_SLUGS.includes(c.slug)}
                  >
                    {c.newsletter_title}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </select>
          </div>
        </div>
        <div className="col-lg-6">
          <div class="form-group">
            <label for="exampleFormControlSelect1">Day on (next send)</label>
            <select
              class="form-control"
              id="exampleFormControlSelect1"
              value={dayOn?.toString()}
              onChange={(e) => {
                const newDayOn = e.target.value;

                const token = document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute("content");

                fetch(`/api/settings`, {
                  method: "put",
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRF-Token": token,
                  },
                  body: JSON.stringify({
                    brochure: {
                      course_id: courseId,
                      day_on: newDayOn,
                    },
                  }),
                })
                  .then((res) => {
                    if (!res.ok) {
                      throw res;
                    }
                    return res.json();
                  })
                  .then(
                    (obj) => {
                      setDayOn(obj.day_on);
                      setSaved(true);
                    },
                    (error) => {
                      console.log(error);
                    }
                  );
              }}
            >
              <option value="" disabled selected>
                Choose a day on
              </option>

              {newsletter && newsletter.length ? (
                newsletter.map((t, idx) => (
                  <option
                    value={t[0]}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100px",
                    }}
                  >
                    {t[0]}. {t[2]}
                  </option>
                ))
              ) : (
                <option>Loading...</option>
              )}
            </select>
          </div>
        </div>
      </div>
      <h4 className="font-weight-bold">Platform Settings</h4>

      <div className="row mb-2">
        <div className="col-lg-6">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="viewSwitch"
              onChange={updateSettings}
              checked={view === "interactive"}
            />
            <label className="form-check-label" for="viewSwitch">
              Default to interactive view
            </label>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitch2"
              checked={courseCurriculumOpen}
              onChange={(e) => {
                setCourseCurriculumOpen(e.target.checked);

                localStorage &&
                  localStorage.setItem(
                    "adCourseCurriculumOpen",
                    e.target.checked
                  );
              }}
            />
            <label className="form-check-label" for="customSwitch2">
              Keep course menu open
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <select
            className="custom-select"
            id="languageSwitch"
            value={language}
            onChange={updateSettings}
          >
            <option value="javascript">Default to Javascript</option>
            <option value="python">Default to Python</option>
            <option value="java">Default to Java</option>
            <option value="go">Default to Go</option>
            <option value="ruby">Default to Ruby</option>
          </select>
        </div>
      </div>
      <div className="w-100 mt-2">
        {saved
          ? "Your preferences have been saved. If you changed your newsletter day, you'll receive that lesson at the next send at 9am Eastern Time."
          : null}
      </div>
    </div>
  );
};

export default UserCourseSendSelector;
