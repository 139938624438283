import { Route, Switch, useLocation } from "react-router-dom";
import { adjustForNav, getUrlLanguage, isMobileDevice } from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import ActivityLog from "./ActivityLog";
import AppNav from "./AppNav";
import ChallengeManager from "./ChallengeManager";
import ChatPrompt from "./ChatPrompt";
import ChatWindow from "./ChatWindow";
import CodeConverter from "./CodeConverter";
import CodeVizContainer from "./CodeVizContainer";
import Course from "./Course";
import CourseCurriculum from "./CourseCurriculum";
import Dashboard from "./Dashboard";
import FlashCards from "./FlashCards";
import FooterLinks from "./FooterLinks";
import GenerateCustomCourse from "./GenerateCourse";
import React from "react";
import ReactTooltip from "react-tooltip";
import SectionContainer from "./SectionContainer";
import SectionContainerEditor from "./SectionContainerEditor";
import { ToastContainer } from "react-toastify";
import Tutorial from "./Tutorial";
import { actions } from "./redux/StateReducer";
import { useReducer } from "react";

const PageContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const challenge = useSelector((state) => state.challenge);
  const course = useSelector((state) => state.course);
  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );
  const language = useSelector((state) => state.language);
  const lesson = useSelector((state) => state.lesson);
  const screenListOpen = useSelector((state) => state.screenListOpen);

  const user = useSelector((state) => state.user);
  const vizContainerVisible = useSelector((state) => state.vizContainerVisible);

  const currLocation = useLocation();
  const containerRef = React.useRef(null);
  const appEl = React.useRef(null);

  const tutorialType = currLocation.pathname.includes("lessons")
    ? "lessons"
    : "challenges";

  React.useEffect(() => {
    if (localStorage) {
      const lsCourseCurrOpen = localStorage.getItem("adCourseCurriculumOpen");

      if (lsCourseCurrOpen) {
        dispatch({
          type: actions.SET_COURSE_CURRICULUM_OPEN,
          payload: lsCourseCurrOpen === "true",
        });
      }
    }

    if (user) {
      fetch(`/api/notes/`)
        .then((res) => {
          return res.json();
        })
        .then(
          (obj) => {
            // fixme
            dispatch({
              type: actions.SET_NOTES,
              payload: obj,
            });
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, []);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  React.useEffect(() => {
    const urlLanguage = getUrlLanguage();
    if (urlLanguage && language !== urlLanguage) {
      dispatch({ type: actions.SET_APP_LANGUAGE, payload: urlLanguage });
    }
    adjustForNav();
  }, [location.pathname]);

  React.useEffect(() => {
    if (
      document.getElementById("ad-app") &&
      user &&
      currLocation.pathname !== "/ai-tutor"
    ) {
      // console.log("loading chat prompt");
      window.cp = new ChatPrompt(document.getElementById("ad-app"), user);
      if (!window.cp?.visible && window.cp?.chatPromptOpen()) {
        window.cp.show();
      }
    }
  }, [location.pathname]);

  return (
    <>
      <AppNav
        completed={
          (tutorialType === "lessons" && lesson && lesson.completed) ||
          (challenge && challenge.completed)
        }
        title={
          (tutorialType === "lessons" && lesson && lesson.title) ||
          (tutorialType === "challenges" && challenge && challenge.title) ||
          (course && course.slug && course.title) ||
          ""
        }
        user={user}
        ref={appEl}
      />
      <div className="row no-gutters w-100">
        {courseCurriculumOpen || isMobileDevice() ? (
          <CourseCurriculum
            tutorial={tutorialType === "lessons" ? lesson : challenge}
          />
        ) : null}
        <ReactTooltip className="tooltip-z-index" />
        <ToastContainer />
        <Switch>
          <Route path={["/dashboard"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4 px-5"
                  : "py-4 px-6"
              }  bg-light`}
              ref={containerRef}
              style={{ minHeight: "100vh" }}
            >
              <Dashboard />
            </div>
          </Route>
          <Route path={["/activity-log"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4 px-5"
                  : "py-4 px-6"
              }  bg-light`}
              ref={containerRef}
              style={{ minHeight: "100vh" }}
            >
              <ActivityLog />
            </div>
          </Route>
          <Route path={["/courses/generate"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4 px-5"
                  : "py-4 px-6"
              }  bg-light`}
              ref={containerRef}
            >
              <GenerateCustomCourse />
            </div>
          </Route>
          <Route path={["/courses/:courseId"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4 px-5"
                  : "py-4 px-6"
              }  bg-light`}
              ref={containerRef}
            >
              <Course />
            </div>
          </Route>
          <Route
            path={[
              "/lessons/:tutorialSlug/:language/:screenSlug",
              "/lessons/:tutorialSlug/:screenSlug",
              "/lessons/:tutorialSlug",
              "/challenges/:tutorialSlug/:language/:screenSlug",
              "/challenges/:tutorialSlug/:screenSlug",
              "/challenges/:tutorialSlug",
            ]}
            exact
          >
            <Tutorial
              screenListOpen={screenListOpen}
              updateScreenCompletions={props.updateScreenCompletions}
            />
          </Route>

          <Route
            path={[
              "/sections/:sectionSlug/edit",
              "/categories/:sectionSlug/edit",
            ]}
            exact
          >
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4"
                  : "py-4 px-6"
              } bg-light`}
              ref={containerRef}
            >
              <SectionContainerEditor />
              <FooterLinks />
            </div>
          </Route>

          <Route
            path={["/sections/:sectionSlug", "/categories/:sectionSlug"]}
            exact
          >
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4 px-5"
                  : "py-4 px-6"
              } bg-light`}
              ref={containerRef}
            >
              <SectionContainer />
              <FooterLinks />
            </div>
          </Route>

          <Route path={["/flash-cards/", "/flash-cards/:flashCardSlug"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4"
                  : "py-4 px-6"
              } bg-light`}
              ref={containerRef}
              style={{ minHeight: "100vh" }}
            >
              <FlashCards />
            </div>
          </Route>

          <Route path={["/ai-tutor"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-2"
                  : "py-4 px-6"
              }  bg-light`}
              ref={containerRef}
            >
              <ChatWindow user={user} floating={false} />
            </div>
          </Route>

          <Route path={["/convert/:fromLanguage/:toLanguage"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4"
                  : "py-4 px-6"
              } bg-light`}
              ref={containerRef}
              style={{ minHeight: "100vh" }}
            >
              <CodeConverter />
            </div>
          </Route>

          <Route path={["/challenge-manager"]} exact>
            <div
              id="tutorial-container"
              className={`${courseCurriculumOpen ? "col-lg-9" : "col-lg-12"} ${
                isMobileDevice()
                  ? "p-2"
                  : courseCurriculumOpen
                  ? "p-4 px-5"
                  : "py-4 px-6"
              }  bg-light`}
              ref={containerRef}
              style={{ minHeight: "100vh" }}
            >
              <ChallengeManager />
            </div>
          </Route>
        </Switch>
        {vizContainerVisible ? (
          <CodeVizContainer slug={challenge.slug} />
        ) : null}
      </div>
    </>
  );
};

export default PageContainer;
