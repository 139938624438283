import React from "react";
import { actions } from "./redux/StateReducer";
import { useDispatch } from "react-redux";

const CodeVizContainer = (props) => {
  const dispatch = useDispatch();

  var getJqueryCustom = function () {
    $.ajax({
      method: "get",
      url: "/js/jquery-ui-1.10.3.custom.min.js",
      dataType: "script",
      complete: getDThree,
    });
  };

  var getDThree = function () {
    $.ajax({
      method: "get",
      url: "https://cdnjs.cloudflare.com/ajax/libs/d3/2.10.0/d3.v2.min.js",
      dataType: "script",
      complete: getBbq,
    });
  };

  var getBbq = function () {
    $.ajax({
      method: "get",
      url: "/js/jquery.ba-bbq.min.js",
      dataType: "script",
      complete: getJsPlumb,
      error: function (data, status, err) {
        console.log(data, status, err);
      },
    });
  };

  var getJsPlumb = function () {
    $.ajax({
      method: "get",
      url: "/js/jquery.jsPlumb.min.js",
      dataType: "script",
    }).complete(getPyTutor);
  };

  var getPyTutor = function () {
    $.ajax({
      method: "get",
      url: "/js/pytutor.js",
      dataType: "script",
    }).complete(initFunc);
  };

  var initFunc = function () {
    $("#code-viz-container").addClass(
      "d-flex flex-column justify-content-start"
    );
    initializeVisualizer();
    $("#code-viz-tab").css({
      display: "none",
    });
  };

  function initializeVisualizer() {
    try {
      const codeVizVis = new ExecutionVisualizer("code-viz", codeVizTrace, {
        embeddedMode: true,
        verticalStack: false,
        // heightChangeCallback: redrawAllVisualizerArrows,
        codeDivWidth: window.innerWidth / 2,
        lang: "js",
      });
      // attachLoggers(const codeVizVis,'code-viz');
      $("#codeDisplayDiv").find("button").css({
        color: "#fff",
        background: "rgb(56, 164, 255)",
        border: "1px solid transparent",
      });
      // allVisualizers.push(code-viz_vis);
    } catch (e) {
      console.log("Failed to Initialize CodeLens component");
      console.log(e.toString());
    }
  }

  function showVizContainer() {
    $("head").append(
      '<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.css">'
    );

    $.ajax({
      method: "get",
      url: `/traces/${props.slug}.js`,
      dataType: "script",
      complete: getJqueryCustom,
    });
  }

  showVizContainer();

  return (
    <div id="code-viz-container">
      <div
        className="navbar no-gutters w-100 text-white py-1 px-2"
        style={{ background: "#38A4FF" }}
      >
        <i className="fa fa-eye"></i>
        <strong className="font-weight-bold my-auto">
          Solution Visualized
        </strong>
        <span
          className="far fa-window-close float-right"
          id="code-viz-close"
          onClick={() => {
            $("#code-viz-container")
              .removeClass("d-flex flex-column justify-content-start")
              .fadeOut();
            $("#code-viz-tab").css({
              display: "block",
            });
            dispatch({ type: actions.SET_VIZ_CONTAINER, payload: false });
          }}
        ></span>
      </div>
      <div className="h-100 p-2">
        <div id="code-viz"></div>
      </div>
    </div>
  );
};

export default CodeVizContainer;
