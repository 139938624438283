import App from "./App";
import { Provider } from "react-redux";
import React from "react";
import { assignPropsToStore } from "./redux/store";

function AppProvider(props) {
  return (
    <Provider store={assignPropsToStore(props)}>
      <App {...props} />
    </Provider>
  );
}

export default AppProvider;
