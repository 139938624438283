import "./JobApplications.css";

import { RIEInput, RIESelect } from "riek";
import {
  getFormattedDate,
  isMobileDevice,
  titleize,
  tutorialContainerSize,
} from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import AppNav from "./AppNav";
import CourseCurriculum from "./CourseCurriculum";
import NonTutorialMenu from "./NonTutorialMenu";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { actions } from "./redux/StateReducer";

const JobApplicationsContainer = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);

  const [createJobAppUpdatesOpen, setCreateJobAppUpdatesOpen] = React.useState(
    false
  );

  const [jobApp, setJobApp] = React.useState({
    job_title: "Job Title",
    status: "applied",
    location: "Remote",
    post_url: "https://algodaily.com",
  });

  const [completedChalSlugs, setCompletedChalSlugs] = React.useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);

  const [deleteObject, setDeleteObject] = React.useState({
    type: "",
    id: "",
  });

  const [courseCurriculumOpen, setCourseCurriculumOpen] = React.useState(true);

  let { jobAppId } = useParams();

  function customValidateText(text) {
    return text.length > 0;
  }

  function saveJobApp(id, newJobApp) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/job_applications/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(newJobApp),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          // fixme
          setJobApp(newJobApp);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function deleteJobApp(id) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/job_applications/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }

      dispatch({
        type: actions.DELETE_JOB_APP,
        payload: id,
      });

      history.push("/dashboard");
    });
  }

  function deleteJobAppUpdate(id) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/job_application_updates/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }

      const newJobApp = Object.assign({}, jobApp, {
        job_application_updates: jobApp.job_application_updates.filter(
          (u) => u.id !== id
        ),
      });

      setJobApp(newJobApp);
    });
  }

  React.useEffect(() => {
    fetch(`/api/job_applications/${jobAppId}`)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          // fixme

          setJobApp(obj);
        },
        (error) => {
          console.log(error);

          if (error.status == "404") {
            window.location.replace("/dashboard");
          }
        }
      );

    window.scrollTo(0, 0);

    if (localStorage) {
      const lsCourseCurrOpen = localStorage.getItem("adCourseCurriculumOpen");

      if (lsCourseCurrOpen) {
        setCourseCurriculumOpen(lsCourseCurrOpen === "true");
      }
    }
  }, [jobAppId]);

  const statusOptions = [
    {
      id: "found-opportunity",
      text: "Found Opportunity",
    },
    { id: "applied", text: "Applied" },
    { id: "phone-interview", text: "Phone Interview" },
    { id: "onsite-interview", text: "Onsite Interview" },
    { id: "offer", text: "Offer" },
    { id: "rejected", text: "Rejected" },
    { id: "ghosted", text: "Ghosted" },
  ];

  return (
    <>
      <AppNav
        completed={false}
        setCourseCurriculumOpen={setCourseCurriculumOpen}
        title="Job Applications"
        user={user}
      />
      <div className="row no-gutters w-100">
        {courseCurriculumOpen || isMobileDevice() ? <CourseCurriculum /> : null}
        <div
          id="tutorial-container"
          className={`${tutorialContainerSize(
            courseCurriculumOpen,
            screenListOpen
          )} ${
            isMobileDevice()
              ? "p-2"
              : courseCurriculumOpen
              ? "p-4 px-5"
              : "py-4 px-6"
          }  bg-light`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="font-weight-bold text-center">
              {jobApp.user_added_company ? (
                `${titleize(jobApp.user_added_company)} Application`
              ) : (
                <Skeleton />
              )}
            </h2>
            <i
              type="button"
              className="fa fa-times cursor-pointer"
              onClick={(e) => {
                setDeleteObject({ type: "jobApp", id: jobAppId });
                setConfirmModalOpen(true);
              }}
            />
          </div>
          <div className="card bg-white">
            <div className={`card-body ${isMobileDevice() ? "p-2" : "p-4"} `}>
              {jobApp.job_title ? (
                <div className="row">
                  <div className="col-lg-6">
                    <h3 className="font-weight-bold">Job Title</h3>
                    <p className="cursor-pointer">
                      <RIEInput
                        value={jobApp.job_title}
                        change={(data) => {
                          const newJobApp = Object.assign({}, jobApp);
                          newJobApp.job_title = data.job_title;
                          saveJobApp(jobApp.id, newJobApp);
                        }}
                        className="job-app-entry"
                        propName="job_title"
                        validate={customValidateText}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="font-weight-bold">Status</h3>
                    <p className="cursor-pointer">
                      <RIESelect
                        change={(data) => {
                          const newJobApp = Object.assign({}, jobApp);
                          newJobApp.status = data.status.id;
                          saveJobApp(jobApp.id, newJobApp);
                        }}
                        className="job-app-entry"
                        options={statusOptions}
                        value={
                          statusOptions.filter((o) => o.id === jobApp.status)[0]
                        }
                        propName="status"
                        validate={customValidateText}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="font-weight-bold">Applied On</h3>
                    <p>
                      <RIEInput
                        value={getFormattedDate(new Date(jobApp.applied_on))}
                        change={(data) => {
                          const newJobApp = Object.assign({}, jobApp);
                          newJobApp.applied_on = new Date(data.applied_on);
                          saveJobApp(jobApp.id, newJobApp);
                        }}
                        className="job-app-entry"
                        propName="applied_on"
                        validate={(testDate) => {
                          var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
                          if (!date_regex.test(testDate)) {
                            return false;
                          }
                          return true;
                        }}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="font-weight-bold">Job Post URL</h3>
                    <p className="cursor-pointer">
                      <RIEInput
                        value={jobApp.post_url || "https://algodaily.com"}
                        change={(data) => {
                          const newJobApp = Object.assign({}, jobApp);
                          newJobApp.post_url = data.post_url;
                          saveJobApp(jobApp.id, newJobApp);
                        }}
                        className="job-app-entry"
                        propName="post_url"
                        validate={customValidateText}
                      />
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <h3 className="font-weight-bold">Location</h3>
                    <p className="cursor-pointer">
                      <RIEInput
                        value={jobApp.location || "Remote"}
                        change={(data) => {
                          const newJobApp = Object.assign({}, jobApp);
                          newJobApp.location = data.location;
                          saveJobApp(jobApp.id, newJobApp);
                        }}
                        className="job-app-entry"
                        propName="location"
                        validate={customValidateText}
                      />
                    </p>
                  </div>
                </div>
              ) : (
                <Skeleton />
              )}

              <div className="w-100 mb-4">
                <h3 className="font-weight-bold">Job Application Timeline</h3>
                <p className="small">
                  Add your latest updates to track your recruiting journey at
                  this company. These are only visible to you.
                </p>

                {jobApp.job_application_updates ? (
                  jobApp.job_application_updates.map((jau) => (
                    <div className="w-100 mb-4">
                      <div class={`card text-dark border-1 rounded-lg`}>
                        <div className="card-body py-auto">
                          <div className="d-flex justify-content-between align-items-center">
                            <p>{getFormattedDate(new Date(jau.occurred_on))}</p>
                            <i
                              type="button"
                              className="fa fa-times cursor-pointer"
                              onClick={(e) => {
                                setDeleteObject({
                                  type: "jobAppUpdate",
                                  id: jau.id,
                                });
                                setConfirmModalOpen(true);
                              }}
                            />
                          </div>

                          <h3 className="font-weight-bold">
                            {jau.event_name ? jau.event_name : <Skeleton />}
                          </h3>
                          <p>
                            {jau.event_description ? (
                              jau.event_description
                            ) : (
                              <Skeleton />
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Skeleton></Skeleton>
                )}
                <div className="w-100 text-center mb-4">
                  <button
                    type="button"
                    className="btn btn-default btn-circle btn-lg my-4 mx-auto d-flex align-items-center justify-content-center"
                    onClick={() => setCreateJobAppUpdatesOpen(true)}
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                </div>
                {createJobAppUpdatesOpen ? (
                  <div className="bg-light p-4 rounded-lg">
                    <div className="form-group">
                      <label for="eventDate">Occurred On</label>
                      <input
                        className="form-control"
                        type="date"
                        defaultValue={new Date().toISOString().split("T")[0]}
                        id="eventDate"
                      />
                    </div>
                    <div className="form-group">
                      <label for="eventName">Event Name</label>
                      <select className="form-control" id="eventName">
                        <option>Found job opportunity</option>
                        <option>Submitted application</option>
                        <option>Phone screen interview</option>
                        <option>On-site interview</option>
                        <option>Received offer</option>
                        <option>Negotiation step</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label for="eventDescription">Event Description</label>
                      <textarea
                        className="form-control"
                        id="eventDescription"
                        rows="3"
                      ></textarea>
                    </div>
                    <button
                      className="btn btn-info"
                      onClick={(e) => {
                        e.preventDefault();

                        if (!user) {
                          window.open("/users/sign_in");
                        }

                        const token = document
                          .querySelector('meta[name="csrf-token"]')
                          .getAttribute("content");

                        try {
                          fetch(`/api/job_application_updates`, {
                            method: "post",
                            headers: {
                              "Content-Type": "application/json",
                              "X-CSRF-Token": token,
                            },
                            body: JSON.stringify({
                              job_application_update: {
                                occurred_on: $("#eventDate").val(),
                                event_name: $("#eventName").val(),
                                event_description: $("#eventDescription").val(),
                                job_application_id: jobApp.id,
                              },
                            }),
                          })
                            .then((res) => {
                              if (!res.ok) {
                                throw res;
                              }
                              return res.json();
                            })
                            .then((obj) => {
                              const newJobApp = Object.assign({}, jobApp);
                              newJobApp.job_application_updates.push(obj);
                              setJobApp(newJobApp);
                              setCreateJobAppUpdatesOpen(false);
                            });
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      Save
                    </button>
                  </div>
                ) : null}
              </div>

              <div className="w-100 mb-4">
                <h3 className="font-weight-bold">
                  Suggested Interview Problems
                </h3>
                <p className="small">
                  <strong>What are these questions?</strong> When users apply to
                  new companies, we research the latest asked questions across
                  multiple interview report sources. While not guaranteed to
                  appear on your interview, it gives a sense of the level and
                  types of concepts you can expect.
                </p>
                <div className="row mb-4">
                  {jobApp.challenges ? (
                    jobApp.challenges.map((chal) => (
                      <div className="col-lg-6 mb-4">
                        <div
                          class={`card ${
                            completedChalSlugs.includes(chal.slug)
                              ? "text-secondary"
                              : "text-white"
                          } border-0 shadow rounded-lg cursor-pointer`}
                          style={{ backgroundColor: "#364859" }}
                          onClick={() =>
                            history.push(`/challenges/${chal.slug}`)
                          }
                        >
                          <div className="card-body py-auto text-center">
                            {/* <h1 className="display-4">{chal.difficulty}</h1> */}
                            <h3 className="font-weight-bold">
                              {chal.title ? chal.title : <Skeleton />}
                            </h3>
                            <p className="small">
                              {chal.excerpt ? (
                                chal.excerpt.replace(/<[^>]*>?/gm, "")
                              ) : (
                                <Skeleton />
                              )}
                              ...
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                </div>
              </div>
            </div>
          </div>

          {jobApp && jobApp.next ? (
            <button
              className="btn bg-white shadow my-4 mx-auto d-flex align-items-center"
              onClick={() => {
                const nextjobApp = `/categories/${jobApp.next.slug}`;

                history.push(nextjobApp);
              }}
            >
              <i className="fa fa-forward pull-left"></i>
              <span
                style={{
                  paddingLeft: "15px",
                  paddingLight: "5px",
                  width: "100%",
                  display: "inline-block",
                  textAlign: "left",
                }}
              >
                {" "}
                Next Tutorial
                <br />
                <small
                  style={{
                    width: "100%",
                    display: "inline-block",
                    textAlign: "left",
                  }}
                >
                  Navigate to next step
                </small>
              </span>
            </button>
          ) : null}
        </div>
        {screenListOpen ? <NonTutorialMenu /> : null}
      </div>

      <ConfirmationModal
        confirmModalOpen={confirmModalOpen}
        setConfirmModalOpen={setConfirmModalOpen}
        deleteObject={deleteObject}
        deleteJobApp={(e) => deleteJobApp(jobAppId)}
        deleteJobAppUpdate={(e) => deleteJobAppUpdate(deleteObject.id)}
      />
    </>
  );
};

const ConfirmationModal = (props) => {
  return (
    <div
      class={`modal confirmation-modal ${
        props.confirmModalOpen ? "d-block" : "d-none"
      }`}
      tabIndex="-1"
    >
      <div className="modal-dialog modal-sm">
        <div className="modal-content p-4 border-0 shadow">
          <p>
            This{" "}
            {props.deleteObject === "jobApp"
              ? "job application"
              : "application update"}{" "}
            deletion cannot be reversed - are you sure you want to proceed?
          </p>
          <div className="d-flex justify-content-between align-items-center">
            <button
              type="button"
              className="btn btn-secondary btn-sm shadow"
              onClick={(e) => props.setConfirmModalOpen(false)}
            >
              Go back
            </button>
            <button
              type="button"
              className="btn bg-info text-white btn-sm shadow"
              onClick={(e) => {
                e.preventDefault();
                if (props.deleteObject.type === "jobApp") {
                  props.deleteJobApp();
                } else if (props.deleteObject.type === "jobAppUpdate") {
                  props.deleteJobAppUpdate();
                }

                props.setConfirmModalOpen(false);
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobApplicationsContainer;
