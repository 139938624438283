import { Link } from "react-router-dom";
import React from "react";
import { useSelector } from "react-redux";

export default function () {
  const { categories, links } = useSelector((state) => state.footerLinks);

  return (
    <div className="col-lg-10 small row mx-auto my-4">
      <div className="col-lg-6 mb-4">
        <h4>Programming Categories</h4>

        {categories.map((cat) => (
          <li
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <Link to={`/categories/${cat.slug}`}>
              {cat.name} Interview Questions
            </Link>
          </li>
        ))}
      </div>
      <div className="col-lg-6 mb-4">
        <h4>Popular Lessons</h4>

        {links.map((l) => (
          <li
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            <a href={l.href}>{l.title}</a>
          </li>
        ))}
      </div>
    </div>
  );
}
