import "./ChallengeTabs.css";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import React from "react";
import { actions } from "./redux/StateReducer";
import { isMobileDevice } from "./helpers/utils";

const ChallengeTabs = React.forwardRef((props, ref) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const challenge = useSelector((state) => state.challenge);
  const user = useSelector((state) => state.user);

  const { tutorialSlug } = props;
  const [width, setWidth] = React.useState(1);
  const [top, setTop] = React.useState(1);
  const [widthPct, setWidthPct] = React.useState("25%");

  React.useEffect(() => {
    setWidth(document.getElementById("content-container").offsetWidth);
    setTop(document.getElementById("algodaily-nav")?.offsetHeight);

    setWidthPct(
      challenge.has_trace && !(challenge.locked && !user?.paid_active)
        ? "25%"
        : "33.3%"
    );

    const challengeTabs = document.getElementById("challenge-tabs");
    if (challengeTabs) {
      document.getElementById("content-container").style.paddingTop =
        challengeTabs.clientHeight + "px";
    }
  });

  return (
    <div
      id="challenge-tabs"
      className="challenge-tabs position-fixed text-center mx-auto"
      ref={ref}
      style={{
        width: width,
        top: top,
        zIndex: 99,
      }}
    >
      <ul className="nav nav-pills">
        <li className="nav-item" style={{ width: widthPct }}>
          <button
            className={`nav-link small cursor-pointer border-0 py-2 px-1 mx-auto rounded-0 w-100 ${
              !["tests", "solutions", "completions"].some((slug) =>
                location.pathname.endsWith(slug)
              )
                ? "active"
                : "toolbar-gray"
            }`}
            onClick={() => {
              history.push(`/challenge_slides/${tutorialSlug}`);
            }}
            style={{ fontSize: "0.8rem" }}
          >
            TUTORIAL
          </button>
        </li>
        {/* <li className="nav-item" style={{ width: widthPct }}>
          <button
            className={`nav-link small cursor-pointer border-0 py-2 px-1 mx-auto rounded-0 w-100 ${
              location.pathname.includes("tests") ? "active" : "toolbar-gray"
            }`}
            onClick={() => {
              history.push(`/challenge_slides/${tutorialSlug}/tests`);
            }}
            style={{ fontSize: "0.8rem" }}
          >
            TESTS
          </button>
        </li> */}
        <li className="nav-item" style={{ width: widthPct }}>
          <button
            className={`nav-link small cursor-pointer border-0 py-2 px-1 mx-auto rounded-0 w-100 ${
              location.pathname.endsWith("/solutions") ||
              location.pathname.endsWith("/solutions/")
                ? "active"
                : "toolbar-gray"
            }`}
            onClick={() => {
              history.push(`/challenge_slides/${tutorialSlug}/solutions`);
            }}
            style={{ fontSize: "0.8rem" }}
          >
            SOLUTIONS
          </button>
        </li>
        <li className="nav-item" style={{ width: widthPct }}>
          <button
            className={`nav-link small cursor-pointer border-0 py-2 px-1 mx-auto rounded-0 w-100 ${
              location.pathname.includes("completions")
                ? "active"
                : "toolbar-gray"
            }`}
            onClick={() => {
              history.push(`/challenge_slides/${tutorialSlug}/completions`);
            }}
            style={{ fontSize: "0.8rem" }}
          >
            SUBMISSIONS
          </button>
        </li>
        {challenge.has_trace &&
        !(challenge.locked && !user?.paid_active) &&
        !isMobileDevice() ? (
          <li className="nav-item" style={{ width: widthPct }}>
            <button
              className={`nav-link small cursor-pointer border-0 py-2 px-1 mx-auto rounded-0 w-100 toolbar-gray`}
              onClick={() =>
                dispatch({ type: actions.SET_VIZ_CONTAINER, payload: true })
              }
              style={{ fontSize: "0.8rem" }}
            >
              VISUALIZE
            </button>
          </li>
        ) : null}

        {/* {user && user.admin ? (
          <li
            className="nav-item"
            style={{ width: widthPct, fontSize: "0.8rem" }}
            onClick={() =>
              window.open(
                `/admin/language_content/${challenge.language_content_id}/edit`
              )
            }
          >
            <button className="nav-link small cursor-pointer border-0 py-2 px-1 mx-auto rounded-0 w-100 toolbar-gray">
              <i className="fa fa-pen"></i>
            </button>
          </li>
        ) : null} */}
      </ul>
    </div>
  );
});

export default ChallengeTabs;
