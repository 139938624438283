import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { Link, useHistory } from "react-router-dom";

import FooterLinks from "./FooterLinks";
import React from "react";
import Skeleton from "react-loading-skeleton";
import enUS from "date-fns/locale/en-US";
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import { isMobileDevice } from "./helpers/utils";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { useSelector } from "react-redux";

const ActivityLog = (props) => {
  let history = useHistory();
  const user = useSelector((state) => state.user);

  const [name, setName] = React.useState("");
  const [completionEvents, setCompletionEvents] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState({});
  const [isLoaded, setIsLoaded] = React.useState(false);

  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const min = new Date();
  min.setHours(8);

  const fetchCompletions = () => {
    fetch(`/api/activity-log`)
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          setName(obj.name);
          const transformedEvents = obj.combinedCompletions.map((comp) => {
            return {
              title: comp.title,
              start: new Date(comp.start),
              end: new Date(comp.end),
              slug: comp.slug,
              type: comp.type,
            };
          });

          setCompletionEvents(transformedEvents);
          setIsLoaded(true);

          if (document.querySelector("#bottom-left-cell")) {
            document.querySelector("#bottom-left-cell .card").style.height =
              document.querySelector("#bottom-right-cell .card").clientHeight +
              "px";
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // TO DO: handle errors
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
        }
      );
  };

  React.useEffect(() => {
    if (!user) {
      window.location.replace("/users/sign_in");
    }

    fetchCompletions();
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [userInfo]);

  if (!isLoaded) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  return (
    <>
      <div className={`${isMobileDevice() ? "p-2" : "p-0"} container`}>
        <div className="small mb-2">
          <Link to={`/dashboard`}>
            <strong>Home</strong>
          </Link>{" "}
          | <strong>Activity Log</strong>
        </div>
        <h3
          className="mt-2 mb-4"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {name ? (
            <>Hey, {name}! Here's what you've done recently.</>
          ) : (
            <Skeleton></Skeleton>
          )}
        </h3>
        <>
          <div className="row">
            <div className="col mb-4 rounded-lg">
              <Calendar
                localizer={localizer}
                events={completionEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600 }}
                scrollToTime={min}
                onSelectEvent={(ev) => {
                  history.push(`/${ev.type}/${ev.slug}`);
                }}
                drilldownView="agenda"
              />
            </div>
          </div>
        </>
        <FooterLinks />
      </div>
    </>
  );
};

export default ActivityLog;
