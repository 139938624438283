import { isMobileDevice, postCompletion, titleize } from "./helpers/utils";

import React from "react";
import Skeleton from "react-loading-skeleton";
import { actions } from "./redux/StateReducer";
import { useDispatch } from "react-redux";

const ONE_LINER = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontSize: "1rem",
};

const OnboardingFlow = (props) => {
  const dispatch = useDispatch();

  const { fetchUserInfo, userInfo } = props;
  const [step, setStep] = React.useState(1);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [userInfo]);

  function setFirstCompletion(tutorialType, tutorialSlug) {
    postCompletion(tutorialType, tutorialSlug, {}, () => setStep(step + 1));
  }

  function updateSettings(attribute, value, callback) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const data = { onboarded: true };
    data[attribute] = value;

    fetch(`/api/settings`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        brochure: data,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          if (attribute === "view") {
            dispatch({
              type: actions.SET_VIEW,
              payload: obj.view,
            });
          }
          callback();
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return (
    <>
      <div className={`${isMobileDevice() ? "p-2" : "p-0"} container`}>
        <h3 className="mt-2 mb-4">
          {userInfo ? (
            <>
              Welcome, {userInfo.name}! Let's get you set up on the AlgoDaily
              platform.
            </>
          ) : (
            <Skeleton></Skeleton>
          )}
        </h3>

        <div className="row">
          <div
            className={`col-lg-12 border bg-white no-gutters rounded-lg mb-4 ${
              isMobileDevice() ? "p-2" : "p-5"
            } cursor-pointer`}
          >
            {step === 1 ? (
              <>
                <p className="mb-4 lead">
                  Before going to your dashboard, please help us best understand
                  your needs by filling out this 4-question survey. It will take
                  under a minute, and we'll use your answers to curate your
                  experience for optimal learning.
                </p>
                <h3 class="mb-4">
                  (1/4) What do you need help with most in regards to technical
                  interviews?
                </h3>
                <div class="col-lg-6">
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion(
                          "lessons",
                          "how-to-use-algodaily-and-get-the-most-out-of-it"
                        )
                      }
                    >
                      Getting the Interview and Knowing How to Prepare
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion(
                          "lessons",
                          "a-gentle-refresher-into-arrays-and-strings"
                        )
                      }
                    >
                      Whiteboard Algorithms and Data Structures
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion(
                          "lessons",
                          "a-systems-design-primer-for-new-engineers"
                        )
                      }
                    >
                      Mastering Systems Design and Architecture Rounds
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion(
                          "lessons",
                          "what-does-oop-mean-intro-to-object-oriented-programming"
                        )
                      }
                    >
                      Handling Object Oriented Design Questions
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion(
                          "lessons",
                          "computer-programming-what-is-it"
                        )
                      }
                    >
                      Coding Fundamentals like Loops, Conditionals, etc.
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion(
                          "lessons",
                          "best-way-to-learn-coding-cs-degree-bootcamp-or-self-teach"
                        )
                      }
                    >
                      Software Engineering (Git, Docker, Linux, etc.)
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        setFirstCompletion("lessons", "intro-to-ml")
                      }
                    >
                      Learning Machine Learning Fundamentals
                    </button>
                  </p>
                </div>
              </>
            ) : null}
            {step === 2 ? (
              <>
                <p className="mb-4 lead">
                  We support our lessons and challenges in two forms:{" "}
                  <em>long form articles</em> in <strong>Article Mode</strong>{" "}
                  for a familiar learning experience, or{" "}
                  <em>interactive slides</em> in{" "}
                  <strong>Interactive Mode</strong> to step through our content
                  like a slide deck.
                </p>
                <h3 class="mb-4">(2/4) Which view do you prefer?</h3>
                <div class="col-lg-12">
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        updateSettings("view", "article", () =>
                          setStep(step + 1)
                        )
                      }
                    >
                      Article Mode (default)
                    </button>
                  </p>
                  <p>
                    <button
                      className="genric-btn info e-large px-2 px-lg-3"
                      style={ONE_LINER}
                      onClick={() =>
                        updateSettings("view", "interactive", () =>
                          setStep(step + 1)
                        )
                      }
                    >
                      Interactive Mode
                    </button>
                  </p>
                </div>
                <p className="mb-4 lead">
                  See the below for an example of both:
                </p>
                <img
                  src="https://storage.googleapis.com/algodailyrandomassets/marketing/mode-switch.gif?bust=a"
                  class="w-75 shadow-lg rounded"
                />
              </>
            ) : null}
            {step === 3 ? (
              <>
                <h3 class="mb-4">
                  (3/4) What is your preferred programming language?
                </h3>

                <p className="lead mb-4">
                  We don't have 100% coverage on all of these options, but we'll
                  default to this one when available.
                </p>
                <div class="col-lg-6">
                  {["javascript", "python", "java", "csharp", "cpp"].map(
                    (l) => (
                      <p>
                        <button
                          className="genric-btn info e-large px-2 px-lg-3"
                          style={ONE_LINER}
                          onClick={() =>
                            updateSettings("language", l, () =>
                              setStep(step + 1)
                            )
                          }
                        >
                          {titleize(l)}
                        </button>
                      </p>
                    )
                  )}
                </div>
              </>
            ) : null}
            {step === 4 ? (
              <>
                <h3>
                  (4/4) The AlgoDaily newsletter starts off with daily data
                  structures and algorithms interview questions for free.
                </h3>

                <p className="lead">
                  If you'd like to try the other daily newsletters, like{" "}
                  <strong>
                    Systems Design Daily, OOP Design Daily, and much more
                  </strong>
                  ,{" "}
                  {userInfo.premium ? (
                    <>
                      simply navigate to{" "}
                      <a href="/users/edit" target="_blank">
                        the settings page
                      </a>
                    </>
                  ) : (
                    <>
                      <a href="/subscriptions/discounted" target="_blank">
                        get premium access today
                      </a>
                    </>
                  )}
                  . Keep yourself accountable with daily bite-sized lessons that
                  will get you ready.
                </p>
                <p>
                  <button
                    className="genric-btn info e-large px-2 px-lg-3"
                    style={ONE_LINER}
                    onClick={() =>
                      updateSettings("course_id", 3, () => setStep(step + 1))
                    }
                  >
                    Continue with AlgoDaily DS & Algos
                  </button>
                </p>
              </>
            ) : null}
            {step === 5 ? (
              <>
                <h3>We're all set!</h3>

                <p className="lead">
                  Head back to the dashboard to start learning.
                </p>

                <p>
                  <button
                    className="genric-btn info e-large px-2 px-lg-3"
                    style={ONE_LINER}
                    onClick={() =>
                      // TODO: fix as course is currently used for newsletter as well. Very confusing
                      updateSettings("course_id", 3, () => fetchUserInfo())
                    }
                  >
                    START LEARNING ON ALGODAILY
                  </button>
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingFlow;
