import {
  LANG_TO_BOILERPLATE_MAP,
  LANG_TO_CM_MODE_MAP,
  MD_MODE_TO_LANG_MAP,
  determineCanonicalUrl,
  extractCodeFromMd,
  extractLangFromCodeBlock,
  getUrlLanguage,
  loadLanguageUtils,
  readjustForSlides,
  toastNotSignedIn,
} from "../helpers/utils";
import React, { useState } from "react";

import CodeExecutor from "../Progression/CodeExecutor";
import { UnControlled as CodeMirror } from "react-codemirror2";
import OutputContainer from "../OutputContainer";
import Toolbar from "./Toolbar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

require("codemirror/keymap/sublime");
require("codemirror/addon/comment/comment");
require("codemirror/addon/lint/lint");
require("codemirror/addon/hint/show-hint.css");
require("codemirror/addon/hint/show-hint");

const LessonCode = (props) => {
  const stateLanguage = useSelector((state) => state.language);
  const user = useSelector((state) => state.user);

  // NOTE: props.language comes from executableCodeRenderer
  // Renderer will return an array of screenCode if multi-lang
  const isMultiLang = Array.isArray(props.screenCode);

  const [editor, setEditor] = useState();
  const [editorVal, setEditorVal] = useState("");

  // If multi-language, determine if any are the user's preferred
  const matchingCodeSnippet =
    isMultiLang &&
    props.screenCode.find(
      (sc) => MD_MODE_TO_LANG_MAP[sc.props.language] === stateLanguage
    );

  const [mdMode, setMdMode] = useState(
    isMultiLang && matchingCodeSnippet
      ? matchingCodeSnippet.props.language
      : props.language || "text"
  );

  const [showResetWarning, setShowResetWarning] = useState(false);

  const history = useHistory();

  const outNodeRef = React.useRef();

  function loadEditorVal() {
    if (props.screenCode) {
      let mdMode;
      if (isMultiLang) {
        const matchingSnippetByUrl = props.screenCode.find((sc) => {
          // multi-language code tabs
          const lang = MD_MODE_TO_LANG_MAP[sc.props.language];
          return getUrlLanguage() === lang;
        });

        if (matchingSnippetByUrl) {
          // 1. Match by URL
          // Should store this here with state machine
          setEditorVal(matchingSnippetByUrl.props.value);
          mdMode = matchingSnippetByUrl.props.language;
        } else if (matchingCodeSnippet) {
          // 2. Match by app state language
          setEditorVal(matchingCodeSnippet.props.value);
          mdMode = matchingCodeSnippet.props.language;
        } else {
          // 3. Default to first of multi-lang
          setEditorVal(props.screenCode[0].props.value);
          mdMode = props.screenCode[0].props.language;
        }
      } else {
        setEditorVal(extractCodeFromMd(props.screenCode));
        mdMode = extractLangFromCodeBlock(props.language);
      }

      if (MD_MODE_TO_LANG_MAP[mdMode]) {
        setMdMode(MD_MODE_TO_LANG_MAP[mdMode]);
      } else {
        setMdMode(mdMode);
      }

      loadLanguageUtils(mdMode);
    } else {
      // 3. Initial code setup
      setEditorVal(
        `${
          props.challenge.initialCode
            ? extractCodeFromMd(props.challenge.initialCode)
            : "Let's continue on the next screen!"
        }\n`
      );
      const mdMode = extractLangFromCodeBlock(props.challenge.initialCode);
      loadLanguageUtils(mdMode);
      setMdMode(mdMode);
    }
  }

  React.useEffect(() => {
    loadEditorVal();
  }, [window.location.href, props.screenCode, props.language, stateLanguage]);

  React.useEffect(() => {
    loadLanguageUtils(mdMode);
  }, [window.location.href, mdMode]);

  const runCode = () => {
    if (!user) {
      return toastNotSignedIn("run code on the site");
    }

    const codeExecutor = new CodeExecutor({
      val: editor.getValue(),
      language: MD_MODE_TO_LANG_MAP[mdMode],
      isTestSubmission: props.isTestSubmission || false,
      outNodeRef:
        outNodeRef.current || document.getElementById("curr-output-container"),
    });
    codeExecutor.runCode();
  };

  var options = {
    autoCloseBrackets: true,
    lineNumbers: true,
    mode: { name: LANG_TO_CM_MODE_MAP[mdMode], globalVars: true },
    theme: "elegant",
    readOnly: false,
    keyMap: "sublime",
    tabSize: 2,
    extraKeys: {
      "Ctrl-Enter": runCode,
      "Cmd-Enter": runCode,
      "Cmd-/": "toggleComment",
      "Ctrl-/": "toggleComment",
      Tab: "defaultTab",
      Tab: (cm) => {
        if (cm.getMode().name === "null") {
          cm.execCommand("insertTab");
        } else {
          if (cm.somethingSelected()) {
            cm.execCommand("indentMore");
          } else {
            cm.execCommand("insertSoftTab");
          }
        }
      },
      "Shift-Tab": (cm) => cm.execCommand("indentLess"),
    },
    hintOptions: { completeSingle: false },
  };

  return (
    <>
      <Toolbar
        challenge={props.challenge}
        // below parameter is new lang
        changeLanguage={(language) => {
          let mdMode = "javascript";
          // TOFIX: HAVE A CYCLICAL ISSUE
          // setMdMode triggers originalLanguage in Toolbar to change to new language, but originalLanguage should stay the original...
          if (isMultiLang || props.isTestSubmission /* for last screen */) {
            /* Multi-language code tabs */
            const newUrl = determineCanonicalUrl(
              MD_MODE_TO_LANG_MAP[language],
              {
                slug: "",
              }
            );
            return history.push(newUrl);
          } else {
            const normalizedLang = MD_MODE_TO_LANG_MAP[props.language];
            if (language === normalizedLang) {
              setEditorVal(extractCodeFromMd(props.screenCode));
              mdMode = normalizedLang;
            } else {
              setEditorVal(LANG_TO_BOILERPLATE_MAP[language]);
              mdMode = language;
            }
          }

          if (MD_MODE_TO_LANG_MAP[mdMode]) {
            setMdMode(MD_MODE_TO_LANG_MAP[mdMode]);
          } else {
            setMdMode(mdMode);
          }

          loadLanguageUtils(mdMode);
        }}
        code={editorVal} // for conversion
        language={mdMode} // TODO: examine mdMode and potentially rename
        languagesSupported={props.languagesSupported}
        resetCode={() => setShowResetWarning(true)}
        runCode={runCode}
        type="lesson"
      />
      {showResetWarning ? (
        <div className="modal d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog shadow" role="document">
            <div
              className="modal-content text-white p-2 shadow"
              style={{ backgroundColor: "#2d2d2d" }}
            >
              <div className="modal-header">
                <h4 className="modal-title m-auto">Are you sure?</h4>
              </div>
              <div className="modal-body">
                <p>
                  This will wipe out your code and replace it with the original
                  code snippet that was here.
                </p>
                <div className="modal-footer d-flex justify-content-between">
                  <button
                    type="button"
                    className="genric-btn info my-2"
                    onClick={() => setShowResetWarning(false)}
                  >
                    BACK
                  </button>
                  <button
                    type="button"
                    className="genric-btn info my-2"
                    onClick={() => {
                      loadEditorVal();
                      setShowResetWarning(false);
                    }}
                  >
                    CONFIRM RESET
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {CodeMirror && (
        <CodeMirror
          value={editorVal}
          autoCursor={false}
          autoScroll={false}
          onChange={(editor, data, value) => {
            setEditorVal(value);
          }}
          editorDidMount={(mountedEditor) => {
            if (!editor) {
              setEditor(mountedEditor);
            }
            readjustForSlides();
          }}
          options={options}
        />
      )}
      <OutputContainer ref={outNodeRef} />
    </>
  );
};

export default LessonCode;
