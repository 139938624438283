import {
  getFormattedDate,
  isMobileDevice,
  tutorialContainerSize,
} from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import AppNav from "./AppNav";
import CourseCurriculum from "./CourseCurriculum";
import { Link } from "react-router-dom";
import NonTutorialMenu from "./NonTutorialMenu";
import React from "react";
import ReactTooltip from "react-tooltip";
import ScreenRenderer from "./ScreenRenderer";
import { actions } from "./redux/StateReducer";

const QuizContainer = (props) => {
  const dispatch = useDispatch();

  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );
  const notes = useSelector((state) => state.notes);
  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);

  const [screen, setScreen] = React.useState({
    kind: "info screen",
    content: "",
    slug: "",
  });

  const fetchNewScreen = () => {
    fetch(`/api/screens/random`)
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          setScreen(obj);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  React.useEffect(() => {
    // Fetching notes here since it'll only need to be dynamic in tutorials
    if (user) {
      fetchNewScreen();
    }
  }, [user]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <AppNav completed={false} title="Course Builder Quiz" />
      <div className="row no-gutters w-100 d-flex justify-content-around mt-5">
        <div className="col-lg-6 col-md-8 col-sm-10 col-12">
          <div className="row d-flex justify-content-around">
            <ScreenRenderer screen={screen} screens={[]} />
          </div>
          <div className="row d-flex justify-content-around">
            <button
              className="btn btn-lg btn-gray border-0"
              onClick={fetchNewScreen}
            >
              Next Question
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizContainer;
