import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { forwardRef, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { isMobileDevice, strToSlug } from "./helpers/utils";

import { CSS } from "@dnd-kit/utilities";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const ChallengeManager = (props) => {
  const history = useHistory();

  const user = useSelector((state) => state.user);

  // for sorting of lessons
  const [activeId, setActiveId] = useState(null);

  const [challenges, setChallenges] = useState([]);
  const [selectedChallenge, setSelectedChallenge] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [modifyOrder, setModifyOrder] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function updateChallenge(slug, newChalAttrs) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    return fetch(`/api/challenges/${slug}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({ challenge: newChalAttrs }),
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const items = challenges;
      // setItems((items) => {
      const oldIndex = items.findIndex((c) => c.slug === active.id);
      const newIndex = items.findIndex((c) => c.slug === over.id);

      const challengesOrdered = arrayMove(items, oldIndex, newIndex);

      // get new sequences
      challengesOrdered.forEach((c, i) => {
        c.sequence = i;
      });

      const promises = [];
      challengesOrdered.forEach((chal, idx) => {
        promises.push(
          updateChallenge(chal.slug, {
            sequence: parseInt(idx),
            newsletter_sequence: parseInt(idx),
          })
        );
      });

      Promise.all(promises).then(() => {
        setChallenges(challengesOrdered);
      });
    }
  }

  const fetchChallenges = () => {
    fetch(`/api/challenges`)
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          setChallenges(obj);
          setSelectedChallenge(obj[0]);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // TO DO: handle errors
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
        }
      );
  };

  React.useEffect(() => {
    if (!(user && user.admin)) {
      console.log("redirecting");
      return window.location.replace("/");
    }

    fetchChallenges();
  }, [user]);

  React.useEffect(() => {
    const challengeEditorHeight = document.getElementById("challenge-editor")
      ?.clientHeight;
    const challengeSelectorEl = document.getElementById("challenge-selector");

    if (challengeSelectorEl) {
      challengeSelectorEl.style.height = challengeEditorHeight + "px";
    }
  });

  if (!isLoaded) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  const challengeRows = modifyOrder ? (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={challenges}
        strategy={verticalListSortingStrategy}
      >
        {challenges.map((c) => (
          <ChallengeRowModifier challenge={c} />
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <Item
            id={activeId}
            challenge={challenges.find((c) => c.slug === activeId)}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  ) : (
    <>
      {challenges.map((c) => (
        <div className="row mb-2">
          <div
            className={`rounded d-flex justify-content-between border w-100 p-2 cursor-pointer ${
              c.slug === selectedChallenge.slug
                ? "bg-info text-white"
                : "bg-white"
            }`}
            onClick={() => setSelectedChallenge(c)}
          >
            <span>{c.sequence}</span> <span>{c.title}</span>
          </div>
        </div>
      ))}
    </>
  );
  return (
    <>
      <div className={`${isMobileDevice() ? "p-2" : "p-0"} container`}>
        <h1>Challenge Manager</h1>
        <label>
          <input
            type="checkbox"
            className="mr-2"
            checked={modifyOrder}
            onChange={() => setModifyOrder(!modifyOrder)}
          />
          Enable drag and drop to modify sequences
        </label>
        <div className="container">
          <div className="row">
            <div
              id="challenge-selector"
              className="col-lg-5"
              style={{ overflowY: "scroll" }}
            >
              <div className="row mb-2">
                <div
                  className={`bg-warning rounded d-flex justify-content-between border w-100 p-2 cursor-pointer`}
                  onClick={() =>
                    setSelectedChallenge({
                      title: "newTitle",
                      sequence: challenges.length,
                      newsletter_sequence: challenges.length,
                      locked: false,
                      published: false,
                      slug: "new-slug",
                      question: "Question",
                      image_url: "image",
                      kind: "algorithms",
                    })
                  }
                >
                  Add New Challenge
                </div>
              </div>
              {challengeRows}
            </div>
            <div className="col-lg-7">
              <div id="challenge-editor" className="border bg-white p-lg-4">
                <div class="form-group">
                  <label for="title">Title</label>
                  <input
                    class="form-control"
                    value={selectedChallenge.title}
                    onChange={(e) =>
                      setSelectedChallenge({
                        ...selectedChallenge,
                        title: e.target.value,
                        slug: strToSlug(e.target.value),
                      })
                    }
                  />
                </div>
                <div className="d-flex">
                  <div class="form-group col-lg-6">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={selectedChallenge.published}
                      onChange={(e) =>
                        setSelectedChallenge({
                          ...selectedChallenge,
                          published: e.target.value,
                        })
                      }
                    />
                    <label className="form-check-label" for="published">
                      Published
                    </label>
                  </div>
                  <div class="form-group col-lg-6">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      checked={selectedChallenge.locked}
                      onChange={(e) =>
                        setSelectedChallenge({
                          ...selectedChallenge,
                          locked: e.target.value,
                        })
                      }
                    />
                    <label className="form-check-label" for="published">
                      Locked
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div class="form-group col-lg-4">
                    <label for="title">Sequence</label>
                    <input
                      class="form-control"
                      value={selectedChallenge.sequence}
                      onChange={(e) =>
                        setSelectedChallenge({
                          ...selectedChallenge,
                          sequence: e.target.value,
                          newsletter_sequence: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div class="form-group col-lg-4">
                    <label for="title">Slug</label>
                    <input
                      class="form-control"
                      value={selectedChallenge.slug}
                      onChange={(e) =>
                        setSelectedChallenge({
                          ...selectedChallenge,
                          slug: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div class="form-group col-lg-4">
                    <label for="title">Kind</label>
                    <select
                      class="form-control"
                      value={selectedChallenge.kind}
                      onChange={(e) =>
                        setSelectedChallenge({
                          ...selectedChallenge,
                          kind: e.target.value,
                        })
                      }
                    >
                      <option value="algorithms">Algorithms</option>

                      <option value="frontend">Frontend</option>

                      <option value="Learn">Learn</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label for="title">Question</label>
                  <textarea
                    class="form-control"
                    value={selectedChallenge.question}
                    onChange={(e) =>
                      setSelectedChallenge({
                        ...selectedChallenge,
                        question: e.target.value,
                      })
                    }
                    rows={10}
                  />
                </div>
                <div class="form-group">
                  <label for="title">Image URL</label>
                  <input
                    class="form-control"
                    value={selectedChallenge.image_url}
                    onChange={(e) =>
                      setSelectedChallenge({
                        ...selectedChallenge,
                        image_url: e.target.value,
                      })
                    }
                  />
                </div>
                {selectedChallenge.id ? (
                  <button
                    className="btn bg-info text-white"
                    onClick={() =>
                      history.push(`/challenges/${selectedChallenge.slug}/edit`)
                    }
                  >
                    Edit {selectedChallenge.title}
                  </button>
                ) : (
                  <button
                    className="btn bg-info text-white"
                    onClick={() =>
                      updateChallenge(
                        selectedChallenge.slug,
                        selectedChallenge
                      ).then((obj) => {
                        setSelectedChallenge(obj);
                        const newChallenges = [...challenges];
                        const orgIdx = newChallenges.findIndex(
                          (c) => c.slug === obj.slug
                        );
                        if (orgIdx > -1) {
                          newChallenges[orgIdx] = obj;
                        } else {
                          newChallenges.push(obj);
                        }
                        console.log("adding saved obj to list");
                        setChallenges(newChallenges);
                      })
                    }
                  >
                    Save {selectedChallenge.title}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ChallengeRowModifier = (props) => {
  const { challenge } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: challenge.slug });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="row mb-2">
      <div
        className="bg-white rounded d-flex justify-content-between border w-100 p-2 cursor-grab"
        style={style}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <span>
          <i className="fa fa-bars mr-4"></i> {challenge.sequence}
        </span>{" "}
        <span>{challenge.title}</span>
      </div>
    </div>
  );
};

export const Item = forwardRef(({ id, ...props }, ref) => {
  const { challenge } = props;
  return (
    <div className="row">
      <div className="bg-white rounded d-flex justify-content-between border w-100 p-2">
        <span>
          {" "}
          <i className="fa fa-bars mr-4" /> {challenge.sequence}
        </span>{" "}
        <span>{challenge.title}</span>
      </div>
    </div>
  );
});

export default ChallengeManager;
