import {
  LANG_TO_CM_MODE_MAP,
  extractCodeFromMd,
  extractLangFromCodeBlock,
  sqlToJsDate,
} from "./helpers/utils";

import CodeSnippet from "./CodeSnippet";
import React from "react";

const UserCompletions = (props) => {
  const [completions, setCompletions] = React.useState([]);
  const { challenge, user } = props;

  React.useEffect(() => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    try {
      fetch(`/my_completions/${challenge.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
      })
        .then((response) => response.json())
        .then((parsed) => {
          setCompletions(parsed);
          window.scrollTo(0, 0);
          $("#content-container").scrollTop(0);
        });
    } catch (e) {
      console.log(e);
    }
  }, [challenge.id]);

  const userCompletions = completions
    .filter((completion) => {
      return completion.code && completion.code.length;
    })
    .map((completion) => {
      const completionLang =
        extractLangFromCodeBlock(completion.code) || "text";

      return (
        <div className="mb-4">
          <div
            className="p-2 text-dark font-weight-bold border-0 m-0 small"
            style={{
              backgroundColor: "#f0f2f7",
              borderRadius: "0.5em 0.5em 0 0",
            }}
          >
            Submitted {sqlToJsDate(completion.created_at)} in{" "}
            {LANG_TO_CM_MODE_MAP[completionLang].toUpperCase()}
            {completion.performance ? (
              <>
                <br />
                <strong>Performance: </strong>
                {parseFloat(completion.performance).toFixed(2)} ms
              </>
            ) : null}
          </div>

          <CodeSnippet
            code={extractCodeFromMd(completion.code)}
            hasHeader={true}
            languageCode={completionLang}
          />
        </div>
      );
    });

  return (
    <div className="p-4 px-5">
      <h3 className="font-weight-bold">Your Submissions</h3>
      {userCompletions.length ? (
        userCompletions
      ) : (
        <>
          <p>
            You haven't submitted any code for this challenge yet. Solve the
            problem by passing all the test cases, and your submissions will
            appear here.
          </p>
        </>
      )}
    </div>
  );
};

export default UserCompletions;
