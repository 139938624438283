import { Link } from "react-router-dom";
import React from "react";
import { useParams } from "react-router-dom";

const CrashCourseNav = (props) => {
  const [sectionOpened, setSectionOpened] = React.useState(
    parseInt(localStorage && localStorage.getItem("adCrashCourseSectionOpened"))
  );
  const [showDates, setShowDates] = React.useState(
    localStorage && localStorage.getItem("adCrashCourseShowDates") === "true"
  );

  const { tutorialSlug } = useParams();

  const weekDays = [
    "Jan 3 - Jan 9",
    "Jan 10 - Jan 16",
    "Jan 17 - Jan 23",
    "Jan 24 - Jan 30",
    "Jan 31 - Feb 6",
    "Feb 7 - Feb 13",
    "Feb 14 - Feb 20",
    "Feb 21 - Feb 27",
  ];

  return (
    <>
      <div className="form-check">
        <input
          type="checkbox"
          className="form-check-input"
          onChange={() => {
            const newVal = !showDates;
            localStorage.setItem("adCrashCourseShowDates", newVal);
            setShowDates(newVal);
          }}
          checked={showDates}
        />
        <label className="form-check-label small" for="viewSwitch">
          Show Live Cohort Dates
        </label>
      </div>
      <ul className="list-group list-group-root d-block mb-4 pb-4 pl-0">
        {props.crashCourse.map((week, i) => {
          if (sectionOpened != i) {
            return (
              <li
                className="list-group-item list-group-item-action font-weight-bold cursor-pointer pl-0"
                onClick={() => {
                  localStorage.setItem("adCrashCourseSectionOpened", i);
                  setSectionOpened(i);
                }}
              >
                <i className="fa fa-caret-right"></i> Week {i + 1}
                {showDates ? ` - ${weekDays[i]}` : ""}
              </li>
            );
          }

          return (
            <>
              <li
                className="list-group-item list-group-item-action font-weight-bold cursor-pointer pl-0"
                onClick={() => {
                  localStorage.setItem("adCrashCourseSectionOpened", null);
                  setSectionOpened(null);
                }}
              >
                <i className="fa fa-caret-down"></i> Week {i + 1}
                {showDates ? ` - ${weekDays[i]}` : ""}
              </li>
              <ul className="list-group list-group-flush">
                {week.map((tut) => (
                  <Link
                    to={`/${tut.type}/${tut.slug}`}
                    className={`${
                      tut.slug === tutorialSlug ? "active" : ""
                    } list-group-item list-group-item-action font-weight-light pl-4`}
                    style={
                      tut.completed
                        ? {
                            color: "lightgray",
                          }
                        : null
                    }
                  >
                    {tut.title.length > 75
                      ? tut.title.slice(0, 75) + "..."
                      : tut.title}
                    {tut.video ? (
                      <i
                        className="fa fa-video my-auto"
                        style={{
                          marginLeft: "1em",
                          fontSize: "0.8em",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                      </i>
                    ) : null}
                  </Link>
                ))}
              </ul>
            </>
          );
        })}
      </ul>
    </>
  );
};

export default CrashCourseNav;
