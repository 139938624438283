import "./SectionContainer.css";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { forwardRef, useState } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  extractOnlyText,
  isMobileDevice,
  postCompletion,
  titleize,
} from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import Badges from "./Badges";
import { CSS } from "@dnd-kit/utilities";
import { Helmet } from "react-helmet";
import { RIETextArea } from "riek";
import ReactMarkdown from "react-markdown/with-html";
import Skeleton from "react-loading-skeleton";
import Video from "./Video";
import { actions } from "./redux/StateReducer";

const metaInfo = (tut, user) => (
  <div className="d-flex justify-content-start align-items-center small">
    {tut.locked && !(user && user.paid_active) ? (
      <span className="mr-2 small">
        <i class="fa fa-lock my-auto"></i> Premium
      </span>
    ) : (
      <span className="mr-2 small">
        <i class="fa fa-lock-open my-auto"></i> Unlocked
      </span>
    )}
    {tut.video ? (
      <span className="mr-2 small">
        <i class="fa fa-video my-auto"></i> Video
      </span>
    ) : (
      <span className="mr-2 small">
        <i class="fa fa-book my-auto"></i> Interactive
      </span>
    )}
  </div>
);

const SectionContainerEditor = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);
  const language = useSelector((state) => state.language);
  const user = useSelector((state) => state.user);

  const [category, setCategory] = useState({});

  // for sorting of lessons
  const [activeId, setActiveId] = useState(null);

  let { sectionSlug } = useParams();

  const location = useLocation();

  // Bounce if not an admin
  if (!(user && user.admin)) {
    history.push(location.pathname.replace("/edit", ""));
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const resourceName = location.pathname.includes("section")
    ? "section"
    : "category";
  const resourceNamePlural = location.pathname.includes("sections")
    ? "sections"
    : "categories";

  React.useEffect(() => {
    fetch(`/api/${resourceNamePlural}/${sectionSlug}`)
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          // fixme
          const parsedObj = JSON.parse(obj[resourceName]);
          setCategory(Object.assign({}, parsedObj, { next: obj.next }));

          if (resourceName === "section") {
            dispatch({
              type: actions.SET_ACTIVE_SECTIONS,
              payload: [parsedObj],
            });
          } else {
            dispatch({
              type: actions.SET_ACTIVE_SECTIONS,
              payload: [],
            });
          }

          window.scrollTo(0, 0);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [sectionSlug]);

  if (!category.slug) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  const challengesLength = category.challenges.length || 0;
  const completedChallenges =
    category.challenges.filter((c) => c.completed) || 0;
  const lessonsLength = category.lessons.length || 0;
  const completedLessons = category.lessons.filter((l) => l.completed) || 0;

  const completedString = isMobileDevice() ? "" : " Completed";

  const pctCompleted = Math.floor(
    ((completedChallenges.length + completedLessons.length) /
      (challengesLength + lessonsLength)) *
      100
  );

  const progressBar = (
    <div className="progress" style={{ height: "16px" }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${pctCompleted}%` }}
        aria-valuenow={pctCompleted}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        {challengesLength || lessonsLength
          ? completedChallenges.length / challengesLength +
              completedLessons.length / lessonsLength >=
            0.25
            ? `${completedChallenges.length + completedLessons.length} / ${
                challengesLength + lessonsLength
              }${completedString}`
            : null
          : null}
      </div>
    </div>
  );

  function updateSection(id, newSection) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/sections/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(newSection),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          return true;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function updateTutorial(id, newLesson) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/lessons/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify(newLesson),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          return true;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const items = category.lessons.map((l) => l.slug);
      // setItems((items) => {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      const lessonsOrdered = arrayMove(items, oldIndex, newIndex);

      console.log(lessonsOrdered);
      // this is not a swap, this rearranges the array
      // so the dropped item and items after get updated

      lessonsOrdered.forEach((lessonSlug, idx) => {
        updateTutorial(lessonSlug, {
          // don't include rest of data b/c it'll get overwritten
          // increment bc sequence is 1-based
          sequence: parseInt(idx + 1),
        });
      });

      setCategory(
        Object.assign({}, category, {
          lessons: lessonsOrdered.map((slug, idx) => {
            return {
              ...category.lessons.find((l) => slug === l.slug),
              sequence: idx + 1,
            };
          }),
        })
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {(category &&
            (category.name || category.title) &&
            "AlgoDaily - " + titleize(category.name || category.title)) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."}
        </title>
        <meta
          name="description"
          content={
            (category && category.description) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:image"
          content={
            category && category.image_url
              ? category.image_url
              : `https://storage.googleapis.com/algodailyrandomassets/curriculum/${category.slug}/cover.jpg`
          }
        />
        <meta
          property="og:url"
          content={
            (category && `https://algodaily.com/sections/${category.slug}`) ||
            "https://algodaily.com"
          }
        />
        <meta
          property="og:title"
          content={
            (category &&
              (category.name || category.title) &&
              "AlgoDaily - " + titleize(category.name || category.title)) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:description"
          content={
            (category && category.description) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:image"
          content={
            category && category.image_url
              ? category.image_url
              : `https://storage.googleapis.com/algodailyrandomassets/curriculum/${category.slug}/cover.jpg`
          }
        />
      </Helmet>

      <div className="border bg-white no-gutters rounded-lg row d-flex align-items-center mb-4">
        <div className="col-lg-4">
          {category.image_url ? (
            <div class="w-100 p-4">
              <img src={category.image_url} class="img-fluid" />
            </div>
          ) : (
            <div class="w-100 p-4">
              <img
                src={`https://storage.googleapis.com/algodailyrandomassets/curriculum/${category.slug}/cover.jpg`}
                class="img-fluid"
              />
            </div>
          )}
        </div>
        <div className="col-lg-8 p-4">
          <h3 className="font-weight-bold">
            {category.name || category.title || <Skeleton />}
          </h3>
          {category.description ? (
            <p>
              {user.admin ? (
                <RIETextArea
                  value={category.description}
                  change={(data) => {
                    updateSection(category.slug, {
                      description: data.description,
                    });
                    const newCategory = {
                      ...category,
                      description: data.description,
                    };
                    setCategory(newCategory);
                  }}
                  classEditing="form-control"
                  propName="description"
                  rows={6}
                />
              ) : (
                <ReactMarkdown
                  source={category.description}
                  escapeHtml={false}
                />
              )}
            </p>
          ) : (
            <Skeleton />
          )}
          <div className="d-flex mb-2 flex-wrap">
            <Badges obj={category} />
          </div>
          {user ? progressBar : null}
        </div>
      </div>
      <div className="card bg-white">
        <div className={`card-body ${isMobileDevice() ? "p-2" : "p-4"} `}>
          {category.challenges && category.challenges.length ? (
            <div class="w-100">
              <h3 class="font-weight-bold">Interview Problems</h3>
              <p class="small">
                <strong
                  data-tip="<div style='max-width: 24rem;'>You'll see duplicates because a question may fall into different sections. We keep them here because we believe that it is good to sporadically review old problems. They are optional if already completed-- but reviewing them (and more importantly re-doing them with the new knowledge) will ensure that you've internalized the patterns.</div>"
                  data-html={true}
                >
                  Why do I see duplicate challenges?{" "}
                  <i className="fas fa-info-circle"></i>
                </strong>
              </p>
              <div class="row">
                {category.challenges ? (
                  category.challenges.map((chal) => (
                    <div class="col-lg-4 mb-5">
                      <div
                        class={`card ${
                          chal.completed ? "bg-white" : "text-dark bg-light"
                        } border-0 shadow rounded-lg`}
                        style={
                          chal.completed
                            ? {
                                color: "silver",
                              }
                            : null
                        }
                      >
                        <img
                          src={chal.image_url}
                          className="card-img-top rounded-top bg-white cursor-pointer"
                          style={{
                            objectFit: "cover",
                            height: "12rem",
                            opacity: chal.completed ? 0.3 : 1,
                          }}
                          onClick={() =>
                            history.push(`/challenges/${chal.slug}`)
                          }
                        />
                        <div
                          class="card-body py-auto cursor-pointer"
                          onClick={() =>
                            history.push(`/challenges/${chal.slug}`)
                          }
                        >
                          {metaInfo(chal, user)}
                          <h4
                            class="font-weight-bold"
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {chal.title ? (
                              <Link
                                to={`/challenges/${chal.slug}`}
                                style={{ color: "inherit" }}
                              >
                                {chal.title}
                              </Link>
                            ) : (
                              <Skeleton />
                            )}
                          </h4>
                          <p
                            class="small"
                            style={{
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitLineClamp: 4,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {chal.excerpt ? (
                              extractOnlyText(chal.excerpt)
                            ) : (
                              <Skeleton />
                            )}
                            ...
                          </p>
                        </div>
                        <div className="card-footer d-flex justify-content-between small">
                          <span>Difficulty: {chal.difficulty}/10</span>
                          {chal.completed ? (
                            <span>
                              <i className="far fa-check-square pr-1"></i>{" "}
                              Completed
                            </span>
                          ) : (
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                if (chal.completed) {
                                  return;
                                }
                                // we can't use addChallengeCompleton since it pulls from the challenge in app state
                                if (user) {
                                  let body = JSON.stringify({
                                    completion: {
                                      userEmail: user.email,
                                      slug: chal.slug,
                                      challenge_id: chal.id,
                                      performance: 0,
                                      language_id: language.id,
                                    },
                                  });

                                  postCompletion(
                                    "challenges",
                                    chal.slug,
                                    body,
                                    (response) => {
                                      if (response.status == 200) {
                                        const newSections = [
                                          ...course.sections,
                                        ];
                                        // have to fix category --> sections
                                        const sectionIdx = newSections.findIndex(
                                          (s) => s.slug === category.slug
                                        );
                                        const tutorialIdx = newSections[
                                          sectionIdx
                                        ].challenges.findIndex(
                                          (t) => t.slug === chal.slug
                                        );
                                        newSections[sectionIdx].challenges[
                                          tutorialIdx
                                        ].completed = true;

                                        dispatch({
                                          type: actions.SET_COURSE,
                                          payload: {
                                            ...course,
                                            sections: newSections,
                                          },
                                        });
                                      }
                                    }
                                  );
                                }
                              }}
                            >
                              <i className="far fa-square pr-1"></i> Incomplete
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Skeleton></Skeleton>
                )}
              </div>
            </div>
          ) : null}

          {category.video ? <Video video={category.video} user={user} /> : null}

          {category.cheatsheet ? (
            <>
              <h3 class="font-weight-bold">Cheat Sheet</h3>
              <ReactMarkdown source={category.cheatsheet} escapeHtml={false} />
            </>
          ) : (
            ""
          )}

          {category.lessons && lessonsLength ? (
            <div class="w-100 mb-4">
              <h3 class="font-weight-bold">Lessons</h3>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={category.lessons}
                  strategy={verticalListSortingStrategy}
                >
                  <div class="row mb-4">
                    {category.lessons.map((lesson) => {
                      return (
                        <TutorialCard
                          key={lesson.slug}
                          lesson={lesson}
                          user={user}
                        />
                      );
                    })}
                  </div>
                </SortableContext>
                <DragOverlay>
                  {activeId ? (
                    <Item
                      id={activeId}
                      lesson={category.lessons.find((l) => l.slug === activeId)}
                      user={user}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </div>
          ) : null}
        </div>
      </div>
      {/* 
      {category && category.next ? (
        <button
          className="btn bg-white shadow my-4 mx-auto d-flex align-items-center"
          onClick={() => {
            const nextCategory = `/sections/${category.next.slug}`;

            history.push(nextCategory);
          }}
        >
          <i className="fa fa-forward pull-left"></i>
          <span
            style={{
              paddingLeft: "15px",
              paddingLight: "5px",
              width: "100%",
              display: "inline-block",
              textAlign: "left",
            }}
          >
            {" "}
            Next Tutorial
            <br />
            <small
              style={{
                width: "100%",
                display: "inline-block",
                textAlign: "left",
              }}
            >
              Navigate to next step
            </small>
          </span>
        </button>
      ) : null} */}
    </>
  );
};

const TutorialCard = (props) => {
  const { lesson, key, user } = props;

  let history = useHistory();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: lesson.slug });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div class="col-lg-4 mb-4">
      <div
        class={`card ${
          lesson.completed ? "text-secondary bg-white" : "text-dark bg-light"
        } border-0 shadow rounded-lg`}
      >
        <img
          src={lesson.image_url}
          className="card-img-top rounded-top bg-white cursor-pointer"
          style={{
            objectFit: "cover",
            height: "12em",
            opacity: lesson.completed ? 0.3 : 1,
          }}
          onClick={() => history.push(`/lessons/${lesson.slug}/edit`)}
          ref={setNodeRef}
          // style={style}
          {...attributes}
          {...listeners}
          key={key}
        />
        <div
          class="card-body py-auto cursor-pointer"
          onClick={() => history.push(`/lessons/${lesson.slug}/edit`)}
        >
          {metaInfo(lesson, user)}

          <h4
            class="font-weight-bold"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
            }}
            title={lesson.title ? lesson.title : ""}
          >
            {lesson.title ? lesson.title : <Skeleton />}
          </h4>

          <p
            class="small"
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
            }}
          >
            {lesson.excerpt ? extractOnlyText(lesson.excerpt) : <Skeleton />}
            ...
          </p>
        </div>
        <div className="card-footer d-flex justify-content-between small">
          <span
            className="cursor-pointer"
            onClick={() => history.push(`/lessons/${lesson.slug}/edit`)}
          >
            Edit Tutorial
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              if (lesson) {
                if (lesson.completed) {
                  return;
                }

                postCompletion("lessons", lesson.slug, null, (lc) => {
                  const newSections = [...course.sections];
                  // have to fix category --> sections
                  const sectionIdx = newSections.findIndex(
                    (s) => s.slug === category.slug
                  );
                  const tutorialIdx = newSections[sectionIdx].lessons.findIndex(
                    (t) => t.slug === lesson.slug
                  );
                  newSections[sectionIdx].lessons[tutorialIdx].completed = true;

                  dispatch({
                    type: actions.SET_COURSE,
                    payload: { ...course, sections: newSections },
                  });
                });
              }
            }}
          >
            {lesson.completed ? (
              <>
                <i className="far fa-check-square pr-1"></i> Completed
              </>
            ) : (
              <>
                <i className="far fa-square pr-1"></i> Incomplete
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export const Item = forwardRef(({ id, ...props }, ref) => {
  const { lesson, user } = props;

  return (
    <div class="mb-4" {...props} ref={ref}>
      <div
        class={`card ${
          lesson.completed ? "text-secondary bg-white" : "text-dark bg-light"
        } border-0 shadow rounded-lg`}
      >
        <img
          src={lesson.image_url}
          className="card-img-top rounded-top bg-white cursor-pointer"
          style={{
            objectFit: "cover",
            height: "12em",
            opacity: lesson.completed ? 0.3 : 1,
          }}
          // onClick={() => history.push(`/lessons/${lesson.slug}`)}
        />
        <div
          class="card-body py-auto cursor-pointer"
          // onClick={() => history.push(`/lessons/${lesson.slug}`)}
        >
          {metaInfo(lesson, user)}

          <h4
            class="font-weight-bold"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              display: "-webkit-box",
            }}
            title={lesson.title ? lesson.title : ""}
          >
            {lesson.title ? lesson.title : <Skeleton />}
          </h4>

          <p
            class="small"
            style={{
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
            }}
          >
            {lesson.excerpt ? extractOnlyText(lesson.excerpt) : <Skeleton />}
            ...
          </p>
        </div>
      </div>
    </div>
  );
});

export default SectionContainerEditor;
