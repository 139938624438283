import React from "react";

const ShareButtons = (props) => {
  return (
    <div id="share-buttons" className="my-1">
      <a
        className="cursor-pointer pr-2"
        onClick={() =>
          window.open(
            `mailto:?Subject=${
              document.title
            }&Body=I%20saw%20this%20and%20thought%20of%20you!%20 ${encodeURIComponent(
              window.location.href
            )}`
          )
        }
      >
        <i className="fas fa-envelope fa-lg"></i>
      </a>

      <a
        className="cursor-pointer pr-2"
        onClick={() =>
          window.open(
            `http://www.facebook.com/sharer.php?u=${encodeURIComponent(
              window.location.href
            )}`
          )
        }
        target="_blank"
      >
        <i className="fab fa-facebook fa-lg"></i>
      </a>

      <a
        className="cursor-pointer pr-2"
        onClick={() =>
          window.open(
            `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
              window.location.href
            )}`
          )
        }
        target="_blank"
      >
        <i className="fab fa-linkedin fa-lg"></i>
      </a>

      <a
        className="cursor-pointer pr-2"
        onClick={() =>
          window.open(
            `http://reddit.com/submit?url=${encodeURIComponent(
              window.location.href
            )}&title=${document.title}`
          )
        }
        target="_blank"
      >
        <i className="fab fa-reddit fa-lg"></i>
      </a>

      <a
        className="cursor-pointer pr-2"
        onClick={() =>
          window.open(
            `https://twitter.com/share?url=${encodeURIComponent(
              window.location.href
            )}&text=${document.title}`
          )
        }
        target="_blank"
      >
        <i className="fab fa-twitter fa-lg"></i>
      </a>
    </div>
  );
};

export default ShareButtons;
