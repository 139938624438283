import CountUpAnimation from "../CountUpAnimation";
import React from "react";
import { toast } from "react-toastify";

export function isSectionComplete(section) {
  const completed =
    section.challenges &&
    section.challenges.every((t) => t.completed) &&
    section.lessons &&
    section.lessons.every((t) => t.completed);

  return completed;
}

export function addSectionCompletion(section, callback) {
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  try {
    fetch("/api/section_completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        section_id: section.id,
      }),
    }).then(callback);
  } catch (e) {
    console.log(e);
  }
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function addScreenCompletion(completionObj, callback) {
  const { screen, answer, points, isCorrect } = completionObj;

  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  try {
    fetch("/api/screen_completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        screen_id: screen.id,
        answer: answer,
        points: points,
        is_correct: isCorrect,
        lesson_id: screen.lesson_id,
        challenge_id: screen.challenge_id,
      }),
    })
      .then((resp) => resp.json())
      .then(callback);
  } catch (e) {
    console.log(e);
  }
}

export function deleteScreenCompletion(id, callback) {
  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  fetch(`/api/screen_completions/${id}`, {
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": token,
    },
  }).then((res) => {
    if (!res.ok) {
      throw res;
    }

    callback();
  });
}

export function recordAnswer(isCorrect, screen, answer, callback) {
  if (isCorrect) {
    const points = randomIntFromInterval(1, 100);

    addScreenCompletion({ isCorrect, screen, answer, points }, (respObj) => {
      callback(respObj);
      toast(
        <>
          Answer saved! You gained <CountUpAnimation>{points}</CountUpAnimation>{" "}
          points
        </>,
        {
          toastId: "afterAnswer",
        }
      );
    });
  } else {
    const points = randomIntFromInterval(-100, -1);

    addScreenCompletion({ isCorrect, screen, answer, points }, (respObj) => {
      callback(respObj);
      toast(
        <>
          Answer saved! You lost <CountUpAnimation>{points}</CountUpAnimation>{" "}
          points
        </>,
        {
          toastId: "afterAnswer",
        }
      );
    });
  }
}
