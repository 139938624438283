import { LANG_TO_CM_MODE_MAP, MD_MODE_TO_LANG_MAP } from "./helpers/utils";

import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { googlecode } from "react-syntax-highlighter/dist/esm/styles/hljs";

const CodeSnippet = (props) => {
  const languageCodeLower =
    props.languageCode && props.languageCode.toLowerCase();

  return (
    <div
      className={`editor clear ${props.className ? props.className : ""} ${
        props.hasHeader ? "no-round-top" : ""
      }`}
    >
      {props.hasHeader ? null : (
        <div
          className="w-100 py-1 px-2 font-weight-bold small"
          style={{
            backgroundColor: "#f0f2f7",
            borderRadius: "0.5em 0.5em 0 0",
          }}
        >
          {LANG_TO_CM_MODE_MAP[languageCodeLower]
            ? LANG_TO_CM_MODE_MAP[languageCodeLower].toUpperCase()
            : "SNIPPET"}
        </div>
      )}
      {props.code && (
        <SyntaxHighlighter
          language={MD_MODE_TO_LANG_MAP[languageCodeLower]}
          customStyle={{
            background: "#f6f8fb",
            fontSize: "1rem",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
          showLineNumbers={true}
          style={{ ...googlecode }}
        >
          {props.code}
        </SyntaxHighlighter>
      )}
    </div>
  );
};

export default CodeSnippet;
