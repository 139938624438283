import React, { Component } from "react";
import { deleteScreenCompletion, recordAnswer } from "./helpers/completions";
import { getFormattedDate, toastNotSignedIn } from "./helpers/utils";

import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";

class FillIn extends Component {
  constructor(props, context) {
    super(props, context);

    this.checkAnswer = this.checkAnswer.bind(this);

    const screen = this.props.screen;

    this.state = {
      screen: screen,
      revealAnswer: screen.screenCompletion,
      userAnswer: screen.screenCompletion
        ? screen.screenCompletion.answer
        : null,
      isCorrect: screen.screenCompletion && screen.screenCompletion.is_correct,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.screen !== this.props.screen) {
      const screen = this.props.screen;
      this.setState({
        screen: screen,
        revealAnswer: screen.screenCompletion,
        userAnswer: screen.screenCompletion
          ? screen.screenCompletion.answer
          : null,
        isCorrect:
          screen.screenCompletion && screen.screenCompletion.is_correct,
      });
    }
  }

  checkAnswer(e) {
    e.preventDefault();

    if (this.state.revealAnswer) {
      return;
    }

    if (!this.props.user) {
      return toastNotSignedIn("track work on this exercise");
    }

    const answer = document.getElementById(`answer-${this.state.screen.slug}`)
      .value;

    let isCorrect = "";
    if (
      this.state.screen.solution.toLowerCase().includes(answer.toLowerCase())
    ) {
      isCorrect = true;
    } else {
      isCorrect = false;
    }

    if (isCorrect) {
      recordAnswer(true, this.props.screen, answer, (savedSC) => {
        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: answer,
        });
      });
    } else {
      recordAnswer(false, this.props.screen, answer, (savedSC) => {
        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: answer,
        });
      });
    }
  }

  render() {
    const isSlides = this.props.isSlides;

    const congrats = this.state.isCorrect ? (
      <span className="text-success">Correct! </span>
    ) : (
      <span className="text-danger">Sorry, that's wrong. </span>
    );
    const result = (
      <>
        <div className="w-100 my-4">
          <p>
            <strong>{congrats}</strong> The correct answer is:
          </p>
          <code>{this.props.screen.solution}</code>
        </div>
        {this.props.screen.explanation && (
          <div className="w-100 mb-4">
            <h4>Explanation:</h4>
            <ReactMarkdown source={this.props.screen.explanation} />
          </div>
        )}
      </>
    );

    return (
      <div
        className={`screen ${
          isSlides ? "py-5 px-4" : "p-4"
        } border-0 h-100 overflow-auto`}
      >
        <p>
          <strong>Write the missing line below.</strong>
        </p>
        <input
          className="form-control form-control-lg mb-4"
          id={`answer-${this.state.screen.slug}`}
          type="text"
          placeholder="Write missing line"
          value={
            this.state.screen?.screenCompletion?.answer || this.state.userAnswer
          }
          onChange={(e) => this.setState({ userAnswer: e.target.value })}
        />

        {this.state.revealAnswer ? null : (
          <div className="row">
            <div className="col-lg-6 mb-2">
              <button
                className="genric-btn info large w-100"
                onClick={this.checkAnswer}
                style={{ cursor: "pointer" }}
              >
                Submit
              </button>
            </div>
            <div className="col-lg-6 mb-2">
              <button
                className="genric-btn info large w-100"
                onClick={() => {
                  if (!this.props.user) {
                    return toastNotSignedIn("track work on this exercise");
                  }

                  recordAnswer(false, this.props.screen, "N/A", (savedSC) => {
                    this.setState({
                      screen: {
                        ...this.props.screen,
                        screenCompletion: savedSC,
                      },
                      revealAnswer: true,
                      isCorrect: false,
                      userAnswer: "N/A",
                    });
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                Reveal answer
              </button>
            </div>
          </div>
        )}
        {this.state.revealAnswer ? result : null}
        {this.state.screen.screenCompletion ? (
          <small>
            Answer saved on{" "}
            {getFormattedDate(
              new Date(this.state.screen.screenCompletion.created_at)
            )}
            .{" "}
            <em
              className="strong cursor-pointer text-ocean-blue"
              onClick={() =>
                deleteScreenCompletion(
                  this.state.screen.screenCompletion.id,
                  () => {
                    const {
                      screenCompletion,
                      ...screenWithoutSC
                    } = this.state.screen;
                    return this.setState({
                      screen: screenWithoutSC,
                      revealAnswer: false,
                      userAnswer: null,
                      isCorrect: null,
                    });
                  }
                )
              }
            >
              Delete answer and reset?
            </em>
          </small>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(FillIn);
