import React, { Component } from "react";
import { deleteScreenCompletion, recordAnswer } from "./helpers/completions";
import { getFormattedDate, toastNotSignedIn } from "./helpers/utils";

import MathJax from "react-mathjax";
import ReactMarkdown from "react-markdown";
import RemarkMathPlugin from "remark-math";
import { connect } from "react-redux";

class MultipleChoice extends Component {
  constructor(props, context) {
    super(props, context);

    const screen = this.props.screen;

    this.state = {
      screen: screen,
      revealAnswer: screen.screenCompletion,
      userAnswer: screen.screenCompletion
        ? parseInt(screen.screenCompletion.answer)
        : null,
      isCorrect: screen.screenCompletion && screen.screenCompletion.is_correct,
    };

    this.checkAnswer = this.checkAnswer.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.screen !== this.props.screen) {
      const screen = this.props.screen;
      this.setState({
        screen: screen,
        revealAnswer: screen.screenCompletion,
        userAnswer: screen.screenCompletion
          ? parseInt(screen.screenCompletion.answer)
          : null,
        isCorrect:
          screen.screenCompletion && screen.screenCompletion.is_correct,
      });
    }
  }

  checkAnswer(inputVal) {
    if (this.state.revealAnswer) {
      return;
    }

    // June 3rd, 2023: We're not going to require users to be
    // signed in to track their progress as a test.
    // if (!this.props.user) {
    //   return toastNotSignedIn("track work on this exercise");
    // }

    const answer = parseInt(inputVal);
    let isCorrect = "";

    if (this.state.screen.solution == answer) {
      isCorrect = true;
    } else {
      isCorrect = false;
    }

    if (this.props.user) {
      recordAnswer(isCorrect, this.props.screen, answer, (savedSC) => {
        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: answer,
        });
      });
    } else {
      // if user is not signed in, just generate savedSC and
      // show the answer
      this.setState({
        screen: {
          ...this.props.screen,
          screenCompletion: {
            screen_id: this.props.screen.id,
            answer: answer,
            points: 0,
            is_correct: isCorrect,
            lesson_id: this.props.screen.lesson_id,
            challenge_id: this.props.screen.challenge_id,
            created_at: new Date(),
            updated_at: new Date(),
          },
        },
        revealAnswer: true,
        isCorrect: isCorrect,
        userAnswer: answer,
      });
    }
  }

  render() {
    const screen = this.state.screen;
    const isSlides = this.props.isSlides;

    return (
      <div
        className={`screen ${
          isSlides ? "py-5 px-4" : "p-4"
        } border-0 h-100 overflow-auto`}
      >
        <h4>Click the option that best answers the question.</h4>
        <ul className="list-group">
          {screen.options
            ? screen.options.map((o, idx) => {
                return (
                  <li
                    key={o.toString().substr(0, 10)}
                    onClick={() => {
                      if (!this.state.userAnswer) {
                        this.checkAnswer(idx);
                      }
                    }}
                    className={`list-group-item shadow ${
                      parseInt(this.state.userAnswer) == idx
                        ? "bg-dark"
                        : "bg-info"
                    } mb-3 text-white ${
                      this.state.revealAnswer && screen.solution == idx
                        ? "correct"
                        : ""
                    } ${
                      parseInt(this.state.userAnswer) == idx
                        ? "border border-dark shadow-lg"
                        : ""
                    }`}
                  >
                    {o}{" "}
                    {this.state.revealAnswer && screen.solution == idx ? (
                      <p className="p-0 m-0">
                        <i className="fa fa-check" />{" "}
                        <small>This is the correct answer.</small>
                      </p>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })
            : null}
          {this.state.revealAnswer && (
            <div className="w-100 mb-4">
              {this.state.isCorrect && (
                <strong>
                  <span className="text-success">Correct! </span>
                </strong>
              )}
              {!this.state.isCorrect && (
                <strong>
                  <span className="text-danger">Sorry, that's wrong. </span>
                </strong>
              )}
            </div>
          )}
          {this.state.revealAnswer && screen.explanation && (
            <>
              <div className="w-100 mb-4">
                <h4>Explanation:</h4>
                <MathJax.Provider input="tex">
                  <ReactMarkdown
                    plugins={[RemarkMathPlugin]}
                    source={screen.explanation}
                    renderers={{
                      math: (props) => <MathJax.Node formula={props.value} />,
                      inlineMath: (props) => (
                        <MathJax.Node inline formula={props.value} />
                      ),
                    }}
                  />
                </MathJax.Provider>
              </div>
            </>
          )}
          {screen.screenCompletion ? (
            <small>
              Answer saved on{" "}
              {getFormattedDate(new Date(screen.screenCompletion.created_at))}.{" "}
              <em
                className="strong cursor-pointer text-ocean-blue"
                onClick={() => {
                  if (this.props.user) {
                    deleteScreenCompletion(screen.screenCompletion.id, () => {
                      const { screenCompletion, ...screenWithoutSC } = screen;
                      return this.setState({
                        screen: screenWithoutSC,
                        revealAnswer: false,
                        userAnswer: null,
                        isCorrect: null,
                      });
                    });
                  } else {
                    return toastNotSignedIn(
                      "record, track, and delete this answer"
                    );
                  }
                }}
              >
                Delete answer and reset?
              </em>
            </small>
          ) : null}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(MultipleChoice);
