import React from "react";

export default function Badges(props) {
  const { obj, sectionComplete = false } = props;

  return (
    <>
      {/* for courses */}
      {obj.challenges_count ? (
        <button className="btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text">
          <span className="small">Challenges</span>{" "}
          <span className="badge badge-secondary">{obj.challenges_count}</span>
        </button>
      ) : obj.challenges && obj.challenges.length ? (
        <button
          className={`btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text ${
            sectionComplete ? "text-secondary" : ""
          }`}
        >
          <span className="small">Challenges</span>{" "}
          <span className="badge badge-secondary">{obj.challenges.length}</span>
        </button>
      ) : null}

      {obj.lessons_count ? (
        <button className="btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text">
          <span className="small">Lessons</span>{" "}
          <span className="badge badge-secondary">{obj.lessons_count}</span>
        </button>
      ) : obj.lessons && obj.lessons.length ? (
        <button
          className={`btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text ${
            sectionComplete ? "text-muted" : ""
          }`}
        >
          <span className="small">Lessons</span>{" "}
          <span className="badge badge-secondary">{obj.lessons.length}</span>
        </button>
      ) : null}
      {obj.visuals_count ? (
        <button className="btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text">
          <span className="small">Visuals</span>{" "}
          <span className="badge badge-secondary">{obj.visuals_count}</span>
        </button>
      ) : null}
      {obj.code_snippets_count ? (
        <button className="btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text">
          <span className="small">Code Snippets</span>{" "}
          <span className="badge badge-secondary">
            {obj.code_snippets_count}
          </span>
        </button>
      ) : null}
      {obj.videos_count ? (
        <button className="btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text">
          <span className="small">Videos</span>{" "}
          <span className="badge badge-secondary">{obj.videos_count}</span>
        </button>
      ) : null}
      {obj.free_tutorials ? (
        <button className="btn-sm btn border bg-white px-2 small info mb-2 mr-2 cursor-text">
          <span className="small">Free Tutorials</span>{" "}
          <span className="badge badge-secondary">{obj.free_tutorials}</span>
        </button>
      ) : null}
    </>
  );
}
