import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "./redux/StateReducer";
import { adjustForNav } from "./helpers/utils";

const AppNav = (props) => {
  const dispatch = useDispatch();
  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );
  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);

  const { leftLink, leftTitle, completed, title } = props;
  const [searchVal, setSearchVal] = useState("");
  const [showTutorialMenuBtn, setShowTutorialMenuBtn] = useState(true);

  const currLocation = useLocation();
  const pathName = currLocation.pathname;

  React.useEffect(() => {
    setShowTutorialMenuBtn(
      !(
        pathName.includes("/courses/") ||
        pathName.includes("/sections/") ||
        pathName.includes("_slides/") ||
        pathName.includes("/dashboard")
      )
    );
    adjustForNav();
  });

  const showToolsAndCompany =
    pathName.includes("/courses/") ||
    pathName.includes("/dashboard") ||
    pathName.includes("/activity-log");

  return (
    <nav
      id="algodaily-nav"
      className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top shadow"
    >
      {!pathName.includes("_slides/") ? (
        <div className="d-none d-lg-block nav-item text-nowrap mr-4">
          <i
            className="fa fa-bars cursor-pointer text-white"
            onClick={(e) => {
              const newCourseCurriculumOpen = !courseCurriculumOpen;
              dispatch({
                type: actions.SET_COURSE_CURRICULUM_OPEN,
                payload: newCourseCurriculumOpen,
              });
              localStorage &&
                localStorage.setItem(
                  "adCourseCurriculumOpen",
                  newCourseCurriculumOpen
                );
            }}
            data-tip="Toggle the left side curriculum menu"
          ></i>
        </div>
      ) : null}

      {leftTitle && leftLink ? (
        <Link className="navbar-brand" to={leftLink}>
          <i className="fa fa-caret-left"></i> {leftTitle}
        </Link>
      ) : !user || pathName.includes("/dashboard") ? (
        <a className="navbar-brand" href={user ? "/curriculum" : "/"}>
          <i className="fa fa-caret-left"></i>{" "}
          {user ? "Curriculum" : "Start AlgoDaily For Free"}
        </a>
      ) : (
        <Link className="navbar-brand" to={"/dashboard"}>
          <i className="fa fa-caret-left"></i> Dashboard
        </Link>
      )}

      {!showToolsAndCompany ? (
        <ul
          className="d-none d-lg-block navbar-nav m-lg-auto d-lg-flex"
          style={{ flexGrow: 1 }}
        >
          <li className="nav-brand text-nowrap m-lg-auto">
            <h1 className="nav-link text-white p-0 m-0">
              {completed ? (
                <>
                  <s>
                    {title.length > 60 ? title.substr(0, 60) + "..." : title}
                  </s>{" "}
                  <i className="fa fa-check"></i>
                </>
              ) : title.length > 60 ? (
                title.substr(0, 60) + "..."
              ) : (
                title
              )}
            </h1>
          </li>
        </ul>
      ) : null}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className="collapse navbar-collapse"
        id="navbar"
        style={{ flexGrow: showToolsAndCompany ? 1 : 0 }}
      >
        <ul className={`navbar-nav ${showToolsAndCompany ? "ml-auto" : ""}`}>
          {!user ? (
            <li className="d-lg-none nav-item pr-1 text-nowrap text-white">
              <a className="nav-link text-white" href="/">
                Start Learning For Free
              </a>
            </li>
          ) : null}
          {showToolsAndCompany ? (
            <>
              <li className="nav-item pr-1 my-lg-1 dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="aboutDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Company
                </a>
                <div className="dropdown-menu" aria-labelledby="aboutDropdown">
                  <a
                    href={user ? "/learn_about_us" : "/#features"}
                    className="dropdown-item"
                  >
                    {user ? "About Us / FAQ" : "Learn About Us"}
                  </a>
                  <a href="/sample_newsletter" className="dropdown-item">
                    Sample Newsletter
                  </a>
                  <a href="/blog" className="dropdown-item">
                    AlgoDaily Blog
                  </a>
                  <a
                    href="https://www.youtube.com/c/teamalgodaily"
                    className="dropdown-item"
                    target="_blank"
                  >
                    Youtube Channel
                  </a>
                  <a href="/contact_us" className="dropdown-item">
                    Contact Us
                  </a>
                  <a href="/privacy" className="dropdown-item">
                    Privacy Policy
                  </a>
                  <a href="/terms" className="dropdown-item">
                    Terms and Conditions
                  </a>
                </div>
              </li>
            </>
          ) : null}

          {user?.paid_active ? (
            <li className="nav-item pr-1 my-1 dropdown text-nowrap">
              <a className="nav-link" href="/forum">
                Community
              </a>
            </li>
          ) : null}

          {!(user && user.paid_active) ? (
            <button
              className="genric-btn info my-lg-1 m-0 border-0 font-weight-bold mr-2"
              onClick={() => window.location.replace("/subscriptions/new")}
            >
              Premium 50% Off
            </button>
          ) : null}
          {!user ? (
            <>
              <li className="nav-item">
                <a className="nav-link my-lg-1" href="/users/sign_in">
                  Login
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link my-lg-1" href="/users/sign_up">
                  Sign Up
                </a>
              </li>
            </>
          ) : null}

          <li
            id="toolsDropdownLi"
            className="nav-item pr-1 my-1 dropdown text-nowrap"
          >
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="toolsDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Resources
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="toolsDropdown"
            >
              <Link className="dropdown-item" to="/activity-log">
                My Activity Log
              </Link>
              <a className="dropdown-item" href="/videos">
                AlgoDaily Video Library
              </a>
              <a className="dropdown-item" href="/companies">
                Company Interview Reports
              </a>
              <a className="dropdown-item" href="/challenges/daily">
                All Interview Challenges
              </a>
              <a className="dropdown-item" href="/flash-cards">
                Tech Interview Flash Cards
              </a>
              <Link className="dropdown-item" to="/courses/generate">
                Generate a Custom Course
              </Link>
              <a
                className="dropdown-item"
                href={
                  user &&
                  user.paid_active &&
                  ["lifetime", "annual"].includes(user.membership)
                    ? "/users/edit"
                    : "https://algodaily.gumroad.com/l/yYEqr"
                }
                target="_blank"
              >
                AlgoDaily Book: Core Essentials
              </a>
              <a className="dropdown-item" href="/latest">
                Latest Additions
              </a>
              {user ? (
                <div
                  style={{
                    display: "block",
                    width: "100%",
                    padding: "0.25rem 1.5rem",
                    clear: "both",
                    fontWeight: "400",
                    color: "#212529",
                    textAlign: "inherit",
                    whiteSpace: "nowrap",
                    backgroundColor: "transparent",
                    border: "0",
                  }}
                >
                  <form
                    className="form-inline"
                    id="global-search-form"
                    onSubmit={(event) => {
                      event.preventDefault();
                      window.location.href =
                        "/global_search?query=" + searchVal; //whatever you want
                    }}
                  >
                    <input
                      className="form-control w-100"
                      type="text"
                      placeholder="Search"
                      id="global-search-input"
                      onChange={(e) => {
                        setSearchVal(e.target.value);
                      }}
                    />
                  </form>
                </div>
              ) : null}
            </div>
          </li>
        </ul>

        {showTutorialMenuBtn && (
          <i
            className="d-none d-lg-block fa fa-tasks cursor-pointer text-white mx-2 p-2 border border-secondary"
            onClick={() =>
              dispatch({
                type: actions.SET_SCREEN_LIST_OPEN,
                payload: !screenListOpen,
              })
            }
            data-tip="Toggle the right hand task menu"
            data-place="left"
          />
        )}
      </div>
    </nav>
  );
};

export default AppNav;
