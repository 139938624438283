import "./CourseCurriculum.css";

import { Link, useHistory, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { isMobileDevice, titleize } from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import Ad from "./Ad";
import CrashCourseNav from "./CrashCourseNav";
import { actions } from "./redux/StateReducer";
import { isSectionComplete } from "./helpers/completions";
import { useParams } from "react-router-dom";

const COURSE_KIND_ORDERED = [
  "essentials",
  "specialties",
  "nontechnical",
  "personalized",
];

function getIcon(kind) {
  const iconMap = {
    essentials: "book",
    specialties: "tools",
    nontechnical: "user-tie",
    personalized: "user-shield",
    guided: "graduation-cap",
    // menuView icons
    courses: "book",
    crashCourse: "graduation-cap",
    dashboard: "user-tie",
    activityLog: "history",
  };

  const val = iconMap[kind];

  return <i class={`fas fa-${val} ml-1`}></i>;
}

const CourseCurriculum = (props) => {
  const dispatch = useDispatch();

  const activeSections = useSelector((state) => state.activeSections);
  const course = useSelector((state) => state.course);
  const courses = useSelector((state) => state.courses);
  const courseMode = useSelector((state) => state.courseMode);
  const crashCourse = useSelector((state) => state.crashCourse);
  const user = useSelector((state) => state.user);

  const [menuView, setMenuView] = useState("courses");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMiniCourse, setIsMiniCourse] = useState(true);

  const { tutorialSlug } = useParams();
  const history = useHistory();
  const location = useLocation();

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  function adjustCurrWidth() {
    const containerWidth =
      document.getElementById("curriculum-container").clientWidth.toString() +
      "px";

    document.getElementById("curriculum-list").style.width = containerWidth;

    if (document.getElementById("curriculum-tabs")) {
      document.getElementById("curriculum-tabs").style.width = containerWidth;

      if (!isMobileDevice()) {
        document.getElementById("curriculum-list").style.marginTop =
          document.getElementById("curriculum-tabs").clientHeight.toString() +
          "px";
      }
    }
  }

  React.useEffect(() => {
    if (document.getElementById("curriculum-container")) {
      adjustCurrWidth();
    }
  });

  React.useEffect(() => {
    if (!(courses.length && courses.length > 1)) {
      try {
        fetch("/api/courses")
          .then((res) => {
            // Stream response object stream for status
            if (!res.ok) {
              throw res;
            }
            return res.json();
          })
          .then((arr) => {
            dispatch({
              type: actions.SET_COURSES,
              payload: [...arr],
            });
          });
      } catch (e) {
        console.log(e);
      }
    }

    window.addEventListener("resize", adjustCurrWidth);
  }, []);

  React.useEffect(() => {
    if (isMobileDevice()) {
      setMobileMenuOpen(false);
    }

    // State machine starts here, determine menuView
    let menuView = "courses";

    let query = useQuery();
    const menuViewParam = query.get("menuview");

    if (menuViewParam) {
      menuView = menuViewParam;
    } else if (location.pathname.includes("courses")) {
      // fix: should be parity w/ course landing page
      dispatch({
        type: actions.SET_COURSE,
        payload: course,
      });

      dispatch({
        type: actions.SET_COURSE_MODE,
        payload: "menu",
      });
    } else if (location.pathname.includes("sections")) {
      menuView = "sections";

      dispatch({
        type: actions.SET_COURSE_MODE,
        payload: "menu",
      });
    } else if (
      location.pathname &&
      location.pathname.includes("crash-course")
    ) {
      dispatch({
        type: actions.SET_COURSE_MODE,
        payload: "crashcourse",
      });
    } else if (
      location.pathname.includes("challenges") ||
      location.pathname.includes("lessons")
    ) {
      menuView = "tutorials";
    }

    setMenuView(menuView);
  }, [location.pathname]);

  React.useEffect(() => {
    // State machine switch here, determine workflow
    if (menuView !== "courses") {
      // TODO: source of truth shouldn't be sections passed by props
      // or should they? - Need a better place to filter by course
      // Maybe we need to put course_id on tutorials
      if (activeSections && activeSections.length) {
        // The notion of activeSections is affecting the sanity of this
        // logic - only one section should be active at any time
        try {
          let section = activeSections[0];

          if (section) {
            const courseFromCache = courses.find(
              (c) => section.course_id === c.id
            );

            if (
              courseFromCache &&
              courseFromCache.sections &&
              courseFromCache.sections[0] &&
              (courseFromCache.sections[0].lessons ||
                courseFromCache.sections[0].challenges)
            ) {
              dispatch({ type: actions.SET_COURSE, payload: courseFromCache });
              setIsMiniCourse(courseFromCache.mini_course);
              return;
            } else if (!course || course.id != section.course_id) {
              fetch(`/api/courses/${section.course_id}`)
                .then((res) => {
                  // Stream response object stream for status
                  if (!res.ok) {
                    throw res;
                  }
                  return res.json();
                })
                .then((obj) => {
                  if (obj.locked && obj.redirect) {
                    return window.location.replace(obj.redirect);
                  }

                  setIsMiniCourse(obj.mini_course);
                  dispatch({ type: actions.SET_COURSE, payload: obj });
                });
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    }

    if (!user) {
      window.addEventListener("load", function () {
        var ezstandalone = window.ezstandalone || {};
        ezstandalone.cmd = ezstandalone.cmd || [];
        ezstandalone.cmd.push(function () {
          ezstandalone.displayMore(131);
        });
      });
    }
  }, [location.pathname, tutorialSlug, activeSections, menuView, course]);

  function getTitle(menuView, mode, course) {
    const icon = <> {getIcon(menuView)}</>;

    if (mode === "crashcourse") {
      return <span>60 Day Crash Course {icon}</span>;
    } else {
      if (menuView === "courses") {
        return <a href="/curriculum">All AlgoDaily Courses {icon}</a>;
      } else if (menuView === "sections") {
        return (
          <Link to={`/courses/${course.slug}`}>
            {course.title} {icon}
          </Link>
        );
      } else if (menuView === "tutorials") {
        return (
          <Link to={`/courses/${course.slug}`}>
            {course.title} {icon}
          </Link>
        );
      }
    }
  }

  const coursesByKind = courses.reduce((prev, curr) => {
    if (!prev[curr.kind]) {
      prev[curr.kind] = [];
    }
    prev[curr.kind].push(curr);
    return prev;
  }, {});

  return (
    <>
      {isMobileDevice() ? (
        <div id="curriculum-mobile-container" className="w-100 d-lg-block p-0">
          <div
            className="border-0 p-2 bg-light"
            onClick={() => {
              document.getElementById("curriculum-list").style.width = "100%";

              setMobileMenuOpen(!mobileMenuOpen);
            }}
          >
            <button className="genric-btn w-100 info large my-2 mx-auto d-flex align-items-center shadow">
              {mobileMenuOpen ? (
                <>
                  <i className="fa fa-caret-down pr-2"></i>Close Course Menu
                </>
              ) : (
                <>
                  <i className="fa fa-caret-right pr-2"></i>Open Course Menu
                </>
              )}
            </button>
          </div>
        </div>
      ) : null}
      <div
        id="curriculum-container"
        className={`${
          mobileMenuOpen ? "" : "d-none"
        } col-lg-3 col-sm-12 d-lg-block shadow`}
        style={{
          position: "relative",
          zIndex: 10,
        }}
      >
        <div
          id="curriculum-tabs"
          className={
            isMobileDevice() ? "position-relative w-100" : "position-fixed"
          }
        >
          <div className="px-3 py-2 d-flex justify-content-between align-items-center border-bottom">
            <div
              className="d-flex align-items-center"
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <span className="cursor-pointer mr-1">
                {getTitle(menuView, courseMode, course) || "Loading..."}
              </span>
              {!(user && user.paid_active) &&
              menuView !== "courses" &&
              course &&
              !course.locked ? (
                <img
                  src="https://storage.googleapis.com/algodailyrandomassets/marketing/free.png"
                  style={{
                    width: "1.3rem",
                    height: "1.3rem",
                  }}
                />
              ) : null}
            </div>
          </div>

          {courseMode === "crashcourse" && (
            <div
              className="px-3 py-2 border-bottom border-radius-0 font-weight-bold cursor-pointer"
              onClick={() => {
                dispatch({
                  type: actions.SET_COURSE_MODE,
                  payload: "menu",
                });
                setMenuView("courses");
              }}
              style={{
                borderRadius: "0",
              }}
            >
              <i className="fa fa-caret-left"></i>
              <i className="fa fa-caret-left mr-2"></i>{" "}
              <span style={{ fontSize: "1.05rem", fontWeight: 400 }}>
                Back to all AlgoDaily courses
              </span>
            </div>
          )}
          {courseMode !== "crashcourse" && menuView === "tutorials" && (
            <div
              className="px-3 py-2 border-bottom font-weight-bold cursor-pointer"
              onClick={() => {
                setMenuView("sections");
              }}
            >
              <i className="fa fa-caret-left"></i>
              <i className="fa fa-caret-left mr-2"></i>{" "}
              <span style={{ fontSize: "1.05rem", fontWeight: 400 }}>
                Back to course sections
              </span>
            </div>
          )}
          {menuView === "sections" && (
            <div
              className="px-3 py-2 border-bottom font-weight-bold cursor-pointer"
              onClick={() => {
                setMenuView("courses");
              }}
            >
              <i className="fa fa-caret-left"></i>
              <i className="fa fa-caret-left mr-2"></i>{" "}
              <span style={{ fontSize: "1.05rem", fontWeight: 400 }}>
                Back to all AlgoDaily courses
              </span>
            </div>
          )}
        </div>
        <div
          id="curriculum-list"
          className={`p-0 small col-lg-3 h-100 overflow-auto ${
            mobileMenuOpen ? "" : "position-fixed pb-2 pb-lg-5"
          }`}
          style={{ overflow: "scroll" }}
        >
          <>
            {courseMode === "crashcourse" ? (
              <div className="p-3">
                <p>
                  This 8-week crash course is a simplified version of the full
                  curriculum, in the right order, to go from beginner to
                  interview-ready. The dates attached are for participation with
                  our ongoing cohorts. You can opt to ignore the dates if you're
                  going at your own pace.{" "}
                  <a
                    href="https://algodaily.com/blog/last-call-to-join-60-day-crash-course-faq"
                    target="_blank"
                  >
                    Learn more here
                  </a>
                  .
                </p>
                {user && user.paid_active ? (
                  <CrashCourseNav crashCourse={crashCourse} />
                ) : (
                  <p>
                    <strong>
                      This crash course menu is currently only available for
                      premium members.{" "}
                      <a href="/subscriptions/discounted">
                        Enroll and get the full package today
                      </a>
                      .
                    </strong>
                  </p>
                )}
              </div>
            ) : (
              <>
                <ul className="list-group list-group-root d-block pb-2 pb-lg-5 mb-2">
                  {menuView === "courses" &&
                    COURSE_KIND_ORDERED.map((kind) => {
                      return (
                        <>
                          <li
                            key={kind}
                            className="list-group-item list-group-item-action px-3 font-weight-bold text-secondary border-0"
                          >
                            {titleize(kind)} {getIcon(kind)}
                          </li>
                          {coursesByKind[kind] && coursesByKind[kind].length
                            ? coursesByKind[kind]
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((c) => (
                                  <li
                                    key={c.slug}
                                    className={`${
                                      !location.pathname.endsWith(
                                        `courses/generate`
                                      ) &&
                                      (location.pathname.endsWith(
                                        `courses/${c.id}`
                                      ) ||
                                        location.pathname.endsWith(
                                          `courses/${c.slug}`
                                        ) ||
                                        (course && course.id === c.id))
                                        ? "active"
                                        : ""
                                    } list-group-item list-group-item-action px-3 font-weight-bold cursor-pointer border-0`}
                                    onClick={() => {
                                      localStorage &&
                                        localStorage.setItem(
                                          "adLeftRail",
                                          "menu"
                                        );
                                      dispatch({
                                        type: actions.SET_COURSE_MODE,
                                        payload: "menu",
                                      });

                                      history.push(`/courses/${c.slug}`);
                                    }}
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    <i
                                      className={`far ${
                                        c.completed
                                          ? "fa-check-circle"
                                          : "fa-circle"
                                      } pr-2`}
                                    ></i>{" "}
                                    <Link
                                      to={`/courses/${c.slug}`}
                                      style={{
                                        color: c.completed
                                          ? "lightgray"
                                          : !location.pathname.endsWith(
                                              `courses/generate`
                                            ) &&
                                            course &&
                                            course.id === c.id
                                          ? "#fff" // active
                                          : "#495057", // dark
                                      }}
                                    >
                                      {c.title}
                                    </Link>
                                  </li>
                                ))
                            : null}
                          {kind === "personalized" && (
                            <li
                              className={`list-group-item list-group-item-action px-3 font-weight-bold cursor-pointer border-0 ${
                                location.pathname.endsWith(`courses/generate`)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                history.push(`/courses/generate`);
                              }}
                            >
                              <i className="far fa-circle pr-2"></i>{" "}
                              <Link
                                to="/courses/generate"
                                style={{
                                  color: location.pathname.endsWith(
                                    `courses/generate`
                                  )
                                    ? "#fff"
                                    : "#495057",
                                }}
                              >
                                Generate a Custom Course 🪄
                              </Link>
                            </li>
                          )}
                        </>
                      );
                    })}

                  {menuView === "courses" && courses.length ? (
                    <>
                      <li
                        key="crashcourse"
                        className="list-group-item list-group-item-action px-3 font-weight-bold text-secondary border-0"
                      >
                        Guided {getIcon("guided")}
                      </li>
                      <li
                        className="list-group-item list-group-item-action px-3 font-weight-bold cursor-pointer border-0"
                        onClick={() => {
                          localStorage &&
                            localStorage.setItem("adLeftRail", "crashcourse");
                          dispatch({
                            type: actions.SET_COURSE_MODE,
                            payload: "crashcourse",
                          });
                        }}
                      >
                        <i className="far fa-circle pr-2"></i> 60 Day Interview
                        Crash Course
                      </li>
                    </>
                  ) : null}
                  {menuView !== "courses"
                    ? (menuView === "tutorials"
                        ? course.sections.filter((s) =>
                            activeSections.map((s) => s.id).includes(s?.id)
                          )
                        : course.sections || []
                      ) // this needs to be wrapped or react tries to render course.sections as a DOM element
                        .map((section, i) => {
                          const completed = isSectionComplete(section);

                          return (
                            <>
                              <li
                                key={section.slug}
                                className={`${
                                  !isMiniCourse &&
                                  menuView === "sections" &&
                                  activeSections.some(
                                    (s) => s.id === section.id
                                  )
                                    ? "active"
                                    : ""
                                } list-group-item list-group-item-action font-weight-bold cursor-pointer border-0 px-3`}
                                onClick={() => {
                                  history.push(`/sections/${section.slug}`);
                                }}
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                                title={section.title}
                              >
                                <i
                                  className={`far ${
                                    completed ? "fa-check-circle" : "fa-circle"
                                  } pr-2`}
                                ></i>{" "}
                                <Link
                                  to={`/sections/${section.slug}`}
                                  style={{
                                    color:
                                      !isMiniCourse &&
                                      menuView === "sections" &&
                                      activeSections.some(
                                        (s) => s.id === section.id
                                      )
                                        ? "#fff" // active
                                        : completed // non-active
                                        ? "lightgray"
                                        : "#495057",
                                  }}
                                >
                                  {section.title}
                                </Link>
                              </li>
                              {menuView === "tutorials" ||
                              (menuView === "sections" && isMiniCourse) ? (
                                <ul className="list-group list-group-flush">
                                  {section.lessons &&
                                    section.lessons
                                      .filter((l) => l.published)
                                      .map((lesson) => (
                                        <Link
                                          to={`/lessons/${lesson.slug}`}
                                          className={`${
                                            lesson.slug === tutorialSlug
                                              ? "active"
                                              : ""
                                          } list-group-item list-group-item-action font-weight-light border-0`}
                                          style={
                                            lesson.completed
                                              ? {
                                                  color: "lightgray",
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }
                                              : {
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                  textOverflow: "ellipsis",
                                                }
                                          }
                                          title={lesson.title}
                                        >
                                          {" "}
                                          {lesson.locked &&
                                          !(user && user.paid_active) ? (
                                            <i className="fa fa-lock my-auto pr-2">
                                              {" "}
                                            </i>
                                          ) : (
                                            <i
                                              className={`far ${
                                                lesson.completed
                                                  ? "fa-check-circle"
                                                  : "fa-circle"
                                              } pr-2`}
                                            ></i>
                                          )}{" "}
                                          {lesson.title.length > 75
                                            ? lesson.title.slice(0, 75) + "..."
                                            : lesson.title}
                                          {lesson.video ? (
                                            <i
                                              className="fa fa-video my-auto"
                                              style={{
                                                marginLeft: "1em",
                                                fontSize: "0.8em",
                                                fontSize: "10px",
                                              }}
                                            >
                                              {" "}
                                            </i>
                                          ) : null}
                                        </Link>
                                      ))}
                                </ul>
                              ) : null}
                              {menuView === "tutorials" ||
                              (menuView === "sections" && isMiniCourse) ? (
                                <ul className="list-group list-group-flush">
                                  {section.challenges &&
                                    section.challenges.map((challenge) => (
                                      <Link
                                        to={`/challenges/${challenge.slug}`}
                                        className={`list-group-item list-group-item-action font-weight-light ${
                                          challenge.slug === tutorialSlug
                                            ? "active"
                                            : ""
                                        } border-0`}
                                        style={
                                          challenge.completed
                                            ? {
                                                color: "lightgray",
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                              }
                                            : {
                                                overflow: "hidden",
                                                whiteSpace: "nowrap",
                                                textOverflow: "ellipsis",
                                              }
                                        }
                                        title={challenge.title}
                                      >
                                        {challenge.locked &&
                                        !(user && user.paid_active) ? (
                                          <i className="fa fa-lock my-auto pr-2"></i>
                                        ) : (
                                          <i
                                            className={`far ${
                                              challenge.completed
                                                ? "fa-check-circle"
                                                : "fa-circle"
                                            } pr-2`}
                                          ></i>
                                        )}{" "}
                                        {challenge.title.length > 75
                                          ? challenge.title.slice(0, 75) + "..."
                                          : challenge.title}
                                        {challenge.video ? (
                                          <i
                                            className="fa fa-video my-auto"
                                            style={{
                                              marginLeft: "1em",
                                              fontSize: "0.8em",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {" "}
                                          </i>
                                        ) : null}
                                      </Link>
                                    ))}
                                </ul>
                              ) : null}
                            </>
                          );
                        })
                    : null}
                </ul>
              </>
            )}
            {!(user && user.paid_active) && (
              <Ad kind="narrow" />
              // <div id="ezoic-pub-ad-placeholder-131" className="w-100"></div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default CourseCurriculum;
