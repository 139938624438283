import JobApplicationsMenu from "./JobApplicationsMenu";
import NotesMenu from "./NotesMenu";
import React from "react";

const NonTutorialMenu = () => {
  return (
    <nav id="tutorial-menu-pillar" className="bg-light col-lg-2 p-0">
      <div
        id="tutorial-nav-container"
        className="m-0 p-0 position-fixed overflow-auto bg-light"
      >
        <NotesMenu />
        {/* <JobApplicationsMenu /> */}
      </div>
    </nav>
  );
};

export default NonTutorialMenu;
