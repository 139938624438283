import React from "react";
import { isMobileDevice } from "./helpers/utils";

export default function Ad(props) {
  const { kind } = props;

  const NARROW_ADS = [
    "https://storage.googleapis.com/algodailyrandomassets/marketing/square-ad-1.jpg",
    "https://storage.googleapis.com/algodailyrandomassets/marketing/square-ad-2.jpg",
    "https://storage.googleapis.com/algodailyrandomassets/marketing/adbanner300x350v2.jpg",
    "https://storage.googleapis.com/algodailyrandomassets/marketing/adbanner300x350.jpg",
  ];

  const WIDE_ADS = [
    "https://storage.googleapis.com/algodailyrandomassets/marketing/sale-leaderboard-ad-1.png",
    "https://storage.googleapis.com/algodailyrandomassets/marketing/sale-leaderboard-ad-2.png",
  ];

  const adUrl =
    isMobileDevice() || kind === "narrow"
      ? NARROW_ADS[Math.floor(Math.random() * NARROW_ADS.length)]
      : WIDE_ADS[Math.floor(Math.random() * WIDE_ADS.length)];

  return (
    // // show DRIPS.ad every other time
    // Math.floor(Math.random() * 2) === 0 ? (
    //   <a href="https://drips.ai" target="_blank">
    //     <img
    //       src={
    //         isMobileDevice() || kind === "narrow"
    //           ? "https://www.drips.ai/marketing/banner-ad.png"
    //           : "https://www.drips.ai/marketing/leaderboard-ad.png"
    //       }
    //       className="w-100 border mb-2"
    //     />
    //   </a>
    // ) : (
    <a href="/subscriptions/discounted" target="_blank">
      <img src={adUrl} className="w-100 border mb-2" />
    </a>
    // )
  );
}
