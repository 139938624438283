import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actions } from "./redux/StateReducer";
import { titleize } from "./helpers/utils";
import { useHistory } from "react-router-dom";

export default (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const jobApps = useSelector((state) => state.jobApps);
  const user = useSelector((state) => state.user);

  const [createJobAppOpen, setCreateJobAppOpen] = useState(false);
  const [jobsOpen, setJobsOpen] = useState(true);

  React.useEffect(() => {
    if (user && user.paid_active && !jobApps.length) {
      fetch(`/api/job_applications/`)
        .then((res) => {
          return res.json();
        })
        .then(
          (obj) => {
            dispatch({
              type: actions.SET_JOB_APPS,
              payload: obj,
            });
          },
          (error) => {
            console.log(error);
          }
        );

      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      document.getElementById("tutorial-nav-container").style.height =
        (
          vh - document.getElementById("algodaily-nav")?.offsetHeight
        ).toString() + "px";
    }
  }, [user]);

  React.useEffect(() => {
    if (createJobAppOpen) {
      document.getElementById("tutorial-nav-container").scrollTop =
        document.getElementById("tutorial-nav-container").clientHeight + 1000;
    }

    const tutorialNavContainer = document.getElementById(
      "tutorial-nav-container"
    );
    if (tutorialNavContainer) {
      tutorialNavContainer.style.width =
        document.getElementById("tutorial-menu-pillar").clientWidth.toString() +
        "px";
    }
  });

  if (!(user && user.paid_active)) {
    return null;
  }

  return (
    <>
      <div
        id="tutorial-nav-header"
        className="w-100 d-flex justify-content-start align-items-center border-bottom px-4 pt-4 pb-0 cursor-pointer"
        onClick={() => {
          setJobsOpen(!jobsOpen);
        }}
      >
        <i
          className={`fa ${
            jobsOpen ? "fa-angle-double-down" : "fa-angle-double-right"
          } mr-2`}
        />{" "}
        <h3 className="py-2 my-0">Jobs</h3>
      </div>
      {jobsOpen ? (
        <div
          id="tutorial-nav-scroll"
          className="w-100 overflow-auto pb-4 pt-2 px-4"
        >
          {jobApps && jobApps.length ? (
            jobApps.map((ja) => (
              <button
                className="btn bg-white shadow mx-auto my-2 d-flex align-items-center w-100"
                onClick={() => {
                  history.push(`/jobapps/${ja.id}`);
                }}
              >
                <i className="fa fa-file pull-left"></i>
                <span
                  style={{
                    paddingLeft: "15px",
                    paddingLight: "5px",
                    width: "100%",
                    display: "inline-block",
                    textAlign: "left",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {" "}
                  {ja.user_added_company}
                  <br />
                  <small
                    style={{
                      width: "100%",
                      display: "inline-block",
                      textAlign: "left",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {titleize(ja.job_title)}
                  </small>
                </span>
              </button>
            ))
          ) : (
            <p className="small">
              This feature allows you to track job applications to software
              companies. As we build out our companies directory, we will
              recommend you interview questions to prepare for based on our
              research. To start, add a job application below:
            </p>
          )}
          <button
            type="button"
            className="btn btn-default btn-circle btn-lg my-4 mx-auto d-flex align-items-center justify-content-center"
            onClick={(e) => {
              if (!user) {
                return window.open("/users/sign_in");
              }

              if (user && !user.paid_active) {
                return window.open("/subscriptions/new");
              }

              setCreateJobAppOpen(true);
            }}
          >
            <i className="fa fa-plus"></i>
          </button>
          {createJobAppOpen ? (
            <div className="w-100 mb-4">
              <div className="bg-white rounded-lg text-dark p-2 text-center">
                <strong>Company</strong>
                <input
                  id="company-name-input"
                  className="form-control form-control-sm mb-2"
                  type="text"
                  placeholder="Amazon"
                />
                <strong>Job Title</strong>

                <input
                  id="job-title-input"
                  className="form-control form-control-sm mb-4"
                  type="text"
                  placeholder="Software Engineer"
                />
                <button
                  className="btn btn-sm bg-warning mb-4"
                  onClick={(e) => {
                    e.preventDefault();

                    const token = document
                      .querySelector('meta[name="csrf-token"]')
                      .getAttribute("content");

                    try {
                      fetch(`/api/job_applications`, {
                        method: "post",
                        headers: {
                          "Content-Type": "application/json",
                          "X-CSRF-Token": token,
                        },
                        body: JSON.stringify({
                          job_application: {
                            status: "applied",
                            user_id: user.id,
                            user_added_company: $("#company-name-input").val(),
                            job_title: $("#job-title-input").val(),
                          },
                        }),
                      })
                        .then((res) => {
                          if (!res.ok) {
                            throw res;
                          }
                          return res.json();
                        })
                        .then((obj) => {
                          dispatch({
                            type: actions.SET_JOB_APPS,
                            payload: jobApps.concat([obj]),
                          });
                          setCreateJobAppOpen(false);
                        });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
