import React, { Component } from "react";
import { deleteScreenCompletion, recordAnswer } from "./helpers/completions";
import { getFormattedDate, toastNotSignedIn } from "./helpers/utils";

import ReactMarkdown from "react-markdown/with-html";
import { connect } from "react-redux";

function titleizeAndColorize(string) {
  const finalStr = string.charAt(0).toUpperCase() + string.slice(1);
  if (finalStr == "False") {
    return (
      <span>
        {finalStr} <i className="fa fa-times text-danger" />
      </span>
    );
  } else {
    return (
      <span>
        {finalStr} <i className="fa fa-check text-success" />
      </span>
    );
  }
}

class Swipe extends Component {
  constructor(props, context) {
    super(props, context);

    const screen = this.props.screen;

    this.state = {
      screen: screen,
      revealAnswer: screen.screenCompletion,
      userAnswer: screen.screenCompletion
        ? screen.screenCompletion.answer
        : null,
      isCorrect: screen.screenCompletion && screen.screenCompletion.is_correct,
    };
  }

  // Needed for slides
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.screen !== this.props.screen) {
      const screen = this.props.screen;
      this.setState({
        screen: screen,
        revealAnswer: screen.screenCompletion,
        userAnswer: screen.screenCompletion
          ? screen.screenCompletion.answer
          : null,
        isCorrect:
          screen.screenCompletion && screen.screenCompletion.is_correct,
      });
    }
  }

  checkAnswer(inputVal) {
    if (this.state.revealAnswer) {
      return;
    }

    if (!this.props.user) {
      return toastNotSignedIn("track work on this exercise");
    }

    const answer = inputVal.toString();
    let isCorrect = "";
    if (answer == this.props.screen.solution) {
      isCorrect = true;
    } else {
      isCorrect = false;
    }

    if (isCorrect) {
      recordAnswer(true, this.props.screen, answer, (savedSC) => {
        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: answer,
        });
      });
    } else {
      recordAnswer(false, this.props.screen, answer, (savedSC) => {
        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: answer,
        });
      });
    }
  }

  render() {
    const screen = this.state.screen;
    const isSlides = this.props.isSlides;
    const solution = screen.solution;

    return (
      <div
        className={`screen ${
          isSlides ? "py-5 px-4" : "p-4"
        } border-0 h-100 overflow-auto`}
      >
        <p>
          Press true if you believe the statement is correct, or false
          otherwise.
        </p>
        <div className="row">
          <div className="col-lg-6">
            <button
              type="button"
              onClick={() => this.checkAnswer(true)}
              className={`genric-btn info w-100 mb-2 ${
                this.state.userAnswer === "true"
                  ? "bg-dark border border-dark shadow-lg"
                  : ""
              }`}
            >
              TRUE
            </button>
          </div>
          <div className="col-lg-6">
            <button
              type="button"
              onClick={() => this.checkAnswer(false)}
              className={`genric-btn info w-100 mb-2 ${
                this.state.userAnswer === "false"
                  ? "bg-dark border border-dark shadow-lg"
                  : ""
              }`}
            >
              FALSE
            </button>
          </div>
        </div>

        {this.state.revealAnswer ? (
          <>
            <p>
              <strong>
                <span>
                  {this.state.isCorrect && (
                    <span className="text-success">Correct! </span>
                  )}
                  {!this.state.isCorrect && (
                    <span className="text-danger">Sorry, that's wrong. </span>
                  )}{" "}
                  The statement is {titleizeAndColorize(solution.toString())}.
                </span>
              </strong>
            </p>
            {screen.explanation && (
              <div className="w-100 mb-4">
                <h4>Explanation</h4>
                <p>
                  <ReactMarkdown source={screen.explanation} />
                </p>
              </div>
            )}
          </>
        ) : null}
        {screen.screenCompletion ? (
          <small>
            Answer saved on{" "}
            {getFormattedDate(new Date(screen.screenCompletion.created_at))}.{" "}
            <em
              className="strong cursor-pointer text-ocean-blue"
              onClick={() =>
                deleteScreenCompletion(screen.screenCompletion.id, () => {
                  const { screenCompletion, ...screenWithoutSC } = screen;
                  return this.setState({
                    screen: screenWithoutSC,
                    revealAnswer: false,
                    userAnswer: null,
                    isCorrect: null,
                  });
                })
              }
            >
              Delete answer and reset?
            </em>
          </small>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(Swipe);
