import {
  LANGUAGES,
  LANG_TO_BOILERPLATE_MAP,
  LANG_TO_CM_MODE_MAP,
  isMobileDevice,
  loadLanguageUtils,
  parseSSEStream,
  titleize,
  toastNotSignedIn,
} from "./helpers/utils";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { RIEInput, RIESelect } from "riek";
import React, { useState } from "react";

import { UnControlled as CodeMirror } from "react-codemirror2";
import { Helmet } from "react-helmet";

require("codemirror/keymap/sublime");
require("codemirror/addon/comment/comment");
require("codemirror/addon/lint/lint");
require("codemirror/addon/hint/show-hint.css");
require("codemirror/addon/hint/show-hint");

const CodeConverter = () => {
  const history = useHistory();

  let { fromLanguage, toLanguage } = useParams();

  const [inputCode, setInputCode] = useState("");
  const [outputCode, setOutputCode] = useState("");
  const [isConverting, setIsConverting] = useState(false);
  const [model, setModel] = useState("speed");

  if (!LANGUAGES.includes(fromLanguage) || !LANGUAGES.includes(toLanguage)) {
    history.push("/convert/javascript/python");
  }

  React.useEffect(() => {
    loadLanguageUtils(fromLanguage);
    loadLanguageUtils(toLanguage);

    window.scrollTo(0, 0);
  }, [fromLanguage, toLanguage]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const inputParam = urlParams.get("input");
    if (inputParam === null) {
      if (LANG_TO_BOILERPLATE_MAP[fromLanguage]) {
        setInputCode(LANG_TO_BOILERPLATE_MAP[fromLanguage]);
      } else {
        setInputCode("");
      }
    } else {
      setInputCode(atob(inputParam));
    }
  }, []);

  const handleClick = () => {
    if (isConverting) {
      return;
    }

    setIsConverting(true);
    setOutputCode(() => "");

    const token = document.querySelector('meta[name="csrf-token"]').content;
    // fetch
    fetch(`/api/convert/${fromLanguage}/${toLanguage}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({ inputCode, model }),
    })
      .then(async (response) => {
        if (response.status !== 200) {
          toastNotSignedIn("convert code");
          return;
        }

        const reader = response.body.getReader();

        let res = "";

        while (true) {
          const { done, value } = await reader.read();

          console.log(done, value);
          if (done) {
            break;
          }

          // Convert Uint8Array to string
          const s = new TextDecoder().decode(value);
          const text = parseSSEStream(s);
          console.log(text);

          res += text;
          setOutputCode((o) => res);
        }
      })
      // .then((response) => response.json())
      // .then((data) => {
      //   setOutputCode(data.output);
      // })
      .catch((error) => console.error(error))
      .finally(() => setIsConverting(false));
  };

  var options = {
    autoCloseBrackets: true,
    lineNumbers: true,
    theme: "elegant",
    readOnly: false,
    keyMap: "sublime",
    tabSize: 2,
    extraKeys: {
      "Cmd-/": "toggleComment",
      "Ctrl-/": "toggleComment",
      Tab: "defaultTab",
      Tab: (cm) => {
        if (cm.getMode().name === "null") {
          cm.execCommand("insertTab");
        } else {
          if (cm.somethingSelected()) {
            cm.execCommand("indentMore");
          } else {
            cm.execCommand("insertSoftTab");
          }
        }
      },
      "Shift-Tab": (cm) => cm.execCommand("indentLess"),
    },
    hintOptions: { completeSingle: false },
  };

  const convertButton = (
    <button className="btn btn-primary w-100" onClick={handleClick}>
      {isConverting && <i class="fas fa-spinner fa-pulse"></i>} Convert
    </button>
  );

  const converterOpts = LANGUAGES.map((l) => ({
    id: l,
    text: titleize(l),
  }));

  const metaTitle = `Convert ${titleize(fromLanguage)} to ${titleize(
    toLanguage
  )}`;
  const metaDescription = metaTitle;
  const metaLink = `https://algodaily.com/convert/${fromLanguage}/${toLanguage}`;
  const metaImageUrl = `https://algodaily.com/img/socialshare-${parseInt(
    Math.random() * 8
  )}.png`;

  return (
    <>
      <Helmet>
        <title>{metaTitle} - AlgoDaily</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={metaLink} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImageUrl} />
        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@algodaily" />
        <meta name="twitter:creator" content="@algodaily" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImageUrl} /> */}
        <link
          rel="canonical"
          href={`https://algodaily.com/convert/${fromLanguage}/${toLanguage}`}
        />
      </Helmet>
      <div className="converter border bg-white no-gutters rounded-lg p-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h1 className="m-0">
            Convert{" "}
            <span
              className="cursor-pointer font-weight-bold"
              onMouseEnter={(e) => {
                // italicize, make it obvious it's clickable and underlined
                e.target.style.fontStyle = "italic";
                e.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                // unitalicize, make it obvious it's clickable and underlined
                e.target.style.fontStyle = "normal";
                e.target.style.textDecoration = "none";
              }}
            >
              <RIESelect
                change={(data) => {
                  return history.push(
                    `/convert/${data.language.id}/${toLanguage}`
                  );
                }}
                options={converterOpts}
                value={converterOpts.filter((o) => o.id === fromLanguage)[0]}
                propName="language"
                validate={(t) => t.length > 0}
              />
            </span>{" "}
            code to{" "}
            <span
              className="cursor-pointer font-weight-bold"
              onMouseEnter={(e) => {
                // italicize, make it obvious it's clickable and underlined
                e.target.style.fontStyle = "italic";
                e.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                // unitalicize, make it obvious it's clickable and underlined
                e.target.style.fontStyle = "normal";
                e.target.style.textDecoration = "none";
              }}
            >
              <RIESelect
                change={(data) => {
                  return history.push(
                    `/convert/${fromLanguage}/${data.language.id}`
                  );
                }}
                options={converterOpts}
                value={converterOpts.filter((o) => o.id === toLanguage)[0]}
                propName="language"
                validate={(t) => t.length > 0}
              />
            </span>{" "}
          </h1>
          {/* select model dropdown */}
          <select
            className="form-control col-lg-2 col-sm-12"
            onChange={(e) => {
              setModel(e.target.value);
            }}
            value={model}
          >
            <option value="speed">Speed</option>
            <option value="accuracy">Accuracy</option>
          </select>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3>Input code</h3>
            {CodeMirror && (
              <CodeMirror
                value={inputCode}
                onChange={(e) => setInputCode(e.getValue())}
                autoCursor={true}
                autoScroll={false}
                options={{
                  ...options,
                  mode: {
                    name: LANG_TO_CM_MODE_MAP[fromLanguage],
                    globalVars: true,
                  },
                }}
                style={{
                  height: "300px",
                }}
              />
            )}
          </div>
          {isMobileDevice() && (
            <div className="col-lg-2 col-sm-12 d-flex align-items-center">
              {convertButton}
            </div>
          )}
          <div className="col-lg-6 col-sm-12">
            <h3>Output code</h3>
            {CodeMirror && (
              <CodeMirror
                value={outputCode}
                onChange={(e) => setOutputCode(e.getValue())}
                autoCursor={false}
                autoScroll={false}
                options={{
                  ...options,
                  mode: {
                    name: LANG_TO_CM_MODE_MAP[toLanguage],
                    globalVars: true,
                  },
                }}
                style={{
                  minHeight: "300px",
                }}
              />
            )}
          </div>
        </div>
        <div className="d-flex d-flex justify-content-around">
          <div className="col-lg-6">{!isMobileDevice() && convertButton}</div>
        </div>
      </div>
      <div className="col-lg-10 small row mx-auto my-4">
        <div className="col-lg-3 mb-4">
          <h4>Convert {titleize(fromLanguage)} to:</h4>

          {LANGUAGES.filter((lang) => lang !== fromLanguage).map((l) => (
            <li
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Link to={`/convert/${fromLanguage}/${l}`}>{titleize(l)}</Link>
            </li>
          ))}
        </div>
        <div className="col-lg-3 mb-4">
          <h4>Convert to {titleize(fromLanguage)}:</h4>

          {LANGUAGES.filter((lang) => lang !== fromLanguage).map((l) => (
            <li
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Link to={`/convert/${l}/${fromLanguage}`}>{titleize(l)}</Link>
            </li>
          ))}
        </div>
        <div className="col-lg-3 mb-4">
          <h4>Convert {titleize(toLanguage)} to:</h4>
          {LANGUAGES.filter((lang) => lang !== toLanguage).map((l) => (
            <li
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Link to={`/convert/${toLanguage}/${l}`}>{titleize(l)}</Link>
            </li>
          ))}
        </div>
        <div className="col-lg-3 mb-4">
          <h4>Convert to {titleize(toLanguage)}:</h4>

          {LANGUAGES.filter((lang) => lang !== toLanguage).map((l) => (
            <li
              style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              <Link to={`/convert/${l}/${toLanguage}`}>{titleize(l)}</Link>
            </li>
          ))}
        </div>
      </div>
    </>
  );
};
export default CodeConverter;
