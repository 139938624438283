import "./SectionContainer.css";

import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import React, { useState } from "react";
import { addSectionCompletion, isSectionComplete } from "./helpers/completions";
import {
  extractOnlyText,
  isMobileDevice,
  postCompletion,
  titleize,
} from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import Ad from "./Ad";
import Badges from "./Badges";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown/with-html";
import ReactTooltip from "react-tooltip";
import SectionLessonCard from "./SectionLessonCard";
import Skeleton from "react-loading-skeleton";
import Video from "./Video";
import { actions } from "./redux/StateReducer";

const SectionContainer = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);
  const courses = useSelector((state) => state.courses);
  const language = useSelector((state) => state.language);
  const user = useSelector((state) => state.user);

  // FIXME: rename all category/setCategory to section/setSection
  const [category, setCategory] = useState({});

  let { sectionSlug } = useParams();

  const location = useLocation();

  const resourceName = location.pathname.includes("section")
    ? "section"
    : "category";
  const resourceNamePlural = location.pathname.includes("sections")
    ? "sections"
    : "categories";

  React.useEffect(() => {
    function setObj(obj) {
      setCategory(Object.assign({}, obj, { next: obj.next }));

      if (resourceName === "section") {
        dispatch({
          type: actions.SET_ACTIVE_SECTIONS,
          payload: [obj],
        });
      } else {
        dispatch({
          type: actions.SET_ACTIVE_SECTIONS,
          payload: [],
        });
      }

      if (obj.course) {
        dispatch({
          type: actions.SET_COURSE,
          payload: obj.course,
        });
      }
    }

    for (let course of courses) {
      if (course.sections) {
        for (let section of course.sections) {
          if (section.slug === sectionSlug) {
            setObj(section);
            return;
          }
        }
      }
    }

    // handle both id and slug as URL identifiers
    if (
      category?.slug !== sectionSlug &&
      category?.id !== parseInt(sectionSlug)
    ) {
      fetch(`/api/${resourceNamePlural}/${sectionSlug}`)
        .then((res) => {
          return res.json();
        })
        .then(
          (obj) => {
            if (obj.locked && obj.redirect) {
              return window.location.replace(obj.redirect);
            }

            // fixme
            const parsedObj = JSON.parse(obj[resourceName]);

            setObj(parsedObj);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    // if (!user) {
    //   window.addEventListener("load", function () {
    //     var ezstandalone = window.ezstandalone || {};
    //     ezstandalone.cmd = ezstandalone.cmd || [];

    //     if (
    //       ezstandalone.placeholders.includes(129) &&
    //       ezstandalone.placeholders.includes(117)
    //     ) {
    //       ezstandalone.cmd.push(function () {
    //         ezstandalone.refresh();
    //       });
    //     } else {
    //       ezstandalone.cmd.push(function () {
    //         ezstandalone.displayMore(129, 117);
    //       });
    //     }
    //   });
    // }
  }, [sectionSlug, course]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (!category.slug) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  const challengesLength = category.challenges.length || 0;
  const completedChallenges =
    category.challenges.filter((c) => c.completed) || 0;
  const lessonsLength = category.lessons.length || 0;
  const completedLessons = category.lessons.filter((l) => l.completed) || 0;

  const completedString = isMobileDevice() ? "" : " Completed";

  const pctCompleted = Math.floor(
    ((completedChallenges.length + completedLessons.length) /
      (challengesLength + lessonsLength)) *
      100
  );

  const progressBar = (
    <div className="progress" style={{ height: "16px" }}>
      {challengesLength || lessonsLength ? (
        (completedChallenges.length + completedLessons.length) /
          (challengesLength + lessonsLength) >=
        0.2 ? (
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${pctCompleted}%` }}
            aria-valuenow={pctCompleted}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {`${completedChallenges.length + completedLessons.length} / ${
              challengesLength + lessonsLength
            }${completedString}`}{" "}
          </div>
        ) : (
          `${completedChallenges.length + completedLessons.length} / ${
            challengesLength + lessonsLength
          }${completedString}`
        )
      ) : null}
    </div>
  );

  const metaInfo = (tut) => (
    <div className="position-absolute" style={{ top: "1rem", right: "1rem" }}>
      {!(user && user.paid_active) && (
        <img
          src={`https://storage.googleapis.com/algodailyrandomassets/marketing/${
            tut.locked ? "lock" : "free"
          }.png?b=1`}
          style={{
            width: "2.2rem",
            height: "2.2rem",
            WebkitFilter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
            filter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
          }}
          title="This tutorial is free"
          loading="lazy"
        />
      )}
      {tut.video ? (
        <img
          src="https://storage.googleapis.com/algodailyrandomassets/marketing/video.png?b=1"
          className="ml-2"
          style={{
            width: "2.2rem",
            height: "2.2rem",
            WebkitFilter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
            filter: "drop-shadow(1px 1px 1px rgba(18, 38, 63, 0.15))",
          }}
          title="This tutorial has video"
          loading="lazy"
        />
      ) : null}
    </div>
  );

  function updateRenderedLesson(les, flag) {
    // Update lesson in menu
    const newSections = JSON.parse(JSON.stringify(course.sections));

    // have to fix category --> sections
    const sectionIdx = newSections.findIndex((s) => s.id === category.id);
    const tutorialIdx = newSections[sectionIdx].lessons.findIndex(
      (t) => t.slug === les.slug
    );
    newSections[sectionIdx].lessons[tutorialIdx].completed = flag;

    // TODO: fix once challenges have a single section to reflect both
    if (flag) {
      // if completed = true
      if (isSectionComplete(newSections[sectionIdx])) {
        addSectionCompletion(newSections[sectionIdx]);
      }
    }

    dispatch({
      type: actions.SET_COURSE,
      payload: { ...course, sections: newSections },
    });
  }

  return (
    <>
      <Helmet>
        <title>
          {(category &&
            (category.name || category.title) &&
            "AlgoDaily - " + (category.name || category.title)) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."}
        </title>
        <meta
          name="description"
          content={
            (category && category.description) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:image"
          content={
            category && category.image_url
              ? category.image_url
              : `https://storage.googleapis.com/algodailyrandomassets/curriculum/${category.slug}/cover.jpg`
          }
        />
        <meta
          property="og:url"
          content={
            (category && `https://algodaily.com/sections/${category.slug}`) ||
            "https://algodaily.com"
          }
        />
        <meta
          property="og:title"
          content={
            (category &&
              (category.name || category.title) &&
              "AlgoDaily - " + titleize(category.name || category.title)) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:description"
          content={
            (category && category.description) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:image"
          content={
            category && category.image_url
              ? category.image_url
              : `https://storage.googleapis.com/algodailyrandomassets/curriculum/${category.slug}/cover.jpg`
          }
        />
      </Helmet>
      <div
        className={`${
          isMobileDevice() ? "p-2" : "p-0"
        } container position-relative`}
      >
        {/* FIX: category vs section */}
        {course && category ? (
          <div
            className="small mb-4"
            style={
              isMobileDevice()
                ? {}
                : {
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }
            }
          >
            {user ? <Link to={`/dashboard`}>Home</Link> : <a href="/">Home</a>}{" "}
            {course && course.slug && course.title ? (
              <>
                >{" "}
                <Link
                  to={`/courses/${course.slug}`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {course.title || <Skeleton />}
                </Link>{" "}
              </>
            ) : null}
            >{" "}
            <Link
              to={`/sections/${category.slug}`}
              style={{ whiteSpace: "nowrap" }}
            >
              <strong>{category.name || category.title || <Skeleton />}</strong>
            </Link>
          </div>
        ) : null}
        <div className="border bg-white no-gutters rounded-lg row d-flex align-items-center mb-4">
          <div className="col-lg-4">
            {category.image_url ? (
              <img
                src={category.image_url}
                className="img-fluid"
                loading="lazy"
              />
            ) : (
              <div className="w-100 p-4">
                <img
                  src={`https://storage.googleapis.com/algodailyrandomassets/curriculum/${category.slug}/cover.jpg`}
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
            )}
          </div>
          <div className="col-lg-8 p-4">
            <h3 className="font-weight-bold">
              {category.name || category.title || <Skeleton />}
              {user && user.admin && (
                <>
                  {" "}
                  <i
                    className="fa fa-users-cog mx-1 cursor-pointer"
                    onClick={() =>
                      window.location.replace(
                        `/admin/section/${category.id}/edit`
                      )
                    }
                  />{" "}
                  <i
                    className="fa fa-random mx-1 cursor-pointer"
                    onClick={() =>
                      history.push(`/sections/${category.slug}/edit`)
                    }
                  />
                </>
              )}
            </h3>
            {category.description ? (
              <p>
                <ReactMarkdown
                  source={category.description}
                  escapeHtml={false}
                />
              </p>
            ) : (
              <Skeleton />
            )}
            <div className="d-flex mb-2 flex-wrap">
              <Badges obj={category} />
            </div>
            {user ? progressBar : null}
          </div>
        </div>

        {!(user && user.paid_active) && (
          <Ad kind="wide" />
          // <div id="ezoic-pub-ad-placeholder-129" className="w-100"></div>
        )}

        <div className="card bg-white">
          <div className={`card-body ${isMobileDevice() ? "p-2" : "p-4"} `}>
            {(category.lessons && lessonsLength) ||
            (category.challenges && challengesLength) ? (
              <div className="w-100 mb-4">
                <h3 className="font-weight-bold">Section Menu</h3>
                <p className="text-muted">
                  <strong
                    data-tip="<div style='max-width: 24rem;'> This section contains the following lessons and challenges. It's recommended you go through the tutorials in order, but
                      you can skip around if you want. To keep track of progress, be
                      sure to mark each lesson and challenge as complete once you've
                      finished it. You can also bookmark lessons and challenges to
                      come back to them later.</div>"
                    data-html={true}
                  >
                    <i className="fas fa-info-circle"></i> How do I use this
                    section?
                  </strong>
                </p>
                <div className="row mb-4">
                  {category.lessons && lessonsLength
                    ? category.lessons.map((lesson, idx) => {
                        return (
                          <SectionLessonCard
                            idx={idx}
                            lesson={lesson}
                            updateRenderedLesson={updateRenderedLesson}
                            user={user}
                          />
                        );
                      })
                    : null}

                  {category.challenges && category.challenges.length
                    ? category.challenges.map((chal, idx) => (
                        <div className="col-lg-4 mb-5" key={`chal-${idx}`}>
                          <div
                            className={`card ${
                              chal.completed ? "bg-white" : "text-dark bg-light"
                            } border-0 shadow rounded-lg position-relative`}
                            style={
                              chal.completed
                                ? {
                                    color: "silver",
                                  }
                                : null
                            }
                          >
                            <img
                              src={chal.image_url}
                              className="card-img-top rounded-top bg-white cursor-pointer"
                              style={{
                                objectFit: "cover",
                                height: "12rem",
                                opacity: chal.completed ? 0.3 : 1,
                              }}
                              onClick={() =>
                                history.push(`/challenges/${chal.slug}`)
                              }
                              loading="lazy"
                            />

                            {metaInfo(chal)}

                            <div
                              className="card-body py-auto cursor-pointer"
                              onClick={() =>
                                history.push(`/challenges/${chal.slug}`)
                              }
                            >
                              <small>
                                {lessonsLength + idx + 1}. CHALLENGE
                              </small>
                              <h4
                                className="font-weight-bold"
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {chal.title ? (
                                  <Link
                                    to={`/challenges/${chal.slug}`}
                                    style={{ color: "inherit" }}
                                  >
                                    {chal.title}
                                  </Link>
                                ) : (
                                  <Skeleton />
                                )}
                              </h4>
                              <p
                                className="small"
                                style={{
                                  overflow: "hidden",
                                  display: "-webkit-box",
                                  WebkitLineClamp: 6,
                                  lineClamp: 6,
                                  WebkitBoxOrient: "vertical",
                                }}
                              >
                                {chal.excerpt ? (
                                  extractOnlyText(chal.excerpt)
                                ) : (
                                  <Skeleton />
                                )}
                                ...
                              </p>
                            </div>
                            <div className="card-footer d-flex justify-content-between small">
                              <span>Difficulty: {chal.difficulty}/10</span>
                              {chal.completed ? (
                                <span>
                                  <i className="far fa-check-square pr-1"></i>{" "}
                                  Completed
                                </span>
                              ) : (
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (chal.completed) {
                                      return;
                                    }
                                    // we can't use addChallengeCompleton since it pulls from the challenge in app state
                                    if (user) {
                                      let body = JSON.stringify({
                                        completion: {
                                          userEmail: user.email,
                                          slug: chal.slug,
                                          challenge_id: chal.id,
                                          performance: 0,
                                          language_id: language.id,
                                        },
                                      });

                                      postCompletion(
                                        "challenges",
                                        chal.slug,
                                        body,
                                        (response) => {
                                          if (response.status == 200) {
                                            const newSections = JSON.parse(
                                              JSON.stringify(course.sections)
                                            );
                                            // have to fix category --> sections
                                            const sectionIdx = newSections.findIndex(
                                              (s) => s.slug === category.slug
                                            );
                                            const tutorialIdx = newSections[
                                              sectionIdx
                                            ].challenges.findIndex(
                                              (t) => t.slug === chal.slug
                                            );
                                            newSections[sectionIdx].challenges[
                                              tutorialIdx
                                            ].completed = true;

                                            dispatch({
                                              type: actions.SET_COURSE,
                                              payload: {
                                                ...course,
                                                sections: newSections,
                                              },
                                            });
                                          }
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <i className="far fa-square pr-1"></i> Mark
                                  Completed
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ) : null}

            {category.video ? (
              <Video video={category.video} user={user} />
            ) : null}

            {category.cheatsheet ? (
              <>
                <h3 className="font-weight-bold">Cheat Sheet</h3>
                <ReactMarkdown
                  source={category.cheatsheet}
                  escapeHtml={false}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        {!(user && user.paid_active) && (
          <Ad kind="wide" />
          // <div id="ezoic-pub-ad-placeholder-117" className="w-100"></div>
        )}
      </div>
    </>
  );
};

export default SectionContainer;
