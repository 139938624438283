import "react-medium-image-zoom/dist/styles.css";

import { Link, useHistory } from "react-router-dom";
import {
  MD_MODE_TO_LANG_MAP,
  determineCanonicalUrl,
  getUrlLanguage,
} from "./utils";

import CodeSnippet from "../CodeSnippet";
import LessonCode from "../AuxiliaryContent/LessonCode";
import MathJax from "react-mathjax";
import React from "react";
import Zoom from "react-medium-image-zoom";

export const promptRenderer = (config) => {
  const { props: origProps, stateLanguage } = config;

  return {
    image: (props) => {
      return (
        <Zoom wrapStyle={{ width: "100%", textAlign: "center" }}>
          <img
            src={props.src}
            style={{
              width: props.src.indexOf("svg") > -1 ? "100%" : "auto",
              maxWidth: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
            loading="lazy"
            alt={origProps?.screen?.title}
          />
        </Zoom>
      );
    },
    parsedHtml: (props) => {
      if (props.element.type === "img") {
        return (
          <Zoom wrapStyle={{ width: "100%", textAlign: "center" }}>
            <img
              src={props.element.props.src}
              style={{
                width:
                  props.element.props.src.indexOf("svg") > -1 ? "100%" : "auto",
                maxWidth: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
              loading="lazy"
              alt={origProps?.screen?.title}
            />
          </Zoom>
        );
      }
      return props.element;
    },
    code: (pr) => {
      return (
        <CodeSnippet
          languageCode={pr.language}
          code={pr.value}
          screenKind={origProps.screen.kind}
          runCode={origProps.runCode}
        />
      );
    },
    table: (props) => {
      return <table className="table">{props.children}</table>;
    },
    math: (props) => <MathJax.Node formula={props.value} />,
    inlineMath: (props) => <MathJax.Node inline formula={props.value} />,
    "code-tabs": (props) => {
      const history = useHistory();

      const { children } = props;

      const [selectedChild, setSelectedChild] = React.useState(
        children[0].props
      );

      React.useEffect(() => {
        children.forEach((c) => {
          const lang = MD_MODE_TO_LANG_MAP[c.props.language];

          // Choose default tab here
          if (getUrlLanguage() === lang || stateLanguage === lang) {
            setSelectedChild(c.props);
          }
        });
      });

      return (
        <div>
          <ul className="nav nav-tabs">
            {children
              .sort((a, b) => {
                const langA = a.props.language;
                const langB = b.props.language;

                if (langA < langB) {
                  return -1;
                }
                if (langA > langB) {
                  return 1;
                }
                return 0;
              })
              .map(({ props: pre }, i) => {
                const newUrl = determineCanonicalUrl(
                  MD_MODE_TO_LANG_MAP[pre.language],
                  {
                    slug: "",
                  }
                );

                return (
                  <li
                    className="nav-item cursor-pointer"
                    key={i}
                    onClick={() => {
                      return history.push(newUrl);
                      // setSelectedChild(pre)
                    }}
                  >
                    <Link
                      className={`nav-link ${
                        pre == selectedChild ? "active" : "text-ocean-blue"
                      }`}
                      to={newUrl}
                    >
                      {MD_MODE_TO_LANG_MAP[pre.language] || pre.language}
                    </Link>
                  </li>
                );
              })}
          </ul>
          <CodeSnippet
            hasHeader={true}
            languageCode={selectedChild.language}
            code={selectedChild.value}
            screenKind={origProps.screen.kind}
            runCode={origProps.runCode}
          />
        </div>
      );
    },
  };
};

export const executableCodeRenderer = (origProps) => {
  return {
    code: (pr) => {
      return (
        <LessonCode
          challenge={origProps.challenge}
          codeFromLocalStorage={origProps.codeFromLocalStorage}
          language={pr.language}
          runCode={origProps.runCode}
          screenCode={pr.value}
        />
      );
    },
    "code-tabs": (props) => {
      const { children } = props;

      const languagesSupported = children.map((c) => c.props.language);

      return (
        <LessonCode
          challenge={origProps.challenge}
          codeFromLocalStorage={origProps.codeFromLocalStorage}
          language={origProps.language}
          languagesSupported={languagesSupported}
          editorVal={origProps.editorVal}
          runCode={origProps.runCode}
          screenCode={children}
        />
      );
    },
  };
};
