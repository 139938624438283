import { toast } from "react-toastify";

const judgeLangFromAlgo = (lang, isTest = false) => {
  const MAP = {
    34: "25", // "71", // python
    29: "63", // js
    javascript: "63",
    python: "25", // "71",
    java: isTest ? "5" : "4",
    go: "60",
    ruby: "72",
    csharp: isTest ? "23" : "51",
    cpp: isTest ? "12" : "54",
    "c++": isTest ? "12" : "54",
    php: "68",
    ts: "94",
    typescript: "94",
  };
  return MAP[lang];
};

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
}

function encode(str) {
  return unescape(encodeURIComponent(str));
}

export default class CodeExecutor {
  constructor({ val, language, isTestSubmission, outNodeRef }) {
    this.timeStart = performance.now();

    this.val = val;
    this.outNodeRef = outNodeRef;
    this.languageId = judgeLangFromAlgo(language, isTestSubmission);

    this.token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");
  }

  runCode() {
    if (!this.languageId) {
      console.log("No language id");
      return toast.error("Sorry, this block isn't executable!");
    }

    // Clear previous
    const $outputRef = $(this.outNodeRef);
    $outputRef.find(".output").html("<div></div>");
    $outputRef
      .addClass("output-overlay d-flex flex-column")
      .css({ "min-height": `0px` });
    $outputRef
      .find(".output")
      .html("<i class='fas fa-spinner text-dark fa-pulse'></i>");

    try {
      fetch("/submissions/judge", {
        method: "POST",
        async: true,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.token,
        },
        crossDomain: true,
        body: JSON.stringify({
          source_code: encode(this.val),
          language_id: this.languageId,
          number_of_runs: "1",
          stdin: "",
          // expected_output: "",
          cpu_time_limit: "2",
          cpu_extra_time: "0.5",
          wall_time_limit: "5",
          memory_limit: "128000",
          stack_limit: "64000",
          max_processes_and_or_threads: "30",
          enable_per_process_and_thread_time_limit: false,
          enable_per_process_and_thread_memory_limit: true,
          max_file_size: "1024",
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((resp) => {
          this.pollSubmission(resp.token);
        });
    } catch (e) {
      console.log(e);
    }
  }

  pollSubmission(submission_token) {
    const SUBMISSION_CHECK_TIMEOUT = 300;

    const self = this;
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    $.ajax({
      url: `/submissions/judge/${submission_token}/${this.languageId}`,
      type: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      async: true,
      success: function (data, textStatus, jqXHR) {
        if (data.status.id <= 2) {
          // In Queue or Processing
          setTimeout(
            () => self.pollSubmission(submission_token),
            SUBMISSION_CHECK_TIMEOUT
          );
          return;
        }
        self.handleResult(data);
      },
      error: this.handleRunError,
    });
  }

  handleResult(data) {
    // for deep copying
    let dataStdOut = data.stdout
      ? (" " + b64DecodeUnicode(data.stdout)).slice(1)
      : "";
    let dataStdErr = data.stderr
      ? (" " + b64DecodeUnicode(data.stderr)).slice(1)
      : "";

    if (dataStdOut && data.status.description === "Accepted") {
      this.out("log", [dataStdOut]);

      if (["63", "71", "25", "60"].includes(this.languageId)) {
        // javascript, python, python for ml, go
        let passes = 0;

        for (let word of dataStdOut.split(" ")) {
          if (word.includes("PASSED")) {
            passes++;
          }
        }

        if (this.val.match(/PASSED/g)?.length === passes) {
          window.testsPassed(data.time && data.time * 1000);
        }
      } else if (this.languageId === "5") {
        const testCasesLength = this.val.match(/\@Test/g).length;

        // java
        if (dataStdOut.includes(`${testCasesLength} tests successful`)) {
          window.testsPassed(data.time && data.time * 1000);
        }
      } else if (this.languageId === "23") {
        const testCasesLength = this.val.match(/\[Test\]/g).length;

        // csharp
        if (dataStdOut.includes(`Passed: ${testCasesLength}`)) {
          window.testsPassed(data.time && data.time * 1000);
        }
      } else if (this.languageId === "12") {
        const testCasesLength = this.val.match(/^TEST\(/gm).length;

        // c++
        if (
          dataStdOut.includes(
            `${testCasesLength} tests from ${testCasesLength} test suites ran.`
          )
        ) {
          window.testsPassed(data.time && data.time * 1000);
        }
      } else if (this.languageId === "68") {
        const outputException = dataStdOut.match(/Exception:/g);

        // php
        if (!outputException && dataStdOut.includes("tests passed!")) {
          window.testsPassed(data.time && data.time * 1000);
        }
      }

      return;
    }

    if (dataStdErr) {
      this.out("log", [
        (dataStdOut += (dataStdOut == "" ? "" : "\n") + dataStdErr),
      ]);
      return;
    }

    const timeEnd = performance.now();
    console.log(
      "It took " + (timeEnd - this.timeStart) + " ms to get submission result."
    );

    var status_id = data.status.id;
    var stdout =
      dataStdOut || "No output. Remember to log or print if necessary.";

    var stderr = dataStdErr || "";
    var compile_output = data.compile_output
      ? b64DecodeUnicode(data.compile_output)
      : "";
    var message = data.message || "";
    var time = data.time === null ? "-" : data.time + "s";
    var memory = data.memory === null ? "-" : data.memory + "KB";

    this.out("log", [`${time}, ${memory}`]);

    if (status_id == 6) {
      stdout = compile_output;
    } else if (status_id == 13) {
      stdout = message;
    } else if (status_id != 3 && stderr != "") {
      // If status is not "Accepted", merge stdout and stderr
      stdout += (stdout == "" ? "" : "\n") + stderr;
    }

    this.out("log", [stdout || message]);
  }

  handleRunError(jqXHR, textStatus, errorThrown) {
    this.out("log", [errorThrown]);
  }

  out(type, args) {
    let timeOutMs = 800;

    let wrap = document.createElement("pre");
    wrap.className = "sandbox-output-" + type;
    for (let i = 0; i < args.length; ++i) {
      let arg = args[i];
      if (i) wrap.appendChild(document.createTextNode(" "));
      if (typeof arg == "string")
        wrap.appendChild(document.createTextNode(arg));
    }

    const outNodeRef = this.outNodeRef;
    const innerOutputEl = $(outNodeRef).find(".output");
    innerOutputEl.html("<div></div>");

    // jz: slow the loading of test results down
    setTimeout(function () {
      $(innerOutputEl).append(wrap);
    }, timeOutMs);
    timeOutMs += 200;
  }
}
