import {
  ClosingSequence,
  extractOnlyText,
  getCompletionsFromLocalStorage,
  getUrlLanguage,
  isMobileDevice,
  levelByDifficulty,
  postCompletion,
  readjustForSlides,
  strToSlug,
  toastNotSignedIn,
} from "./helpers/utils";
import React, { useState } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { actions, defaultChallenge } from "./redux/StateReducer";
import { useDispatch, useSelector } from "react-redux";

import AppNav from "./AppNav";
import AuxiliaryContent from "./AuxiliaryContent";
import ChallengeTabs from "./ChallengeTabs";
import CodeVizContainer from "./CodeVizContainer";
import Content from "./Content";
import Footer from "./SliderFooter";
import { Helmet } from "react-helmet";
// import TestCases from "./TestCases";
import Solution from "./Solution";
import Split from "react-split";
import { ToastContainer } from "react-toastify";
import TutorialMenu from "./TutorialMenu";
import UserCompletions from "./UserCompletions";
import Video from "./Video";
import { recursionInGo } from "./helpers/custom-screens";
import { titleize } from "./helpers/utils";

const SlidesContainer = (props) => {
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = React.useState(false);
  const challenge = useSelector((state) => state.challenge);
  const course = useSelector((state) => state.course);
  const language = useSelector((state) => state.language);
  const lesson = useSelector((state) => state.lesson);
  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);
  const vizContainerVisible = useSelector((state) => state.vizContainerVisible);

  const [companies, setCompanies] = useState([]);
  const [nextArr, setNextArr] = useState([]);

  const [screens, setScreens] = useState([defaultChallenge]);
  const [screen, setScreen] = useState(defaultChallenge);
  const [screenCompletions, setScreenCompletions] = useState(
    getCompletionsFromLocalStorage(props.completed, challenge, screens)
  );
  const [screenView, setScreenView] = useState("content");

  const [tutorialType, setTutorialType] = useState("lessons");
  const [section, setSection] = useState({});

  let { tutorialSlug, screenSlug } = useParams();
  const currLocation = useLocation();

  const outNodeRef = React.useRef();

  if (!screenSlug) {
    screenSlug = "description";
  }

  const showTestsPassedModal = async () => {
    if (!user) {
      return toastNotSignedIn("track your progress");
    }

    // HACK! Using lessons/read path to denote completions
    // Needs work to make both methods symmetrical
    if (challenge && challenge.slug) {
      dispatch({
        type: actions.ADD_CHALLENGE_COMPLETION,
        payload: {
          time: null,
        },
      });
    } else {
      postCompletion(tutorialType, tutorialSlug);
    }
    dispatch({
      type: actions.SET_SHOW_TESTS_PASSED,
      payload: true,
    });
  };

  React.useEffect(() => {
    const tutorialType = currLocation.pathname.includes("lesson")
      ? "lessons"
      : "challenges";

    const currLanguageParam = getUrlLanguage();

    if (
      isLoaded &&
      ((tutorialType === "lessons" &&
        lesson &&
        lesson.slug === tutorialSlug &&
        lesson.screens) ||
        (tutorialType === "challenges" &&
          challenge &&
          challenge.slug === tutorialSlug &&
          challenge.screens)) &&
      (currLanguageParam ? language === currLanguageParam : true)
    ) {
      return;
    }

    window.addEventListener("resize", readjustForSlides);

    setTutorialType(tutorialType);

    let languageToFetch = language;

    if (currLanguageParam) {
      // language in url
      dispatch({
        type: actions.SET_APP_LANGUAGE,
        payload: currLanguageParam,
      });
      languageToFetch = currLanguageParam;
    }

    fetch(
      `/api/${tutorialType}/${tutorialSlug}/${
        tutorialType === "challenges" ? languageToFetch : ""
      }`
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          if (obj.locked && obj.redirect) {
            return window.location.replace(obj.redirect);
          }

          if (obj.screen_completions && obj.screen_completions.length) {
            const screensWithCompletions = obj.screens.map((scr) => {
              return {
                ...scr,
                screenCompletion: obj.screen_completions.find(
                  (sc) => sc.screen_id === scr.id
                ),
              };
            });
            setScreens(screensWithCompletions);
          } else {
            setScreens(obj.screens);
          }

          if (screenSlug) {
            setScreen(obj.screens[0]);
          }

          setNextArr(obj.nextArr);
          setIsLoaded(true);

          if (obj.section) {
            setSection(obj.section);
          }

          if (tutorialType === "lessons") {
            const lessonWithAttributes = Object.assign(obj.lesson, {
              screens: obj.screens,
              nextArr: obj.nextArr,
            });

            dispatch({
              type: actions.SET_APP_LESSON,
              payload: lessonWithAttributes,
            });
          } else if (tutorialType === "challenges") {
            if (obj.companies) {
              setCompanies(obj.companies);
            }

            const challengeWithAttributes = JSON.parse(
              JSON.stringify(
                Object.assign(obj.challenge, {
                  screens: obj.screens,
                  nextArr: obj.nextArr,
                })
              )
            );

            dispatch({
              type: actions.SET_APP_CHALLENGE,
              payload: challengeWithAttributes,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // TO DO: handle errors
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
        }
      );

    setScreenCompletions(
      getCompletionsFromLocalStorage(
        props.completed,
        challenge || lesson,
        screens
      )
    );
  }, [tutorialType, tutorialSlug, window.location.pathname, language]);

  React.useEffect(() => {
    const foundScreenArr = screens.filter((s) => s.slug === screenSlug);
    if (foundScreenArr.length) {
      setScreen(foundScreenArr[0]);
    }

    setScreenView("content");

    const tutorial = lesson || challenge;
    if (!tutorial.completed && localStorage) {
      localStorage.setItem(`${tutorial.slug}-${screenSlug}`, "true");
      let curr = screenCompletions;
      curr[screens.indexOf(screen)] = true;
      setScreenCompletions(curr);
    }

    readjustForSlides();

    if (screenListOpen) {
      dispatch({
        type: actions.SET_SCREEN_LIST_OPEN,
        payload: false,
      });
    }
  }, [screen, screenSlug, screenView]);

  React.useEffect(() => {
    readjustForSlides();
  });

  // Kind of a hack: if there's no matching screen available, always redirect to sales page
  // if (!screen) {
  //   window.location.href = "/subscriptions/new";
  // }

  const screenSlugs = screens.map((s) => s.slug);

  const isFirstScreen = screens[0] == screen;
  const isLastScreen = screens[screens.length - 1] == screen;

  function renderPage(screen) {
    return (
      <Switch>
        <Route
          path={[
            "/challenge_slides/:tutorialSlug/:language/solutions",
            "/challenge_slides/:tutorialSlug/solutions",
          ]}
          exact
        >
          <Solution
            challenge={challenge}
            hideSolution={(e) => {
              e.preventDefault();
              setScreenView("content");
            }}
            language={language}
            user={user}
          />
        </Route>
        {/* <Route
          path={[
            "/challenge_slides/:tutorialSlug/:language/tests",
            "/challenge_slides/:tutorialSlug/tests",
          ]}
          exact
        >
          <TestCases
            challenge={challenge}
            hideTests={(e) => {
              e.preventDefault();
              setScreenView("content");
            }}
            language={language}
            setIsEditingTests={props.setIsEditingTests}
            user={user}
          />
        </Route> */}
        <Route
          path={[
            "/challenge_slides/:tutorialSlug/:language/completions",
            "/challenge_slides/:tutorialSlug/completions",
          ]}
          exact
        >
          <UserCompletions
            challenge={challenge}
            screen={screen}
            hideCompletions={(e) => {
              e.preventDefault();
              setScreenView("content");
            }}
            user={user}
          />
        </Route>
        <Route
          path={[
            "/challenge_slides/:tutorialSlug/:language/:screenSlug",
            "/challenge_slides/:tutorialSlug/:language",
            "/challenge_slides/:tutorialSlug/:screenSlug",
            "/challenge_slides/:tutorialSlug",
            "/lesson_slides/:tutorialSlug/:language/:screenSlug",
            "/lesson_slides/:tutorialSlug/:language",
            "/lesson_slides/:tutorialSlug/:screenSlug",
            "/lesson_slides/:tutorialSlug",
          ]}
          exact
        >
          <div
            className={`bg-white rounded-lg ${
              challenge && challenge.title ? "px-5 py-4" : "p-5"
            }`}
          >
            {isFirstScreen && (
              <>
                <h2 className="font-weight-bold">
                  {lesson ? lesson.title : challenge.title}{" "}
                  {challenge && challenge.difficulty && (
                    <span>({levelByDifficulty(challenge.difficulty)})</span>
                  )}
                </h2>
                {lesson && lesson.video ? (
                  <Video video={lesson.video} user={user} />
                ) : null}
              </>
            )}
            <Content
              challenge={challenge}
              companies={companies}
              isFirstScreen={isFirstScreen}
              isLastScreen={isLastScreen}
              nextArr={nextArr}
              jsSolutionCode={props.jsSolutionCode}
              solutionCode={challenge.solutionCode}
              screen={screen}
              screenSlugs={screenSlugs}
              revealSolution={(e) => {
                e.preventDefault();
                setScreenView("solution");
              }}
              tutorialSlug={tutorialSlug}
            />
            <ClosingSequence
              isLastScreen={isLastScreen}
              challenge={challenge}
              tutorialSlug={tutorialSlug}
            />
          </div>
        </Route>
      </Switch>
    );
  }

  const mobileDevice = isMobileDevice();

  if (!lesson?.slug && !challenge?.slug) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  if (!isLoaded) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  const languageParam = getUrlLanguage();
  const metaTitle =
    (challenge &&
      challenge.title &&
      "AlgoDaily - " +
        challenge.title +
        " - " +
        (languageParam
          ? `In ${titleize(languageParam)}`
          : screen && screen.title)) ||
    (lesson &&
      lesson.title &&
      "AlgoDaily - " +
        lesson.title +
        " - " +
        (languageParam
          ? `In ${titleize(languageParam)}`
          : screen && screen.title)) ||
    "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy.";

  const metaDescription =
    (challenge && extractOnlyText(challenge.excerpt)) ||
    (lesson && extractOnlyText(lesson.excerpt)) ||
    "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy.";

  const metaImageUrl = lesson
    ? lesson.image_url
    : (challenge && challenge.image_url) ||
      `https://algodaily.com/img/socialshare-${parseInt(
        Math.random() * 8
      )}.png`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          property="og:url"
          content={
            (challenge &&
              challenge.question &&
              `https://algodaily.com/challenges/${challenge.slug}`) ||
            (lesson && `https://algodaily.com/lessons/${lesson.slug}`) ||
            "https://algodaily.com"
          }
        />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta
          property="og:image"
          content={
            lesson
              ? lesson.image_url
              : `https://algodaily.com/img/socialshare-${parseInt(
                  Math.random() * 8
                )}.png`
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@algodaily" />
        <meta name="twitter:creator" content="@algodaily" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImageUrl} />
        <link
          rel="canonical"
          href={
            (challenge &&
              challenge.question &&
              `https://algodaily.com/challenges/${challenge.slug}`) ||
            (lesson && `https://algodaily.com/lessons/${lesson.slug}`) ||
            "https://algodaily.com"
          }
        />
      </Helmet>
      <AppNav
        completed={false}
        leftLink={`/sections/${section.id}`}
        leftTitle={section.title}
        title={
          (tutorialType === "lessons" && lesson && lesson.title) ||
          (challenge && challenge.title) ||
          (course && course.slug && course.title) ||
          ""
        }
      />
      {screenListOpen ? (
        <TutorialMenu
          revealSolution={(e) => {
            e.preventDefault();
            setScreenView("solution");
            dispatch({
              type: actions.SET_SCREEN_LIST_OPEN,
              payload: false,
            });
          }}
          revealTests={(e) => {
            e.preventDefault();
            setScreenView("tests");
            dispatch({
              type: actions.SET_SCREEN_LIST_OPEN,
              payload: false,
            });
          }}
          revealCompletions={(e) => {
            e.preventDefault();
            setScreenView("completions");
            dispatch({
              type: actions.SET_SCREEN_LIST_OPEN,
              payload: false,
            });
          }}
          screen={screen}
          screens={screens}
          screenCompletions={screenCompletions}
          tutorialType={
            tutorialType === "lessons" ? "lesson_slides" : "challenge_slides"
          }
          tutorialSlug={tutorialSlug}
        />
      ) : null}
      {screen.full_screen ? (
        <main
          className="bg-white w-100 lesson-screen screens-container"
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <div
            id="content-container"
            className="col-lg-9 col-sm-12 w-100 p-0 m-auto"
          >
            <div className="bg-white rounded-lg p-4 mb-4">
              {isFirstScreen && (
                <>
                  <h3 className="font-weight-bold">
                    {lesson ? lesson.title : challenge.title}{" "}
                    {challenge && challenge.difficulty && (
                      <span>{levelByDifficulty(challenge.difficulty)}</span>
                    )}
                  </h3>
                  {lesson && lesson.video ? (
                    <Video video={lesson.video} user={user} />
                  ) : null}
                </>
              )}
              <Content screen={screen} />
              <ClosingSequence
                isLastScreen={isLastScreen}
                challenge={challenge}
                tutorialSlug={tutorialSlug}
              />
            </div>
          </div>
          <ToastContainer />
        </main>
      ) : (
        <main className="bg-white w-100 lesson-screen">
          <Split
            sizes={mobileDevice ? [100, 100] : [50, 50]}
            gutterSize={8}
            gutterAlign="center"
            snapOffset={30}
            dragInterval={1}
            direction="horizontal"
            cursor="col-resize"
            className={`split-screen row w-100 no-gutters`}
            onDrag={() => {
              const challengeTabs = document.getElementById("challenge-tabs");

              if (challengeTabs) {
                challengeTabs.style.width =
                  document.getElementById("content-container").offsetWidth +
                  "px";
              }
            }}
          >
            <main
              id="content-container"
              className="bg-white slides h-100"
              style={{
                overflow: "scroll",
              }}
            >
              {challenge && challenge.title ? (
                <ChallengeTabs
                  activeTab={screenView}
                  revealQuestion={(e) => {
                    e.preventDefault();
                    setScreenView("content");
                  }}
                  revealSolution={(e) => {
                    e.preventDefault();
                    setScreenView("solution");
                  }}
                  revealTests={(e) => {
                    e.preventDefault();
                    setScreenView("tests");
                  }}
                  revealCompletions={(e) => {
                    e.preventDefault();
                    setScreenView("completions");
                  }}
                  tutorialSlug={tutorialSlug}
                />
              ) : null}
              {renderPage(screen)}
            </main>

            <Split
              sizes={mobileDevice ? [100, 100] : [75, 25]}
              gutterSize={8}
              gutterAlign="center"
              snapOffset={30}
              dragInterval={1}
              direction="vertical"
              cursor="row-resize"
              // style={{ height: "95vh", height: "calc(100vh - 35px)" }}
              className={`screens-container ${mobileDevice ? "" : ""}`}
              onDrag={(sizes) => {
                readjustForSlides();
              }}
            >
              <AuxiliaryContent
                editorVal={props.editorVal}
                mode="slides"
                screen={screen} // this should toggle between lesson code and my code
                submitChallengeEdit={props.submitChallengeEdit}
              />

              <div
                id="curr-output-container"
                className="overflow-auto p-2 small"
                style={{ backgroundColor: "#f0f2f7", zIndex: "999" }}
                ref={outNodeRef}
              >
                <strong className="mb-2">OUTPUT</strong>
                <div
                  className="output pb-4"
                  style={{ backgroundColor: "#f0f2f7" }}
                >
                  Results will appear here.
                </div>
              </div>
            </Split>
          </Split>
          <ToastContainer />
        </main>
      )}
      <Footer
        nextArr={nextArr}
        screen={screen}
        screenSlugs={screenSlugs}
        screensLength={screens.length}
        screenCompletions={screenCompletions}
        toggleScreenList={() =>
          dispatch({
            type: actions.SET_SCREEN_LIST_OPEN,
            payload: !screenListOpen,
          })
        }
        showTestsPassedModal={showTestsPassedModal}
        tutorialType={tutorialType}
        tutorialSlug={tutorialSlug}
      />
      {vizContainerVisible ? <CodeVizContainer slug={challenge.slug} /> : null}
    </>
  );
};

export default SlidesContainer;
