import { greetingByDate, titleize } from "./helpers/utils";

import Ad from "./Ad";
import MathJax from "react-mathjax";
import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import RemarkMathPlugin from "remark-math";
import { promptRenderer } from "./helpers/markdown";
import remarkDisableTokenizers from "remark-disable-tokenizers";
import { useSelector } from "react-redux";

const codeTabs = require("./helpers/code-tabs.js");

const Content = React.memo(
  (props) => {
    const stateLanguage = useSelector((state) => state.language);
    const user = useSelector((state) => state.user);

    const exercise_prompt_map = {
      "multiple choice": "Click the correct answer from the options.",
      swipe: "Is this statement true or false?",
      order: "Could you figure out the right sequence for this list?",
      "fill in": "Fill in the missing part by typing it in.",
    };

    const [prompt, setPrompt] = React.useState(
      `${
        [
          "Build your intuition. ",
          "Try this exercise. ",
          "Let's test your knowledge. ",
          "Are you sure you're getting this? ",
        ][Math.floor(Math.random() * 4)]
      } ${exercise_prompt_map[props.screen.kind] || ""}`
    );

    // React.useEffect(() => {
    //   if (props.isAdScreen && !user) {
    //     window.addEventListener("load", function () {
    //       var ezstandalone = window.ezstandalone || {};
    //       ezstandalone.cmd = ezstandalone.cmd || [];

    //       if (ezstandalone.placeholders.includes(104)) {
    //         ezstandalone.cmd.push(function () {
    //           ezstandalone.refresh();
    //         });
    //       } else {
    //         ezstandalone.cmd.push(function () {
    //           ezstandalone.displayMore(104);
    //         });
    //       }
    //     });
    //   }
    // }, [props.isAdScreen]);

    function renderScreen(props) {
      if (props.isAdScreen) {
        (function () {
          // DON'T EDIT BELOW THIS LINE
          var d = document,
            s = d.createElement("script");
          s.src = "https://www.google.com/recaptcha/api.js";
          s.setAttribute("data-timestamp", +new Date());

          (d.head || d.body).appendChild(s);
        })();
      }

      if (
        props.screen.kind == "question" ||
        props.screen.kind == "info screen"
      ) {
        return (
          <>
            {props.screen.kind == "question" && (
              <p className="lead">
                {greetingByDate(user)} Here's our prompt for today.
              </p>
            )}
            {props.isFirstScreen &&
            props.companies &&
            props.companies.length ? (
              <p className="small">
                <strong>
                  You may see this problem at{" "}
                  {props.companies.map((cSlug, i) => [
                    i > 0 && i < props.companies.length - 1 && ", ",
                    i > 0 && i == props.companies.length - 1 && ", and ",
                    <a href={`/companies/${cSlug}`} target="_blank">
                      {titleize(cSlug.replace("-", " "))}
                    </a>,
                  ])}
                  .
                </strong>
              </p>
            ) : null}
            <MathJax.Provider input="tex">
              <ReactMarkdown
                // for arrays not wrapped in ticks
                plugins={[
                  codeTabs,
                  [remarkDisableTokenizers, { inline: ["reference"] }],
                  RemarkMathPlugin,
                ]}
                source={props.screen.content}
                renderers={promptRenderer({ props, stateLanguage })}
                escapeHtml={false}
              />
            </MathJax.Provider>
            {/* {!(user && user.paid_active) && props.isAdScreen && (
              <Ad kind="wide" />
              // <div id="ezoic-pub-ad-placeholder-104" className="w-100"></div>
            )} */}
            {/* {!user && props.isAdScreen && !props.codemode ? (
              <div className="mb-4 rounded-lg no-gutters align-items-center bg-light p-5">
                <p className="small">
                  <strong>Get free illustrated problems daily!</strong> We'll
                  send you 100+ of the most common coding interview questions,
                  once a day, with visual explanations. Join over 25,323 users
                  who are doubling their salaries in 30 minutes a day.
                </p>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <img
                      src="https://storage.googleapis.com/algodailyrandomassets/book-mock-preview.png"
                      className="w-100"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 d-flex align-items-center">
                    <div className="w-100 text-center">
                      <div className="form-group mb-2">
                        <input
                          className="email-subscribe-name form-control form-control-sm"
                          type="text"
                          placeholder="name"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <input
                          className="email-subscribe-email form-control form-control-sm"
                          type="text"
                          placeholder="email"
                        />
                      </div>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          grecaptcha.execute();
                        }}
                        className="w-100 btn btn-sm bg-info my-2 mx-auto text-white shadow"
                      >
                        <strong>Start for free</strong>
                      </a>
                    </div>
                  </div>
                  <div className="email-subscribe-result text-dark small px-4 m-auto">
                    <p>
                      <a href="/users/sign_in" target="_blank">
                        Returning users can login to stop seeing this
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            ) : null} */}
          </>
        );
      } else {
        return (
          <>
            <h4 className="lead font-weight-bold">{prompt}</h4>
            <ReactMarkdown
              plugins={[
                [codeTabs, remarkDisableTokenizers, { inline: ["reference"] }],
              ]}
              source={props.screen.content}
              renderers={promptRenderer({ props, stateLanguage })}
              escapeHtml={false}
            />
          </>
        );
      }
    }

    return <>{renderScreen(props)}</>;
  },
  (prevProps, nextProps) => {
    // always update content for USG screens
    if (
      prevProps.screen.slug === "ai-response" ||
      nextProps.screen.slug === "ai-response" ||
      prevProps.screen.slug === "chat-response" ||
      nextProps.screen.slug === "chat-response"
    ) {
      return false;
    }

    if (
      prevProps.screen.id === nextProps.screen.id &&
      prevProps.isAdScreen === nextProps.isAdScreen
    ) {
      return true; // props are equal
    }
    return false; // props are not equal -> update the component
  }
);

export default Content;
