import "react-toastify/dist/ReactToastify.css";

import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { addSectionCompletion, isSectionComplete } from "./helpers/completions";
import {
  extractOnlyText,
  getCompletionsFromLocalStorage,
  getUrlLanguage,
  isMobileDevice,
  levelByDifficulty,
  postCompletion,
  shuffle,
  titleize,
  toastNotSignedIn,
  tutorialContainerSize,
} from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import Ad from "./Ad";
import ChatPrompt from "./ChatWindow";
import FooterLinks from "./FooterLinks";
import { Helmet } from "react-helmet";
import React from "react";
import ScreenRenderer from "./ScreenRenderer";
import ShareButtons from "./ShareButtons";
import Skeleton from "react-loading-skeleton";
import TutorialEditor from "./TutorialEditor";
import TutorialMenu from "./TutorialMenu";
import Video from "./Video";
import { actions } from "./redux/StateReducer";
import { toast } from "react-toastify";

const defaultChallenge = {
  initialCode: "",
  solutionCode: "",
  slug: "",
};

const Tutorial = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const challenge = useSelector((state) => state.challenge);
  const course = useSelector((state) => state.course);
  const courseMode = useSelector((state) => state.courseMode);
  const crashCourse = useSelector((state) => state.crashCourse);
  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );
  const language = useSelector((state) => state.language);
  const lesson = useSelector((state) => state.lesson);
  const notes = useSelector((state) => state.notes);
  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);
  const view = useSelector((state) => state.view);

  const defaultScreen = {
    kind: "info screen",
    full_screen: false,
    code: "",
    slug: "introduction",
  };

  const [isLoaded, setIsLoaded] = React.useState(false);

  const [companies, setCompanies] = React.useState([]);
  const [beforeArr, setBeforeArr] = React.useState([]);

  const [nextArr, setNextArr] = React.useState([]);
  const [screens, setScreens] = React.useState([defaultChallenge]);
  const [screen, setScreen] = React.useState(defaultScreen);

  const [screenCompletions, setScreenCompletions] = React.useState([]);
  const [randomAdScreen, setRandomAdScreen] = React.useState({});
  const [tutorialType, setTutorialType] = React.useState("lessons");

  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-instance-variables
  const scrollRef = React.useRef();

  // For use in single screen routes like "/challenges/stacks/screen-slug"
  const [visibleScreens, setVisibleScreens] = React.useState([defaultScreen]);

  let { tutorialSlug, screenSlug } = useParams();
  const currLocation = useLocation();

  const containerRef = React.useRef(null);

  // If user defaults to slides, switch over
  const urlParams = new URLSearchParams(window.location.search);

  if (
    urlParams.get("view") !== "article" &&
    (lesson || (challenge && challenge.title)) &&
    view === "interactive"
  ) {
    history.push(
      `/${
        tutorialType === "lessons" ? "lesson_slides" : "challenge_slides"
      }/${tutorialSlug}`
    );
  }

  if (!screenSlug) {
    screenSlug =
      challenge && challenge.question ? "description" : "introduction";
  }

  function setNewChallengeFetch(obj) {
    if (obj.companies) {
      setCompanies(obj.companies);
    }

    const challengeWithAttributes = JSON.parse(
      JSON.stringify(
        Object.assign(obj.challenge, {
          section: obj.section,
          screens: obj.screens,
          nextArr: obj.nextArr,
          completed: obj.completed,
        })
      )
    );

    dispatch({
      type: actions.SET_APP_CHALLENGE,
      payload: challengeWithAttributes,
    });

    dispatch({
      type: actions.SET_ACTIVE_SECTIONS,
      payload: [challengeWithAttributes.section],
    });
  }

  React.useEffect(() => {
    scrollRef.current = false;

    if (!tutorialSlug) {
      return;
    }

    const tutorialType = currLocation.pathname.includes("lessons")
      ? "lessons"
      : "challenges";

    setTutorialType(tutorialType);

    const languageParam = getUrlLanguage();
    let languageToFetch = language;

    if (languageParam) {
      // language in url
      dispatch({ type: actions.SET_APP_LANGUAGE, payload: languageParam });
      languageToFetch = languageParam;
    }

    fetch(
      `/api/${tutorialType}/${tutorialSlug}/${
        tutorialType === "challenges" ? languageToFetch : ""
      }`
    )
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          // comes from direct_to_signup? method
          if (obj.locked && obj.redirect) {
            return window.location.replace(obj.redirect);
          }

          if (obj.screen_completions && obj.screen_completions.length) {
            const screensWithCompletions = obj.screens.map((scr) => {
              return {
                ...scr,
                screenCompletion: obj.screen_completions.find(
                  (sc) => sc.screen_id === scr.id
                ),
              };
            });
            setScreens(screensWithCompletions);
          } else {
            setScreens(obj.screens);
          }
          setBeforeArr(obj.beforeArr);
          setNextArr(obj.nextArr);

          const shuffled = shuffle(
            obj.screens.filter((s) => s.kind === "info screen")
          );
          setRandomAdScreen(shuffled[0]);

          scrollRef.current = true;

          if (tutorialType === "lessons") {
            const newLesson = Object.assign(obj.lesson, {
              completed: obj.completed,
              section: obj.section,
              course: obj.course,
            });

            dispatch({
              type: actions.SET_APP_LESSON,
              payload: newLesson,
            });

            dispatch({
              type: actions.SET_ACTIVE_SECTIONS,
              payload: obj.section ? [obj.section] : [],
            });
          } else if (tutorialType === "challenges") {
            setNewChallengeFetch(obj);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // TO DO: handle errors
          console.log(error);
        }
      );

    if (urlParams.get("view") === "article") {
      dispatch({
        type: actions.SET_SCREEN_LIST_OPEN,
        payload: true,
      });
    }
  }, [tutorialSlug]);

  // Need to also reload on language changes
  React.useEffect(() => {
    if (tutorialType === "challenges") {
      const languageParam = getUrlLanguage();
      let languageToFetch = language;

      if (languageParam) {
        // language in url
        dispatch({ type: actions.SET_APP_LANGUAGE, payload: languageParam });
        languageToFetch = languageParam;
      }

      fetch(`/api/challenges/${tutorialSlug}/${languageToFetch}`)
        .then((res) => {
          return res.json();
        })
        .then((obj) => {
          // comes from direct_to_signup? method
          if (obj.locked && obj.redirect) {
            return window.location.replace(obj.redirect);
          }

          setNewChallengeFetch(obj);
        });
    }
  }, [language]);

  React.useEffect(() => {
    const screenMatch = screens.filter(
      (s) => s.slug.replace(/[^a-zA-Z0-9_-]/g, "") === screenSlug
    );
    if (screenMatch && screenMatch.length) {
      const firstScreenMatched = screenMatch[0];

      setScreen(firstScreenMatched);
      setVisibleScreens([firstScreenMatched]);
    } else {
      // fall back for non-introduction/question first screens
      setScreen(screens[0]);
      setVisibleScreens([screens[0]]);
    }
  }, [screens, screenSlug]);

  React.useEffect(() => {
    if (screens.length >= 1 && screen.id) {
      setIsLoaded(true);

      // This is a work-around to the scrollRef situation in Tutorial.js - it sets a query param that forces a scroll-to-top action in Article mode
      const scrollToTop = new URLSearchParams(currLocation.search).get(
        "scrollToTop"
      );
      if (!scrollRef.current || scrollToTop) {
        window.scrollTo(0, 0);
        document.body.style.setProperty("font-size", "1rem", "important");
      }

      const { hash } = window.location;

      if (hash || screenSlug) {
        let id = "ad-app"; // backup if neither but still want to scroll up
        if (hash) {
          id = hash.replace("#", "");
        }

        const element = document.getElementById(id);
        if (element) {
          if (hash) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }
      }
    }
  });

  React.useEffect(() => {
    const tutorial = lesson && lesson.id ? lesson : challenge;
    setScreenCompletions(
      getCompletionsFromLocalStorage(tutorial.completed, tutorial, screens)
    );

    if (!isMobileDevice()) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id = entry.target.getAttribute("id");
          const item = document.querySelector(`#menu-${id}`);
          if (entry.intersectionRatio > 0) {
            const cl = item?.classList;
            cl?.add("text-ocean-blue");
            cl?.add("font-weight-bold");
          } else {
            const cl = item?.classList;
            cl?.remove("text-ocean-blue");
            cl?.remove("font-weight-bold");
          }
        });
      });

      if (screens && screens.length) {
        for (let s of screens) {
          const normalizedSlug = s.slug.replace(/[^a-zA-Z0-9_-]/g, "");
          const thisScreen = document.getElementById(normalizedSlug);

          if (thisScreen) {
            observer.observe(thisScreen);
          }
        }
      }
    }

    if (!user) {
      window.addEventListener("load", function () {
        var ezstandalone = window.ezstandalone || {};
        ezstandalone.cmd = ezstandalone.cmd || [];

        if (
          ezstandalone.placeholders.includes(129) &&
          ezstandalone.placeholders.includes(117)
        ) {
          ezstandalone.cmd.push(function () {
            ezstandalone.refresh();
          });
        } else {
          ezstandalone.cmd.push(function () {
            ezstandalone.displayMore(129, 117);
          });
        }
      });
    }
  }, [challenge, lesson]);

  function updateRenderedLesson(les, flag) {
    // Update current lesson
    const newLesson = { ...les };
    newLesson.completed = flag;
    dispatch({
      type: actions.SET_APP_LESSON,
      payload: newLesson,
    });

    // Update lesson in menu
    const newSections = JSON.parse(JSON.stringify(course.sections));
    // have to fix category --> sections
    const sectionIdx = newSections.findIndex(
      (s) => s.id === (les && les.section_id)
    );

    const tutorialIdx = newSections[sectionIdx].lessons.findIndex(
      (t) => t.slug === les.slug
    );
    newSections[sectionIdx].lessons[tutorialIdx].completed = flag;

    // TODO: fix once challenges have a single section to reflect both
    if (flag) {
      // if completed = true
      if (isSectionComplete(newSections[sectionIdx])) {
        addSectionCompletion(newSections[sectionIdx]);
      }
    }

    dispatch({
      type: actions.SET_COURSE,
      payload: { ...course, sections: newSections },
    });
  }

  function markAsComplete() {
    if (!user) {
      return toastNotSignedIn("track your progress");
    }

    if (lesson) {
      // Handling lessons
      if (lesson.completed) {
        const token = document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content");

        return fetch(`/api/lessons/${tutorialSlug}/unread`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
        }).then((res) => {
          if (!res.ok) {
            throw res;
          }

          updateRenderedLesson(lesson, false);
        });
      }

      postCompletion(tutorialType, tutorialSlug);

      toast.success(
        <span>
          <strong>{lesson.title}</strong> is marked as completed! Saved to your{" "}
          <Link to="/activity-log">
            <strong>activity log</strong>
          </Link>
          .
        </span>
      );

      updateRenderedLesson(lesson, true);
    } else {
      // Handling Challenges
      if (challenge.completed) {
        return;
      }
      // only executed code has time
      dispatch({
        type: actions.ADD_CHALLENGE_COMPLETION,
        payload: {
          time: null,
        },
      });

      dispatch({
        type: actions.SET_APP_CHALLENGE,
        payload: Object.assign({}, challenge, { completed: true }),
      });

      const newSections = [...course.sections];
      // have to fix category --> sections
      const filteredSectionIdx = newSections.findIndex(
        (s) => challenge.section_id === s.id
      );
      const tutorialIdx = filteredSection.challenges.findIndex(
        (t) => t.slug === challenge.slug
      );
      newSections[filteredSectionIdx].challenges[tutorialIdx].completed = true;

      dispatch({
        type: actions.SET_COURSE,
        payload: { ...course, sections: newSections },
      });

      toast.success(
        <span>
          {challenge.title} is marked as completed! Saved to your{" "}
          <Link to="/activity-log">
            <strong>activity log</strong>
          </Link>
          .
        </span>
      );
    }
  }

  if (!isLoaded) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  {
    /* Need fresh getUrlLanguage() calls because language choice can be cached via localStorage or DB */
  }
  const languageParam = getUrlLanguage();
  const metaTitle =
    (challenge &&
      challenge.title &&
      "AlgoDaily - " +
        challenge.title +
        (languageParam ? ` - In ${titleize(languageParam)}` : "")) ||
    (lesson &&
      lesson.title &&
      "AlgoDaily - " +
        lesson.title +
        (languageParam ? ` - In ${titleize(languageParam)}` : "")) ||
    "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy.";

  const metaDescription =
    (challenge && extractOnlyText(challenge.excerpt)) ||
    (lesson && extractOnlyText(lesson.excerpt)) ||
    "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy.";

  const metaImageUrl = lesson
    ? lesson.image_url
    : (challenge && challenge.image_url) ||
      `https://algodaily.com/img/socialshare-${parseInt(
        Math.random() * 8
      )}.png`;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta
          property="og:url"
          content={
            (challenge &&
              challenge.question &&
              `https://algodaily.com/challenges/${challenge.slug}`) ||
            (lesson && `https://algodaily.com/lessons/${lesson.slug}`) ||
            "https://algodaily.com"
          }
        />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImageUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@algodaily" />
        <meta name="twitter:creator" content="@algodaily" />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={metaImageUrl} />
        <link
          rel="canonical"
          href={
            (challenge &&
              challenge.question &&
              `https://algodaily.com/challenges/${challenge.slug}`) ||
            (lesson && `https://algodaily.com/lessons/${lesson.slug}`) ||
            "https://algodaily.com"
          }
        />
      </Helmet>
      <main
        id="tutorial-container"
        className={`${tutorialContainerSize(
          courseCurriculumOpen,
          screenListOpen
        )} ${
          isMobileDevice()
            ? "p-2"
            : courseCurriculumOpen
            ? "p-4 px-5"
            : "py-4 px-6"
        }  bg-light`}
        ref={containerRef}
      >
        <div className="d-flex flex-row justify-content-between align-items-center pt-2 mb-2 small">
          <ShareButtons />
          {isMobileDevice() ? null : (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <span
                className={`mr-2 cursor-pointer ${
                  (lesson && lesson.completed) ||
                  (challenge && challenge.completed)
                    ? "bg-disabled"
                    : ""
                }`}
                onClick={markAsComplete}
              >
                {(lesson && lesson.completed) ||
                (challenge && challenge.completed) ? (
                  <>
                    <i className="far fa-check-square"></i> Completed
                  </>
                ) : (
                  <>
                    <i className="far fa-square"></i> Mark As Completed
                  </>
                )}
              </span>

              <span
                className="mr-2 cursor-pointer"
                onClick={() => window.open(`/forum/threads/${tutorialSlug}`)}
              >
                <i className="fa fa-comments pr-1"></i> Discussion
              </span>
            </div>
          )}
        </div>
        {!(user && user.paid_active) && (
          <Ad kind="wide" />
          // <div id="ezoic-pub-ad-placeholder-129" className="w-100"></div>
        )}
        <div
          className="shadow bg-white"
          style={{ borderTop: "0.2rem rgb(240, 242, 247) solid" }}
        >
          <article
            className={
              isMobileDevice()
                ? "p-2"
                : courseCurriculumOpen && screenListOpen
                ? "p-4"
                : "p-5 px-6"
            }
          >
            {lesson && lesson.section && lesson.course ? (
              <div
                className="small mb-4"
                style={
                  isMobileDevice()
                    ? {}
                    : {
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }
                }
              >
                {user ? (
                  <Link to={`/dashboard`} style={{ whiteSpace: "nowrap" }}>
                    Home
                  </Link>
                ) : (
                  <a href="/">Home</a>
                )}{" "}
                >{" "}
                <Link
                  to={`/courses/${lesson.course.slug}`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {lesson.course.title || <Skeleton />}
                </Link>{" "}
                >{" "}
                <Link
                  to={`/sections/${lesson.section.slug}`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {lesson.section.title || <Skeleton />}
                </Link>{" "}
                >{" "}
                <Link to={`/lessons/${lesson.slug}`}>
                  <strong>{lesson.title || <Skeleton />}</strong>
                </Link>
              </div>
            ) : null}
            {isMobileDevice() ? (
              <h2 className="font-weight-bold">
                {lesson ? lesson.title : challenge.title}{" "}
                {challenge && challenge.difficulty && (
                  <span>({levelByDifficulty(challenge.difficulty)})</span>
                )}
              </h2>
            ) : null}

            <Switch>
              <Route
                path={[
                  "/lessons/:tutorialSlug/edit",
                  "/challenges/:tutorialSlug/edit",
                ]}
                exact
              >
                <TutorialEditor
                  screens={screens}
                  tutorialType={tutorialType}
                  tutorialId={lesson && lesson.id ? lesson.id : challenge.id}
                  tutorialSlug={tutorialSlug}
                />
              </Route>
              <Route
                path={[
                  "/lessons/:tutorialSlug/video",
                  "/challenges/:tutorialSlug/video",
                ]}
                exact
                render={() => {
                  return lesson && lesson.video ? (
                    <Video video={lesson.video} user={user} />
                  ) : (
                    <Video video={challenge.video} user={user} />
                  );
                }}
              ></Route>
              <Route
                path={[
                  "/lessons/:tutorialSlug/:language/:screenSlug",
                  "/lessons/:tutorialSlug/:screenSlug",
                  "/challenges/:tutorialSlug/:language/:screenSlug",
                  "/challenges/:tutorialSlug/:screenSlug",
                ]}
                exact
                render={(routeProps) => {
                  const languageParam = getUrlLanguage();

                  if (languageParam) {
                    // language in url
                    return (
                      <>
                        {/* Need video here in order to line up the regular /:tutorialSlug and language versions like /:tutorialSlug/java */}

                        {tutorialType === "lessons" &&
                        lesson &&
                        lesson.video ? (
                          <Video video={lesson.video} user={user} />
                        ) : null}

                        {isLoaded ? (
                          screens.map((screenEl, idx) => {
                            return (
                              <ScreenRenderer
                                companies={companies}
                                key={screenEl.slug}
                                randomAdScreen={randomAdScreen}
                                screen={screenEl}
                                screens={screens}
                              />
                            );
                          })
                        ) : (
                          <Skeleton count={40} />
                        )}
                      </>
                    );
                  }

                  return isLoaded ? (
                    <>
                      {visibleScreens.includes(screens[0]) ? null : (
                        <button
                          className="btn btm-sm w-100 bg-light rounded-lg p-2 mb-4 cursor-pointer"
                          onClick={() => {
                            setVisibleScreens(
                              [
                                screens[
                                  // use first visible screen to determine index
                                  visibleScreens[0].sequence - 2
                                ],
                              ].concat(visibleScreens)
                            );

                            setTimeout(() => {
                              $("html,body").animate({
                                scrollTop:
                                  $("#tutorial-container .progress").offset()
                                    .top - $("#algodaily-nav").height(),
                              });
                            }, 100);
                          }}
                        >
                          <p className="small text-center p-0 m-0">
                            <i className="fa fa-arrow-up"></i> Show previous
                            content
                          </p>
                        </button>
                      )}

                      {visibleScreens.length > 1 ? (
                        visibleScreens.map((s, idx) => (
                          <ScreenRenderer
                            companies={companies}
                            key={s.slug}
                            randomAdScreen={randomAdScreen}
                            screen={s}
                            screens={screens}
                          />
                        ))
                      ) : (
                        <ScreenRenderer
                          companies={companies}
                          key={screen.slug}
                          randomAdScreen={randomAdScreen}
                          screen={screen}
                          screens={screens}
                        />
                      )}

                      {visibleScreens.includes(
                        screens[screens.length - 1]
                      ) ? null : (
                        <button
                          className="btn btm-sm w-100 bg-light rounded-lg p-2 mb-4 cursor-pointer"
                          onClick={() => {
                            setVisibleScreens(
                              visibleScreens.concat(
                                screens[
                                  // use last visible screen to determine index
                                  visibleScreens[visibleScreens.length - 1]
                                    .sequence
                                ]
                              )
                            );

                            setTimeout(() => {
                              $("html,body").animate({
                                scrollTop:
                                  $(".screens-container").last().offset().top -
                                  $("#algodaily-nav").height(),
                              });
                            }, 100);
                          }}
                        >
                          <p className="small text-center p-0 m-0">
                            <i className="fa fa-arrow-down"></i> Show following
                            content
                          </p>
                        </button>
                      )}
                    </>
                  ) : (
                    <Skeleton count={40} />
                  );
                }}
              ></Route>
              <Route
                path={["/lessons/:tutorialSlug", "/challenges/:tutorialSlug"]}
                exact
              >
                {tutorialType === "lessons" && lesson && lesson.video ? (
                  <Video video={lesson.video} user={user} />
                ) : null}

                <div className="row">
                  <div className="w-100 bg-light rounded p-3 mx-2 mb-4 text-right">
                    {screens.some((s) => s.slug === "algodaily-cheatsheet") && (
                      <button
                        className="border-0 bg-transparent cursor-pointer tutorial-button"
                        // slightly expand onMouseEnter
                        // onMouseEnter={(e) => {
                        //   e.target.style.transform = "scale(1.01)";
                        //   // remove bg-gray, add bg-light class
                        //   e.target.classList.remove("bg-gray");
                        //   e.target.classList.add("bg-light");
                        // }}
                        // onMouseLeave={(e) => {
                        //   e.target.style.transform = "scale(1)";
                        //   // remove bg-light, add bg-gray class
                        //   e.target.classList.remove("bg-light");
                        //   e.target.classList.add("bg-gray");
                        // }}
                        onClick={() => {
                          // Find algodaily-cheatsheet element, scroll to it smoothly
                          const cheatsheet = document.getElementById(
                            "algodaily-cheatsheet"
                          );
                          window.scrollTo({
                            top: cheatsheet.offsetTop - 50,
                            behavior: "smooth",
                          });
                        }}
                        // medium bold font
                        style={{ fontWeight: 600 }}
                      >
                        <i class="fa fa-receipt text-ocean-blue mr-2"></i> One
                        pager cheat sheet
                      </button>
                    )}{" "}
                    {user && (
                      <button
                        className="border-0 bg-transparent cursor-pointer tutorial-button"
                        style={{ fontWeight: 600 }}
                        onClick={() => {
                          if (!user) {
                            return toastNotSignedIn("use the AI Chatbot Tutor");
                          }

                          if (window.cp?.visible) {
                            window.cp?.hide();
                          }

                          return history.push("/ai-tutor");
                        }}
                      >
                        <i class="fa fa-chalkboard-teacher text-ocean-blue mr-2"></i>
                        View AI Tutor history
                      </button>
                    )}
                  </div>
                </div>

                {isLoaded ? (
                  screens.map((screenEl, idx) => {
                    return (
                      <ScreenRenderer
                        companies={companies}
                        key={screenEl.slug}
                        randomAdScreen={randomAdScreen}
                        screen={screenEl}
                        screens={screens}
                      />
                    );
                  })
                ) : (
                  <Skeleton count={40} />
                )}
              </Route>
            </Switch>
          </article>
        </div>
        {!(user && user.paid_active) && (
          <Ad kind="wide" />
          // <div id="ezoic-pub-ad-placeholder-117" className="w-100"></div>
        )}

        <div
          className="d-flex justify-content-between"
          style={{
            zIndex: "1000",
          }}
        >
          {isMobileDevice() ? null : (
            <button
              className="btn bg-white shadow my-4 d-flex align-items-center"
              onClick={() => {
                if (courseMode === "crashcourse") {
                  const flattenedCurriculum = crashCourse.flat();
                  for (let i = 0; i < flattenedCurriculum.length; i++) {
                    if (flattenedCurriculum[i].slug === tutorialSlug) {
                      const prior = flattenedCurriculum[i - 1];
                      const priorTutorial = `/${prior.type}/${prior.slug}`;

                      return history.push(priorTutorial);
                    }
                  }

                  const first = flattenedCurriculum[0];
                  const firstTutorial = `/${first.type}/${first.slug}`;

                  return history.push(firstTutorial);
                }

                const tutorialType = beforeArr[0].toLowerCase() + "s";
                const beforeTutorialSlug = beforeArr[1];

                if (
                  (lesson && !lesson.completed) ||
                  (challenge && challenge.id && !challenge.completed)
                ) {
                  markAsComplete();
                }

                const beforeTutorial = `/${tutorialType}/${beforeTutorialSlug}`;

                history.push(beforeTutorial);
              }}
            >
              <i className="fa fa-backward pull-left"></i>
              <span
                style={{
                  paddingLeft: "15px",
                  paddingLight: "5px",
                  width: "100%",
                  display: "inline-block",
                  textAlign: "left",
                }}
              >
                {" "}
                Last Tutorial
                <br />
                <small
                  style={{
                    width: "100%",
                    display: "inline-block",
                    textAlign: "left",
                  }}
                >
                  Navigate to previous step
                </small>
              </span>
            </button>
          )}
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-sm my-4 d-flex align-items-center bg-info mr-2 shadow"
              onClick={() => {
                const options = ["random-challenge", "random-lesson"];
                window.open(
                  `/${options[Math.floor(Math.random() * options.length)]}`
                );
              }}
            >
              <i className="fa fa-2x fa-random text-white"></i>
            </button>
            <button
              className="btn bg-white shadow my-4 d-flex align-items-center"
              onClick={() => {
                if (courseMode === "crashcourse") {
                  const flattenedCurriculum = crashCourse.flat();
                  for (let i = 0; i < flattenedCurriculum.length; i++) {
                    if (flattenedCurriculum[i].slug === tutorialSlug) {
                      const next = flattenedCurriculum[i + 1];
                      const nextTutorial = `/${next.type}/${next.slug}`;

                      return history.push(nextTutorial);
                    }
                  }

                  const last =
                    flattenedCurriculum[flattenedCurriculum.length - 1];
                  const lastTutorial = `/${last.type}/${last.slug}`;

                  return history.push(lastTutorial);
                }

                const tutorialType = nextArr[0].toLowerCase() + "s";
                const nextTutorialSlug = nextArr[1];

                if (
                  (lesson && !lesson.completed) ||
                  (challenge && challenge.id && !challenge.completed)
                ) {
                  markAsComplete();
                }

                const nextTutorial = `/${tutorialType}/${nextTutorialSlug}`;

                history.push(nextTutorial);
              }}
            >
              <i className="fa fa-forward pull-left"></i>
              <span
                style={{
                  paddingLeft: "15px",
                  paddingLight: "5px",
                  width: "100%",
                  display: "inline-block",
                  textAlign: "left",
                }}
              >
                {" "}
                Next Tutorial
                <br />
                <small
                  style={{
                    width: "100%",
                    display: "inline-block",
                    textAlign: "left",
                  }}
                >
                  Navigate to next step
                </small>
              </span>
            </button>
          </div>
        </div>

        <FooterLinks />
      </main>
      {screenListOpen ? (
        <TutorialMenu
          challenge={challenge}
          notes={notes}
          screen={screen}
          screens={screens}
          screenCompletions={screenCompletions}
          tutorialType={tutorialType}
          tutorialSlug={tutorialSlug}
          user={user}
        />
      ) : null}
    </>
  );
};

export default Tutorial;
