import { Link, useHistory } from "react-router-dom";

import React from "react";

export default (props) => {
  let history = useHistory();

  const {
    screen,
    screenCompletions,
    screenSlugs,
    screensLength,
    toggleScreenList,
    tutorialType,
    tutorialSlug,
  } = props;

  const screenIsCompleted = screenCompletions[screen.sequence - 1];

  // In case there are gaps
  const screenSlugIdx = screenSlugs.findIndex((s) => screen.slug === s);
  const getLastScreenSlug = () => {
    for (let i = screenSlugIdx - 1; i >= 0; i--) {
      if (screenSlugs[i]) {
        return screenSlugs[i];
      }
    }
  };

  const getNextScreenSlug = () => {
    for (let i = screenSlugIdx + 1; i <= screenSlugs.length - 1; i++) {
      if (screenSlugs[i]) {
        return screenSlugs[i];
      }
    }
  };

  return (
    <nav
      id="algodaily-footer"
      className="challenge-footer navbar fixed-bottom bg-dark d-flex justify-content-between p-2"
    >
      <div className="col-lg-4 col-8 d-none d-lg-block">
        <span onClick={toggleScreenList} className="cursor-pointer">
          <strong>
            <i className="fa fa-bars"></i>{" "}
            {screenIsCompleted ? (
              <s>
                {screen.sequence}. {screen.title}{" "}
                <i className="fa fa-check"></i>
              </s>
            ) : (
              `${screen.sequence}. ${screen.title}`
            )}
          </strong>
        </span>
      </div>
      <div className="col-lg-4 col-sm-12 d-flex justify-content-center align-items-center">
        {screen.sequence > 1 ? (
          <Link
            to={`/${
              tutorialType === "lessons" ? "lesson_slides" : "challenge_slides"
            }/${tutorialSlug}/${getLastScreenSlug()}`}
          >
            <button className="genric-btn info mx-2 border-0">BACK</button>
          </Link>
        ) : (
          <button
            className="genric-btn bg-secondary mx-2 border-0"
            onClick={() => history.goBack()}
          >
            BACK
          </button>
        )}
        <span className="mx-2" style={{ whiteSpace: "nowrap" }}>
          <strong>
            {screen.sequence} / {screensLength}
          </strong>
        </span>
        {screen.sequence < screensLength ? (
          getNextScreenSlug() === "locked" ? (
            <a href="/subscriptions/discounted?locked=true">
              <button className="genric-btn info mx-2 border-0">NEXT</button>
            </a>
          ) : (
            <Link
              to={`/${
                tutorialType === "lessons"
                  ? "lesson_slides"
                  : "challenge_slides"
              }/${tutorialSlug}/${getNextScreenSlug()}`}
            >
              <button className="genric-btn info mx-2 border-0">NEXT</button>
            </Link>
          )
        ) : (
          <button
            className="genric-btn info mx-2 border-0"
            onClick={props.showTestsPassedModal}
          >
            COMPLETE
          </button>
        )}
      </div>
      <div className="col-lg-4 d-none d-lg-block text-right">
        <button
          className="genric-btn mx-2 border-0 bg-gray"
          onClick={() =>
            history.push(`/${tutorialType}/${tutorialSlug}?view=article`)
          }
        >
          <i className="fa fa-book text-ocean-blue pull-left"></i> ARTICLE MODE
        </button>
      </div>
    </nav>
  );
};
