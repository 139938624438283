import React from "react";

const FREE_VIDS = [
  "https://player.vimeo.com/video/481214424", // Reverse an Arr
  "https://player.vimeo.com/video/481214039", // Array Intersection
  "https://player.vimeo.com/video/481214146", // Fizz Buzz
  "https://player.vimeo.com/video/495586455", // How to Prepare
  "https://player.vimeo.com/video/494509789", // Cycle Sort
  "https://player.vimeo.com/video/495589285", // Implementation Graphs
  "https://player.vimeo.com/video/481214194", // Hash Map for SEO
  "https://player.vimeo.com/video/498905000", // Data Structures Cheat Sheet
];

export default function (props) {
  const { video, user } = props;

  if (!video) {
    return null;
  }

  // TODO: check that Youtube can allow for fullscreen
  return video.indexOf("vimeo") > -1 ? (
    FREE_VIDS.includes(video) || (user && user.paid_active) ? (
      <div className="embed-container shadow">
        <iframe
          src={video}
          width="100%"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
    ) : (
      <a href="/subscriptions/new" target="_blank">
        <img
          src="https://storage.googleapis.com/algodailyrandomassets/videolock.jpg"
          className="shadow img-fluid mb-4"
        ></img>
      </a>
    )
  ) : (
    <div className="embed-container shadow">
      <iframe
        src={video}
        frameborder="0"
        allowfullscreen
        allow="fullscreen;"
      ></iframe>
    </div>
  );
}
