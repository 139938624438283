import {
  LANG_TO_CM_MODE_MAP,
  MD_MODE_TO_LANG_MAP,
  commentsMap,
  determineCanonicalUrl,
  extractCodeFromMd,
  extractLangFromCodeBlock,
  loadLanguageUtils,
  readjustForSlides,
  toastNotSignedIn,
} from "../helpers/utils";
import React, { Component } from "react";

import CodeExecutor from "../Progression/CodeExecutor";
import { UnControlled as CodeMirror } from "react-codemirror2";
import OutputContainer from "../OutputContainer";
import Toolbar from "./Toolbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

require("codemirror/keymap/sublime");
require("codemirror/addon/comment/comment");
require("codemirror/addon/lint/lint");
require("codemirror/addon/hint/show-hint.css");
require("codemirror/addon/hint/show-hint");

class MyCodeTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.downloadFile = this.downloadFile.bind(this);
    this.revealSolution = this.revealSolution.bind(this);
    this.resetCode = this.resetCode.bind(this);

    this.state = { editorVal: "", resetVal: "", showResetWarning: false };

    this.currLocation = window.location;
    this.collaborateMode = this.currLocation.pathname.includes("collaborate");
    this.outNodeRef = React.createRef();

    this.challenge = this.props.challenge;
    this.language = this.props.language;
    this.user = this.props.user;
  }

  componentDidMount() {
    this.loadEditorVal();
    const self = this;
    self.editor.on("keypress", function (cm, event) {
      var inp = String.fromCharCode(event.keyCode);
      if (/[a-zA-Z0-9-_ ]/.test(inp)) {
        self.editor.execCommand("autocomplete");
      }
    });
    loadLanguageUtils(this.language);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.language !== nextProps.language ||
      this.challenge !== nextProps.challenge ||
      this.user !== nextProps.user ||
      this.props.codeFromLocalStorage !== nextProps.codeFromLocalStorage ||
      this.challenge.language_content_id !==
        nextProps.challenge.language_content_id ||
      this.state.resetVal !== nextState.resetVal ||
      this.state.showResetWarning !== nextState.showResetWarning
    );
  }

  componentDidUpdate(prevProps) {
    // TODO: convert to functional component
    // These go here and not the constructor because the context is what prompts the change
    this.language = this.props.language;
    this.challenge = this.props.challenge;
    this.user = this.props.user;

    if (prevProps.codeFromLocalStorage !== this.props.codeFromLocalStorage) {
      this.loadEditorVal();
    } else if (
      // need to account for change in language
      prevProps.challenge.language_content_id !==
        this.challenge.language_content_id ||
      prevProps.language !== this.language
    ) {
      const editorVal = `${
        this.challenge.initialCode
          ? extractCodeFromMd(this.challenge.initialCode)
          : "Write code here."
      }\n`;

      this.editor.setValue(editorVal);
      this.setState({ editorVal: editorVal });
    }

    loadLanguageUtils(this.language);
  }

  updateCodeInput(challenge, val, language) {
    this.setState({ editorVal: val });

    if (challenge && localStorage) {
      // there is a point where props.language has been updated but props.challenge hasn't...
      let lang = extractLangFromCodeBlock(challenge.initialCode);
      lang = MD_MODE_TO_LANG_MAP[lang];

      localStorage.setItem(
        `challenge-${challenge.slug}-entry-${lang || language}`,
        val
      );
    }
  }

  downloadFile(data, filename, type) {
    const extensionMap = {
      javascript: "js",
      python: "py",
      java: "java",
      go: "go",
      ruby: "rb",
      csharp: "cs",
      cpp: "cpp",
      php: "php",
    };

    if (!data) {
      data = this.state.editorVal;
    }

    if (!filename) {
      filename = this.challenge.slug + "." + extensionMap[this.language];
    }

    data =
      commentsMap[this.language] +
      " This is my solution for AlgoDaily problem " +
      this.challenge.title +
      "\n" +
      commentsMap[this.language] +
      " Located at https://algodaily.com/challenges/" +
      this.challenge.slug +
      "\n\n" +
      data;

    var file = new Blob([data], { type: type });
    if (window.navigator.msSaveOrOpenBlob)
      // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename);
    else {
      // Others
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  }

  async revealSolution() {
    let solutions = "";

    await fetch(
      `/challenges/${this.challenge.slug}/${this.language}/solutions`,
      {
        method: "GET",
      }
    )
      .then(function (response) {
        return response.text();
      })
      .then(function (data) {
        solutions = data;
      });

    this.setState({
      editorVal: `${
        this.language == "js" ? "//" : "#"
      } ALGODAILY SOLUTION:\n\n${extractCodeFromMd(
        this.challenge.solutionCode
      )}\n\n${solutions}`,
    });
  }

  resetCode() {
    this.editor.setValue(extractCodeFromMd(this.challenge.initialCode));
    this.setState({
      showResetWarning: false,
      // resetVal: extractCodeFromMd(this.challenge.initialCode),
    });
  }

  loadEditorVal() {
    // If there's editorVal, it means there's been recorded changes
    // when reloaded due to adding a completion, this prevents the test
    // suite from reloading initialCode.
    let editorVal = "";
    if (this.state.resetVal) {
      // 1. Resetted code
      editorVal = this.state.resetVal;
    } else if (this.props.codeFromLocalStorage) {
      // 2. Code from local storage
      editorVal = this.props.codeFromLocalStorage;
    } else {
      // 3. Initial code setup
      editorVal = `${
        this.challenge.initialCode
          ? extractCodeFromMd(this.challenge.initialCode)
          : "Write code here."
      }\n`;
    }

    this.editor.setValue(editorVal);
    this.updateCodeInput(this.challenge, editorVal, this.language);
  }

  render() {
    const runCode = () => {
      if (!this.user) {
        return toastNotSignedIn("run code on the site");
      }

      const codeExecutor = new CodeExecutor({
        val: this.editor.getValue(),
        language: this.language,
        isTestSubmission: true,
        outNodeRef:
          this.outNodeRef.current ||
          document.getElementById("curr-output-container"),
      });
      codeExecutor.runCode();
    };

    var options = {
      autoCloseBrackets: true,
      lineNumbers: true,
      mode: {
        name: LANG_TO_CM_MODE_MAP[this.language],
        globalVars: true,
      },
      theme: "elegant",
      gutters: ["CodeMirror-lint-markers"],
      lint: this.language === "javascript" ? { esversion: "8" } : {},
      readOnly: false,
      keyMap: "sublime",
      tabSize: 2,
      extraKeys: {
        "Ctrl-Enter": runCode,
        "Cmd-Enter": runCode,
        "Cmd-/": "toggleComment",
        "Ctrl-/": "toggleComment",
        Tab: (cm) => {
          if (cm.getMode().name === "null") {
            cm.execCommand("insertTab");
          } else {
            if (cm.somethingSelected()) {
              cm.execCommand("indentMore");
            } else {
              cm.execCommand("insertSoftTab");
            }
          }
        },
        "Shift-Tab": (cm) => cm.execCommand("indentLess"),
      },
      hintOptions: { completeSingle: false },
    };

    return (
      <>
        <Toolbar
          changeLanguage={(newLang) => {
            const canonical = determineCanonicalUrl(newLang, this.challenge);
            return this.props.history.push(canonical);
          }}
          code={this.editor ? this.editor.getValue() : ""}
          downloadFile={this.downloadFile}
          editorVal={this.state.editorVal}
          language={this.language}
          resetCode={() => this.setState({ showResetWarning: true })}
          runCode={runCode}
          type="mycode"
          user={this.props.user}
        />
        {this.state.showResetWarning ? (
          <div className="modal d-block" tabIndex="-1" role="dialog">
            <div className="modal-dialog shadow" role="document">
              <div
                className="modal-content text-white p-2 shadow"
                style={{ backgroundColor: "#2d2d2d" }}
              >
                <div className="modal-header">
                  <h4 className="modal-title m-auto">Are you sure?</h4>
                </div>
                <div className="modal-body">
                  <p>
                    This will wipe out your code and replace it with the initial
                    function signature.
                  </p>
                  <div className="modal-footer d-flex justify-content-between">
                    <button
                      type="button"
                      className="genric-btn info my-2"
                      onClick={() => this.setState({ showResetWarning: false })}
                    >
                      BACK
                    </button>
                    <button
                      type="button"
                      className="genric-btn info my-2"
                      onClick={this.resetCode}
                    >
                      CONFIRM RESET
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {CodeMirror && (
          <CodeMirror
            value={this.state.editorVal}
            autoCursor={false}
            autoScroll={false}
            onChange={
              this.collaborateMode
                ? (editor, op, value) => {
                    this.updateCodeInput(this.challenge, value, this.language);
                    console.log("emitting change");
                    if (
                      op.origin == "+input" ||
                      op.origin == "paste" ||
                      op.origin == "+delete"
                    ) {
                      window.onEditChange(
                        this.props.user.id,
                        op,
                        editor.getValue()
                      );
                    }
                  }
                : (editor, op, value) => {
                    this.updateCodeInput(this.challenge, value, this.language);
                  }
            }
            editorDidMount={(editor) => {
              this.editor = editor;
              readjustForSlides();
            }}
            options={options}
          />
        )}
        <OutputContainer ref={this.outNodeRef} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  challenge: state.challenge,
  language: state.language,
  user: state.user,
});
export default withRouter(connect(mapStateToProps)(MyCodeTab));
