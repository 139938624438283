import "codemirror/lib/codemirror.css";

import App from "./App";
import AppProvider from "./AppProvider";
import ChallengeIndex from "./ChallengeIndex";
import CodeConverter from "./CodeConverter";
import CodeSnippet from "./CodeSnippet";
import Content from "./Content";
import CourseCurriculum from "./CourseCurriculum";
import FooterLinks from "./FooterLinks";
import ShareButtons from "./ShareButtons";
import Turbolinks from "turbolinks";
import UserCourseSendSelector from "./UserCourseSendSelector";
import WebpackerReact from "webpacker-react";

document.addEventListener("turbolinks:load", () => {
  Turbolinks.start();
  WebpackerReact.setup({
    App,
    AppProvider,
    ChallengeIndex,
    CodeConverter,
    Content,
    CourseCurriculum,
    CodeSnippet,
    FooterLinks,
    ShareButtons,
    UserCourseSendSelector,
  });
});
