import React, { Component } from "react";
import { deleteScreenCompletion, recordAnswer } from "./helpers/completions";
import { getFormattedDate, toastNotSignedIn } from "./helpers/utils";

import CodeSnippet from "./CodeSnippet";
import ReactMarkdown from "react-markdown";
import { connect } from "react-redux";

class Order extends Component {
  constructor(props, context) {
    super(props, context);

    const screen = this.props.screen;

    this.state = {
      screen: screen,
      revealAnswer: screen.screenCompletion,
      options: screen.options,
      userAnswer: screen.screenCompletion
        ? JSON.parse(screen.screenCompletion.answer)
        : [],
      currentPointer: 0,
      isCorrect: screen.screenCompletion && screen.screenCompletion.is_correct,
    };

    this.toggleReveal = this.toggleReveal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.screen !== this.props.screen) {
      const screen = this.props.screen;
      this.setState({
        screen: screen,
        revealAnswer: screen.screenCompletion,
        userAnswer: screen.screenCompletion
          ? screen.screenCompletion.answer
          : null,
        isCorrect:
          screen.screenCompletion && screen.screenCompletion.is_correct,
      });
    }
  }

  toggleReveal() {
    this.setState({ revealAnswer: !this.state.revealAnswer });
  }

  checkAnswer(actualSolution, userSolution) {
    const answer = JSON.stringify(userSolution);
    const isCorrect = JSON.stringify(actualSolution) == answer;

    if (isCorrect) {
      recordAnswer(true, this.props.screen, answer, (savedSC) => {
        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: userSolution,
        });
      });
    } else {
      recordAnswer(false, this.props.screen, answer, (savedSC) => {
        this.toggleReveal();

        this.setState({
          screen: { ...this.props.screen, screenCompletion: savedSC },
          revealAnswer: true,
          isCorrect: isCorrect,
          userAnswer: userSolution,
        });
      });
    }
  }

  updateViewAndUpdateSolution(originalIdx) {
    if (this.state.revealAnswer) {
      return;
    }

    if (!this.props.user) {
      return toastNotSignedIn("track work on this exercise");
    }

    // undo
    if (
      this.state.userAnswer[originalIdx] ||
      this.state.userAnswer[originalIdx] === 0
    ) {
      // can only select the last one
      // originalIdx starts at 0, currentPointer is 1 ahead

      // if the previously selected position(+1) does not equal the current pointer
      // meaning it is not the last selection
      if (
        this.state.userAnswer[originalIdx] + 1 !==
        this.state.currentPointer
      ) {
        return;
      }

      // if selected, unselect
      delete this.state.userAnswer[originalIdx];
      this.setState({ currentPointer: this.state.currentPointer - 1 });
    } else {
      // set order
      this.state.userAnswer[originalIdx] = this.state.currentPointer;
      this.setState({ currentPointer: this.state.currentPointer + 1 });
    }

    if (
      this.state.userAnswer.filter((x) => x !== undefined).length ===
      this.state.options.length
    ) {
      const parsedScreenSolution = JSON.parse(this.state.screen.solution);
      this.checkAnswer(parsedScreenSolution, this.state.userAnswer);
    }
  }

  render() {
    const screen = this.state.screen;
    const isSlides = this.props.isSlides;

    const renderers = {
      code: (pr) => <CodeSnippet languageCode={pr.language} code={pr.value} />,
    };

    const parsedScreenSolution = JSON.parse(screen.solution);

    let correctOrder;
    if (this.state.revealAnswer) {
      let properOrderStr = [];
      for (let i = 0; i < parsedScreenSolution.length; i++) {
        const correctIdx = parsedScreenSolution[i];
        properOrderStr[correctIdx] = screen.options[i];
      }

      correctOrder = (
        <div>
          <p>Here's the correct order:</p>
          <ol className="list-group">
            {properOrderStr.map((orderedAnswer, mapIdx) => {
              return (
                <li
                  className="list-group-item border bg-info mb-3 text-white"
                  key={orderedAnswer.toString().substr(0, 20)}
                >
                  {mapIdx + 1}. {orderedAnswer}
                </li>
              );
            })}
          </ol>
        </div>
      );
    } else {
      correctOrder = null;
    }

    const optionList = this.state.options.map((text, originalIdx) => {
      const parsedUserAnswer = this.state.userAnswer;
      const title =
        parsedUserAnswer[originalIdx] || parsedUserAnswer[originalIdx] == 0
          ? `${parsedUserAnswer[originalIdx] + 1}. ${text}`
          : text;

      return (
        <li
          key={originalIdx}
          className="list-group-item border bg-info mb-3 text-white"
          onClick={() => this.updateViewAndUpdateSolution(originalIdx)}
        >
          {title}
        </li>
      );
    });

    return (
      <div
        className={`screen ${
          isSlides ? "py-5 px-4" : "p-4"
        } border-0 h-100 overflow-auto`}
      >
        <p>
          Press the below buttons in the order in which they should occur. Click
          on them again to un-select.
        </p>
        {screen.code ? (
          <ReactMarkdown
            source={"```\n" + screen.code + "\n```"}
            renderers={renderers}
          />
        ) : null}
        <h4>Options:</h4>
        <ul className="list-group">{screen.options ? optionList : null}</ul>
        {this.state.revealAnswer ? (
          <>
            <p>
              <strong>
                <span>
                  {this.state.isCorrect && (
                    <span className="text-success">Correct! </span>
                  )}
                  {!this.state.isCorrect && (
                    <span className="text-danger">Sorry, that's wrong. </span>
                  )}
                </span>
              </strong>
            </p>
            {screen.explanation && (
              <div className="w-100 mb-4">
                <h4>Explanation</h4>
                <ReactMarkdown source={screen.explanation} />
              </div>
            )}
          </>
        ) : null}
        {correctOrder}
        {screen.screenCompletion ? (
          <small>
            Answer saved on{" "}
            {getFormattedDate(new Date(screen.screenCompletion.created_at))}.{" "}
            <em
              className="strong cursor-pointer text-ocean-blue"
              onClick={() =>
                deleteScreenCompletion(screen.screenCompletion.id, () => {
                  const { screenCompletion, ...screenWithoutSC } = screen;
                  return this.setState({
                    screen: screenWithoutSC,
                    revealAnswer: false,
                    userAnswer: [],
                    isCorrect: null,
                  });
                })
              }
            >
              Delete answer and reset?
            </em>
          </small>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps)(Order);
