import {
  ALGO_LANG_ID_TO_JUDGE_MAP,
  MD_MODE_TO_LANG_MAP,
  isMobileDevice,
} from "../helpers/utils";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import OutsideClickHandler from "react-outside-click-handler";
import { actions } from "../redux/StateReducer";
import { useHistory } from "react-router-dom";

const Toolbar = (props) => {
  const dispatch = useDispatch();
  const thisToolbar = useRef();
  const history = useHistory();
  const { downloadFile, type } = props;

  const challenge = useSelector((state) => state.challenge);
  // If it's a lesson/code snippet, we use the language derived from the snippet. Otherwise, if it's a my code/editor toolbar, we want to use the user chosen language from the store
  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );

  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);

  const [showLangDropdown, setShowLangDropdown] = React.useState(false);

  const toolPadding = isMobileDevice() ? "px-2" : "px-3";

  const languagesSupported = props.languagesSupported ||
    challenge?.languages_supported || [34, 29, 26, 22, 38, 10, 16];

  const changeLangFunc = props.changeLanguage;

  const language =
    type === "lesson"
      ? props.language || "text"
      : useSelector((state) => state.language);

  const convertLink = () => {
    console.log(props.code);
    return `/convert/${language}/python?input=${btoa(props.code)}`;
  };

  return (
    <>
      <div
        id="toolbar"
        className={`toolbar clear editor-btn-group ${
          type === "lesson" ? "bg-aux" : "bg-my-code"
        } d-flex w-100 justify-content-between`}
        ref={thisToolbar}
      >
        <div className="d-flex">
          <a
            id="run-code-button"
            className={`editor-btn py-2 ${toolPadding} cursor-pointer ${
              type === "lesson" ? "bg-aux" : "bg-my-code"
            } font-weight-bold d-flex align-items-center small d-md-block d-lg-block`}
            data-tip="Cmd/Ctrl-Enter to run, Cmd/Ctrl-/ to comment"
            onClick={() => {
              if (
                window &&
                window.onToolPress &&
                user &&
                document.getElementById("local-video")
              ) {
                window.onToolPress(user.id, "run-code-button");
              }
              props.runCode();
            }}
          >
            <i className="fa fa-play mr-2"></i> RUN CODE
          </a>

          <a
            className={`editor-btn py-2 ${toolPadding} cursor-pointer font-weight-bold d-flex align-items-center small`}
            onClick={props.resetCode}
          >
            {isMobileDevice() ? (
              <span className="fa fa-eraser" alt="Reset code" />
            ) : (
              "RESET"
            )}
          </a>
          {downloadFile ? (
            <a
              className={`editor-btn py-2 ${toolPadding} cursor-pointer font-weight-bold d-flex align-items-center small ml-2 d-none d-sm-none d-md-block d-lg-block`}
              onClick={(e) => downloadFile()}
            >
              <i className="fa fa-save" /> SAVE
            </a>
          ) : null}
          {!isMobileDevice() && courseCurriculumOpen && screenListOpen ? (
            <a
              className={`editor-btn py-2 ${toolPadding} cursor-pointer font-weight-bold d-flex align-items-center small ml-2 d-none d-sm-none d-md-block d-lg-block`}
              onClick={(e) => {
                dispatch({
                  type: actions.SET_COURSE_CURRICULUM_OPEN,
                  payload: false,
                });
                dispatch({
                  type: actions.SET_SCREEN_LIST_OPEN,
                  payload: false,
                });

                // setTimeout(() => {
                //   if (thisToolbar && thisToolbar.current) {
                //     const navHeight = document.getElementById("algodaily-nav")
                //       ?.clientHeight;

                //     const scrollTop =
                //       window.pageYOffset +
                //       thisToolbar.current.getBoundingClientRect().top +
                //       navHeight +
                //       100;

                //     $(window).scrollTop(scrollTop);
                //   }
                // }, 200);
              }}
            >
              <span className="fa fa-expand" />
            </a>
          ) : null}
        </div>
        <div className="btn-group">
          <a
            className={`editor-btn py-2 ${toolPadding} cursor-pointer font-weight-bold d-flex align-items-center small ml-2 d-none d-sm-none d-md-block d-lg-block`}
            onClick={() => window.open(convertLink())}
            target="_blank"
          >
            <i class="fa fa-globe"></i>
          </a>
          <span
            className={`language py-2 ${toolPadding} cursor-pointer font-weight-bold d-flex align-items-center small`}
            onClick={() => setShowLangDropdown(!showLangDropdown)}
          >
            {language.toUpperCase()}
            <i className="fa fa-caret-square-down ml-2"></i>
          </span>

          <OutsideClickHandler
            onOutsideClick={() => {
              setShowLangDropdown(false);
            }}
          >
            <div
              className={`dropdown-menu dropdown-menu-right ${
                showLangDropdown ? "d-block" : ""
              } editor-btn border-secondary`}
            >
              {languagesSupported &&
                languagesSupported.sort().map((idOrLangStr) => {
                  const currLanguage = isNaN(idOrLangStr)
                    ? MD_MODE_TO_LANG_MAP[idOrLangStr?.toLowerCase()]
                    : ALGO_LANG_ID_TO_JUDGE_MAP[idOrLangStr];
                  return (
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        // Reverting
                        if (
                          currLanguage.toLowerCase() === language.toLowerCase()
                        ) {
                          props.resetCode();
                        } else {
                          changeLangFunc(currLanguage);
                        }

                        setShowLangDropdown(!showLangDropdown);
                      }}
                      key={currLanguage}
                    >
                      {currLanguage.toUpperCase()}
                    </button>
                  );
                })}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    </>
  );
};

export default Toolbar;
