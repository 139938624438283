import "react-mde/lib/styles/css/react-mde-all.css";

import {
  ALGO_LANG_ID_TO_JUDGE_MAP,
  LANG_TO_ALGODAILY_ID,
  strToSlug,
} from "./helpers/utils";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { forwardRef } from "react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { CSS } from "@dnd-kit/utilities";
import OutsideClickHandler from "react-outside-click-handler";
import ReactMarkdown from "react-markdown/with-html";
import ReactMde from "react-mde";
import ScreenRenderer from "./ScreenRenderer";
import { actions } from "./redux/StateReducer";
import { promptRenderer } from "./helpers/markdown";
import remarkDisableTokenizers from "remark-disable-tokenizers";

export default function (props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const currLocation = useLocation();

  const user = useSelector((state) => state.user);

  if (!(user && user.admin)) {
    history.push(currLocation.pathname.replace("/edit", ""));
  }

  const { screens, tutorialType, tutorialId, tutorialSlug } = props;

  const [editedScreens, setEditedScreens] = React.useState(screens);

  const [openedScreens, setOpenedScreens] = React.useState(
    screens.reduce((prev, curr) => {
      prev[curr.slug] = true;
      return prev;
    }, {})
  );

  const [activeId, setActiveId] = React.useState(null);
  const [editorOpenList, setEditorOpenList] = React.useState(
    screens.reduce((a, v) => ({ ...a, [v.slug]: false }), {})
  );
  const [languageContentsOpen, setLanguageContentsOpen] = React.useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const saveScreen = (editedScreen, callback) => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    if (user && user.admin) {
      // Creation
      // edge case bc we need to save solution string as array
      if (
        ["order"].includes(editedScreen.kind) &&
        editedScreen.solution.includes(",")
      ) {
        try {
          const parsedArr = JSON.parse(editedScreen.solution);
          if (Array.isArray(editedScreen.solution)) {
            editedScreen.solution = parsedArr;
          }
        } catch {
          editedScreen.solution = editedScreen.solution
            .split(",")
            .map((el) => (isNaN(el) ? el : JSON.parse(el)));
        }
      }

      if (editedScreen.kind === "info screen" && !editedScreen.code?.trim()) {
        editedScreen.full_screen = true;
      }

      if (editedScreen.isNew) {
        if (editedScreen.title === "New Screen") {
          return window.alert("Please change the title of the new screen");
        }

        delete editedScreen.id;

        fetch("/api/screens", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
          body: JSON.stringify(editedScreen),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
          })
          .then((newScreen) => {
            callback(newScreen);
          });
      } else {
        // Update
        fetch(`/api/screens/${editedScreen.id}`, {
          method: "put",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": token,
          },
          body: JSON.stringify(editedScreen),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
          })
          .then(callback, (error) => {
            console.log(error);
          });
      }
    }
  };

  function handleDragStart(event) {
    const { active } = event;
    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const items = editedScreens.filter((s) => !s.isNew).map((s) => s.slug);

      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      // Not a swap!
      const screenSlugsOrdered = arrayMove(items, oldIndex, newIndex);

      const screensOrdered = screenSlugsOrdered.map((slug, idx) => {
        return {
          ...editedScreens.find((s) => slug === s.slug),
          sequence: idx + 1,
        };
      });

      screensOrdered.forEach((s) => {
        saveScreen({
          id: s.id,
          sequence: s.sequence,
        });
      });

      setEditedScreens(screensOrdered);
    }
  }

  React.useEffect(() => {
    dispatch({
      type: actions.SET_SCREEN_LIST_OPEN,
      payload: false,
    });
  }, []);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <button
        className="btn btm-sm w-100 bg-light rounded-lg p-2 mb-4 cursor-pointer"
        onClick={() => {
          window.scrollTo(0, 0);
          window.location.replace(
            `/admin/${tutorialType.slice(0, -1)}/${tutorialId}/edit`
          );
        }}
      >
        <p className="small text-center p-0 m-0">
          <i className="fa fa-eye"></i> Edit meta attributes
        </p>
      </button>
      {tutorialType === "challenges" ? (
        <>
          <button
            className="btn btm-sm w-100 bg-light rounded-lg p-2 mb-4 cursor-pointer"
            onClick={() => {
              setLanguageContentsOpen(!languageContentsOpen);
            }}
          >
            <p className="small text-center p-0 m-0">
              <i className="fa fa-code"></i> Edit language contents
            </p>
          </button>
          {languageContentsOpen ? (
            <LanguageContentsEditor
              tutorialId={tutorialId}
              tutorialSlug={tutorialSlug}
            />
          ) : null}
        </>
      ) : null}
      <SortableContext
        items={editedScreens}
        strategy={verticalListSortingStrategy}
      >
        {editedScreens.map((screen) => (
          <>
            {/* ScreenRenderer is within ScreenEditor */}
            <ScreenEditor
              changeLanguage={props.changeLanguage}
              companies={props.companies}
              randomAdScreen={props.randomAdScreen}
              runCode={props.runCode}
              setNotes={props.setNotes}
              // Everything above this might not be necessary once Context API is in
              key={screen.slug}
              editedScreens={editedScreens}
              saveScreen={saveScreen}
              screen={screen}
              setEditedScreens={setEditedScreens}
              setOpenedScreens={setOpenedScreens}
              openedScreens={openedScreens}
              editorOpenList={editorOpenList}
              setEditorOpenList={setEditorOpenList}
              tutorialSlug={tutorialSlug}
              tutorialType={tutorialType}
            />
            <BreakTrigger
              user={user}
              editedScreens={editedScreens}
              setEditedScreens={setEditedScreens}
              screenBefore={screen}
              tutorialId={tutorialId}
              tutorialType={tutorialType}
            />
          </>
        ))}
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <Item id={activeId} screens={screens} setActiveId={setActiveId} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}

const ScreenEditor = (props) => {
  const {
    screen,
    saveScreen,
    editedScreens,
    setEditedScreens,
    key,
    editorOpenList,
    setEditorOpenList,
  } = props;

  const [editedScreen, setEditedScreen] = React.useState(screen);
  const [saveSuccessful, setSaveSuccessful] = React.useState(false);

  const [editorOpened, setEditorOpened] = React.useState("content");
  const [selectedTab, setSelectedTab] = React.useState("write");

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: screen.slug });

  const style = {
    transform: CSS.Transform.toString(transform),
    cursor: "grab",
    transition,
  };

  React.useEffect(() => {
    setEditedScreen(screen);
    if (screen.justSaved) {
      setSaveSuccessful(true);
    }
  }, [screen, screen.slug, screen.sequence]);

  React.useEffect(() => {
    $("textarea.mde-text, textarea.screen-textarea")
      .each(function () {
        this.setAttribute(
          "style",
          "height:" + this.scrollHeight + "px;overflow-y:hidden;"
        );
      })
      .on("input", function () {
        this.style.height = "auto";
        this.style.height = this.scrollHeight + "px";
      });
  });

  const normalizedSlug = editedScreen.slug.replace(/[^a-zA-Z0-9_-]/g, "");

  return editorOpenList[screen.slug] ? (
    <OutsideClickHandler
      onOutsideClick={() => {
        const newEditorOpenList = { ...editorOpenList };
        newEditorOpenList[screen.slug] = !editorOpenList[screen.slug];
        setEditorOpenList(newEditorOpenList);
      }}
    >
      <form id={normalizedSlug} className="card">
        <div
          className="card-header"
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          key={key}
        >
          <i className="fa fa-grip-horizontal" /> Screen {editedScreen.sequence}
          : {editedScreen.title}
        </div>
        <div className="card-body">
          <div className="row">
            <div class="col-lg-3">
              <select
                type="text"
                class="form-control"
                placeholder="Kind"
                onChange={(e) => {
                  if (saveSuccessful) {
                    setSaveSuccessful(false);
                  }

                  const randHash = (Math.random() + 1)
                    .toString(36)
                    .substring(7);

                  let questionTitle = "";
                  let questionSlug = "";
                  switch (e.currentTarget.value) {
                    case "multiple choice":
                      questionTitle = "Multiple Choice";
                      questionSlug = `multiple-choice-${randHash}`;
                      break;
                    case "fill in":
                      questionTitle = "Fill In the Blank";
                      questionSlug = `fill-in-${randHash}`;
                      break;
                    case "order":
                      questionTitle = "Order the Steps";
                      questionSlug = `order-${randHash}`;
                      break;
                    case "swipe":
                      questionTitle = "True or False?";
                      questionSlug = `swipe-${randHash}`;
                      break;
                    default:
                      break;
                  }

                  setEditedScreen({
                    ...editedScreen,
                    ...{ kind: e.currentTarget.value },
                    ...{
                      full_screen: ![
                        "multiple choice",
                        "fill in",
                        "order",
                        "swipe",
                      ].includes(e.currentTarget.value),
                    },
                    solution:
                      e.currentTarget.value === "swipe"
                        ? "true"
                        : editedScreen.solution,
                    title: questionTitle || editedScreen.title,
                    slug: questionSlug || editedScreen.slug,
                  });
                }}
                value={editedScreen.kind}
              >
                <option value="question">question</option>
                <option value="info screen">info screen</option>
                <option value="code snippets">code snippets</option>
                <option value="multiple choice">multiple choice</option>
                <option value="swipe">swipe</option>
                <option value="fill in">fill in</option>
                <option value="order">order</option>
              </select>
            </div>
            <div class="col-lg-9">
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                onChange={(e) => {
                  if (saveSuccessful) {
                    setSaveSuccessful(false);
                  }

                  setEditedScreen({
                    ...editedScreen,
                    ...{
                      title: e.currentTarget.value,
                      slug: strToSlug(e.currentTarget.value),
                    },
                  });
                }}
                value={editedScreen.title}
              />
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <label for="slug">Slug</label>
              <input
                type="text"
                class="form-control"
                placeholder="Slug"
                onChange={(e) => {
                  if (saveSuccessful) {
                    setSaveSuccessful(false);
                  }

                  setEditedScreen({
                    ...editedScreen,
                    ...{ slug: e.currentTarget.value },
                  });
                }}
                value={editedScreen.slug}
              />
            </div>
            <div class="col">
              <label for="full_screen">Full Screen</label>
              <select
                type="text"
                class="form-control"
                placeholder="Kind"
                onChange={(e) => {
                  if (saveSuccessful) {
                    setSaveSuccessful(false);
                  }

                  setEditedScreen({
                    ...editedScreen,
                    ...{ full_screen: e.currentTarget.value },
                  });
                }}
                value={editedScreen.full_screen}
              >
                <option value={true}>true</option>
                <option value={false}>false</option>
              </select>
            </div>
          </div>
          <div
            className={`d-inline-block cursor-pointer px-3 py-2 border ${
              editorOpened === "content" ? "bg-light font-weight-bold" : ""
            }`}
            onClick={() => setEditorOpened("content")}
          >
            Content
          </div>
          {["info screen", "question", "code snippets"].includes(
            editedScreen.kind
          ) ? (
            <div
              className={`d-inline-block cursor-pointer px-3 py-2 border ${
                editorOpened === "code" ? "bg-light font-weight-bold" : ""
              }`}
              onClick={() => setEditorOpened("code")}
            >
              Executable Code
            </div>
          ) : null}
          {editorOpened === "content" && (
            <div class="row mb-3">
              <div class="col">
                <ReactMde
                  onChange={(str) => {
                    if (saveSuccessful) {
                      setSaveSuccessful(false);
                    }

                    setEditedScreen({
                      ...editedScreen,
                      ...{ content: str },
                    });
                  }}
                  value={editedScreen.content}
                  generateMarkdownPreview={(markdown) =>
                    Promise.resolve(
                      <ReactMarkdown
                        source={markdown}
                        renderers={promptRenderer({ props })}
                        escapeHtml={false}
                      />
                    )
                  }
                  selectedTab={selectedTab}
                  onTabChange={setSelectedTab}
                />
              </div>
            </div>
          )}

          {editorOpened === "code" && (
            <div class="row mb-3">
              <div class="col">
                <textarea
                  type="text"
                  className="form-control screen-textarea"
                  rows={12}
                  onChange={(e) => {
                    if (saveSuccessful) {
                      setSaveSuccessful(false);
                    }

                    if (
                      ["info screen", "question", "code snippets"].includes(
                        editedScreen.kind
                      )
                    ) {
                      setEditedScreen({
                        ...editedScreen,
                        ...{ full_screen: false, code: e.currentTarget.value },
                      });
                    } else {
                      setEditedScreen({
                        ...editedScreen,
                        ...{ code: e.currentTarget.value },
                      });
                    }
                  }}
                  value={editedScreen.code}
                />
              </div>
            </div>
          )}

          {![
            "info screen",
            "question",
            "code snippets",
            "swipe",
            "fill in",
          ].includes(editedScreen.kind) && (
            <div class="row mb-3">
              <div class="col">
                <label for="options">Options (comma separated string)</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Options"
                  onChange={(e) => {
                    if (saveSuccessful) {
                      setSaveSuccessful(false);
                    }

                    setEditedScreen({
                      ...editedScreen,
                      ...{ options: e.currentTarget.value.split(",") },
                    });
                  }}
                  value={editedScreen.options}
                />
              </div>
            </div>
          )}

          {!["info screen", "question", "code snippets"].includes(
            editedScreen.kind
          ) && (
            <div class="row mb-3">
              <div class="col">
                <label for="solution">
                  {editedScreen.kind === "multiple choice" &&
                    "0-based index of correct option"}
                  {editedScreen.kind === "swipe" &&
                    "Use false or true as a string"}
                  {editedScreen.kind === "order" &&
                    'Corresponding order of each element in options as string (e.g. "0, 1, 2") or array ([0, 1, 2]). So if the options are "A, C, B" and the solution is "0, 2, 1", then the proper order is A, B, C.'}
                </label>
                {editedScreen.kind === "swipe" ? (
                  <select
                    type="text"
                    class="form-control"
                    placeholder="true"
                    onChange={(e) => {
                      setEditedScreen({
                        ...editedScreen,
                        ...{ solution: e.target.value },
                      });
                    }}
                    value={editedScreen.solution}
                  >
                    <option value="true">true</option>
                    <option value="false">false</option>
                  </select>
                ) : (
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Solution"
                    onChange={(e) => {
                      if (saveSuccessful) {
                        setSaveSuccessful(false);
                      }

                      let newVal = e.currentTarget.value;

                      setEditedScreen({
                        ...editedScreen,
                        ...{ solution: newVal },
                      });
                    }}
                    value={editedScreen.solution}
                  />
                )}
              </div>
            </div>
          )}

          <div class="mb-3 d-flex justify-content-end">
            {editedScreen.justSaved || saveSuccessful ? (
              <i className="fa fa-check-circle fa-2x mr-2" />
            ) : null}
            <button
              className="genric-btn danger mr-2"
              onClick={() =>
                window.open(`/admin/screen/${editedScreen.id}/delete`)
              }
            >
              Delete Screen
            </button>
            <button
              className="genric-btn info"
              onClick={(e) => {
                e.preventDefault();

                saveScreen(editedScreen, (obj) => {
                  if (obj) {
                    setSaveSuccessful(true);

                    const newEditedScreens = [
                      ...editedScreens
                        .map((s) => {
                          if (s.isNew) {
                            delete s.isNew;
                            return { ...obj, justSaved: true };
                          }

                          // if slug switched
                          if (editedScreen.id === s.id) {
                            return { ...obj, justSaved: true };
                          }

                          // FIXME: need to return previous + 1
                          // but new id shifts
                          if (s.id === obj.id) {
                            return obj;
                          }
                          return s;
                        })
                        .sort((a, b) => {
                          return a.sequence - b.sequence;
                        }),
                    ];

                    setEditedScreens(newEditedScreens);
                    setEditedScreen(obj);
                  }
                });
              }}
            >
              Save Screen
            </button>
          </div>
        </div>
      </form>
    </OutsideClickHandler>
  ) : (
    <div
      className="cursor-pointer w-100"
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = "#f9fbfd";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = "#fff";
      }}
      onClick={() => {
        const newEditorOpenList = { ...editorOpenList };
        newEditorOpenList[screen.slug] = !editorOpenList[screen.slug];
        setEditorOpenList(newEditorOpenList);
      }}
    >
      <div className="card border-0">
        <div
          className="card-header"
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          key={key}
        >
          <i className="fa fa-grip-horizontal" /> Screen {editedScreen.sequence}
          : {editedScreen.title}
        </div>

        <ScreenRenderer
          changeLanguage={props.changeLanguage}
          randomAdScreen={props.randomAdScreen}
          runCode={props.runCode}
          setNotes={props.setNotes}
          screen={editedScreen}
          screens={editedScreens}
          tutorialSlug={props.tutorialSlug}
          tutorialType={props.tutorialType}
        />
      </div>
    </div>
  );
};

export const Item = forwardRef(({ id, ...props }, ref) => {
  const { screens } = props;
  const currScreen = screens.find((s) => s.slug === id);

  return (
    <form className="card mb-5" {...props} ref={ref}>
      <div className="card-header">
        Dragging <strong>{currScreen?.title}</strong> ... Drop in place of
        another screen
      </div>
      <div className="card-body">
        <ReactMarkdown
          // for arrays not wrapped in ticks
          plugins={[[remarkDisableTokenizers, { inline: ["reference"] }]]}
          source={currScreen?.content}
          escapeHtml={false}
        />
      </div>
    </form>
  );
});

export const BreakTrigger = (props) => {
  const {
    user,
    editedScreens,
    setEditedScreens,
    screenBefore,
    tutorialId,
    tutorialType,
  } = props;

  const [openAddButton, setOpenAddButton] = React.useState(false);

  return (
    <div
      className="w-100 d-flex align-items-center justify-content-center"
      style={{ height: "4rem" }}
      onMouseEnter={(e) => {
        e.currentTarget.style.background = "#f9fbfd";
        setOpenAddButton(true);
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = null;
        setOpenAddButton(false);
      }}
    >
      {openAddButton ? (
        <AddButton
          user={user}
          editedScreens={editedScreens}
          setEditedScreens={setEditedScreens}
          screenBefore={screenBefore}
          tutorialId={tutorialId}
          tutorialType={tutorialType}
        />
      ) : (
        <div className="w-100 h-100"></div>
      )}
    </div>
  );
};

export const AddButton = (props) => {
  const {
    user,
    editedScreens,
    setEditedScreens,
    screenBefore,
    tutorialId,
    tutorialType,
  } = props;

  return (
    <button
      type="button"
      className="btn btn-default btn-circle btn-lg mx-auto"
      onClick={(e) => {
        if (user && !user.admin) {
          return window.open("/users/sign_in");
        }

        let newEditedScreens = [...editedScreens];

        let screenBeforeIdx = newEditedScreens.indexOf(screenBefore);

        // Get rid of any other new screen we're adding
        if (newEditedScreens.some((s) => s.isNew)) {
          if (screenBefore.isNew) {
            screenBeforeIdx = screenBeforeIdx - 1;
          }
          newEditedScreens = newEditedScreens.filter((s) => !s.isNew);
        }

        newEditedScreens.splice(screenBeforeIdx + 1, 0, {
          id: "new",
          title: "New Screen",
          kind: "info screen",
          content: "Insert content here",
          full_screen: true,
          options: [],
          solution: "",
          sequence: screenBeforeIdx + 2,
          lesson_id: tutorialType === "lessons" ? tutorialId : null,
          challenge_id: tutorialType === "challenges" ? tutorialId : null,
          slug: strToSlug("New screen"),
          isNew: true,
        });

        setEditedScreens(newEditedScreens);
      }}
    >
      <i className="fa fa-plus"></i>
    </button>
  );
};

const LanguageContentsEditor = (props) => {
  const { tutorialId, tutorialSlug } = props;

  const token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");
  const availableLanguages = Object.keys(LANG_TO_ALGODAILY_ID);

  const [editedLC, setEditedLC] = React.useState({});
  const [languageContents, setLanguageContents] = React.useState([]);
  const [selectedLang, setSelectedLang] = React.useState();
  const [codeVersion, setCodeVersion] = React.useState("initial_code_full");
  const [saved, setSaved] = React.useState(false);

  function saveLC(lc, cb) {
    const lcWithLang = {
      ...lc,
      language_id: LANG_TO_ALGODAILY_ID[selectedLang],
      challenge_id: tutorialId,
    };

    fetch(`/api/language_contents/${lc.id || ""}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({ language_content: lcWithLang }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((obj) => {
        const editedLCWithId = obj;
        setEditedLC(editedLCWithId);

        const newLC = [...languageContents];
        const idx = languageContents.findIndex(
          (lc) => ALGO_LANG_ID_TO_JUDGE_MAP[lc.language_id] === selectedLang
        );
        if (idx > -1) {
          newLC[idx] = editedLCWithId;
        } else {
          newLC.push(editedLCWithId);
        }

        setLanguageContents(newLC);

        cb();
      });
  }

  function fetchLCs() {
    fetch(`/api/language_contents/${tutorialSlug}`, {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      })
      .then((arr) => {
        setLanguageContents(arr);
      });
  }

  React.useEffect(() => {
    fetchLCs();
  }, []);

  React.useEffect(() => {
    const newLC = {
      ...languageContents.find((lc) => {
        return ALGO_LANG_ID_TO_JUDGE_MAP[lc.language_id] === selectedLang;
      }),
    };
    setEditedLC(
      newLC || {
        language_id: LANG_TO_ALGODAILY_ID[selectedLang],
        challenge_id: tutorialId,
      }
    );
    setSaved(false);
  }, [selectedLang, codeVersion]);

  return (
    <>
      <div className="row form-group">
        <div className="col-lg-6">
          <select
            class="form-control"
            aria-label="Select the language content to edit"
            onChange={(e) => setSelectedLang(e.target.value)}
          >
            <option>Select the language content to edit</option>
            {availableLanguages.map((l) => (
              <option value={l} selected={selectedLang === l}>
                {l}
              </option>
            ))}
          </select>
        </div>
        <div className="col-lg-6">
          <select
            class="form-control"
            aria-label="Select the version"
            onChange={(e) => setCodeVersion(e.target.value)}
          >
            <option selected>Select the version</option>
            {["initial_code_full", "solution_code_full"].map((v) => (
              <option value={v}>{v}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="w-100">
        <textarea
          type="text"
          id="curr-language-content"
          className="form-control mb-4"
          rows={12}
          onChange={(e) => {
            const newLcToShow = { ...editedLC };
            newLcToShow[codeVersion] = e.target.value;
            setEditedLC(newLcToShow);

            const newLC = [...languageContents];
            // to be found and set as LC later
            const idx = newLC.findIndex(
              (lc) => ALGO_LANG_ID_TO_JUDGE_MAP[lc.language_id] === selectedLang
            );
            if (idx > -1) {
              newLC[idx] = newLcToShow;
            } else {
              newLC.push(newLcToShow);
            }

            setLanguageContents(newLC);
          }}
          value={editedLC[codeVersion] || ""}
        />

        <button
          className="btn btm-sm w-100 bg-light rounded-lg p-2 mb-4 cursor-pointer"
          onClick={() => {
            saveLC(editedLC, () => {
              setSaved(true);
            });
          }}
        >
          Save {saved ? <i className="fa fa-check" /> : null}
        </button>
      </div>
    </>
  );
};
