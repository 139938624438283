import React from "react";

export default React.forwardRef((props, ref) => {
  const currLocation = window.location;

  const [wrapText, setWrapText] = React.useState(false);
  const [randomSha, _] = React.useState(
    Math.random().toString(36).substring(2, 15)
  );

  return currLocation && !currLocation.pathname.includes("_slides") ? (
    <div
      className={`bg-light w-100 mb-4 small overflow-auto border d-none ${randomSha}`}
      id="curr-output-container"
      ref={ref}
      style={{
        resize: "both",
        maxHeight: "90vh",
      }}
    >
      <div className="d-flex justify-content-between">
        <strong className="mt-2 px-2 px-lg-4">OUTPUT</strong>
        {/* wrap text checkbox */}
        <div
          className="form-check form-switch mt-2 mr-2"
          onClick={() => {
            const newVal = !wrapText;
            setWrapText(newVal);
            if (newVal) {
              $(`.${randomSha} .sandbox-output-log`).css(
                "white-space",
                "pre-wrap"
              );
            } else {
              $(`.${randomSha} .sandbox-output-log`).css(
                "white-space",
                "nowrap"
              );
            }
          }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            id="wrap-text-output"
            defaultChecked={wrapText}
          />
          <label className="form-check-label" htmlFor="wrap-text-output">
            Wrap
          </label>
        </div>
      </div>
      <div className="output p-2 p-lg-4">
        :001 > Cmd/Ctrl-Enter to run, Cmd/Ctrl-/ to comment
      </div>
    </div>
  ) : null;
});
