import "./App.css";

import { BrowserRouter, Route } from "react-router-dom";
import React, { useRef } from "react";
import { adjustForNav, getUrlLanguage } from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import BookmarksContainer from "./BookmarksContainer";
import JobApplicationsContainer from "./JobApplicationsContainer";
import PageContainer from "./PageContainer";
import QuizContainer from "./QuizContainer";
import SlidesContainer from "./SlidesContainer";
import TestsPassedModal from "./TestsPassedModal";
import { actions } from "./redux/StateReducer";

function MainApp(props) {
  const dispatch = useDispatch();

  const course = useSelector((state) => state.course);
  const challenge = useSelector((state) => state.challenge);
  const user = useSelector((state) => state.user);
  const showTestPassed = useSelector((state) => state.showTestPassed);

  const testsPassed = async (time) => {
    dispatch({
      type: actions.SET_SHOW_TESTS_PASSED,
      payload: true,
    });

    dispatch({
      type: actions.ADD_CHALLENGE_COMPLETION,
      payload: {
        time: time,
      },
    });

    const newSections = JSON.parse(JSON.stringify(course.sections));
    // have to fix category --> sections
    const filteredSectionIdx = newSections.findIndex(
      (s) => challenge.section_id === s.id
    );
    const tutorialIdx = newSections[filteredSectionIdx].challenges.findIndex(
      (t) => t.slug === challenge.slug
    );
    newSections[filteredSectionIdx].challenges[tutorialIdx].completed = true;

    dispatch({
      type: actions.SET_COURSE,
      payload: { ...course, sections: newSections },
    });
  };

  React.useEffect(() => {
    if (window.Sentry) {
      Sentry.init({
        dsn:
          "https://67276dae4e5e42b8a13a0ab6850331ea@o406621.ingest.sentry.io/5274428",
      });
    }

    if (localStorage.getItem("clearStorage") !== "1.4") {
      localStorage.clear();
      localStorage.setItem("clearStorage", "1.4");
    }

    window.testsPassed = testsPassed;

    const urlLanguage = getUrlLanguage();
    if (urlLanguage) {
      dispatch({ type: actions.SET_APP_LANGUAGE, payload: urlLanguage });
    }

    adjustForNav();
  }, []);

  React.useEffect(() => {
    window.testsPassed = testsPassed;
  }, [challenge, course]);

  return (
    <BrowserRouter basename="/">
      <div id="ad-app" className="row no-gutters container-with-top w-100">
        <Route
          path={[
            "/dashboard",
            "/activity-log",
            "/courses/:courseId",
            "/lessons/:tutorialSlug/:language/:screenSlug",
            "/lessons/:tutorialSlug/:screenSlug",
            "/lessons/:tutorialSlug",
            "/challenges/:tutorialSlug/:language/:screenSlug",
            "/challenges/:tutorialSlug/:screenSlug",
            "/challenges/:tutorialSlug",
            "/sections/:sectionSlug",
            "/sections/:sectionSlug/edit",
            "/categories/:categorySlug",
            "/flash-cards",
            "/flash-cards/:flashCardSlug",
            "/convert/:fromLanguage/:toLanguage",
            "/challenge-manager",
            "/ai-tutor",
          ]}
          exact
        >
          <PageContainer />
        </Route>
        <Route
          path={[
            "/lesson_slides/:tutorialSlug/:screenSlug",
            "/lesson_slides/:tutorialSlug",
            "/lesson_slides/:tutorialSlug/:language/:screenSlug",
            "/challenge_slides/:tutorialSlug/:screenSlug",
            "/challenge_slides/:tutorialSlug",
            // matches here well because it's got 4 backslashes
            "/challenge_slides/:tutorialSlug/:language/:screenSlug",
          ]}
          exact
        >
          <SlidesContainer />
        </Route>
        <Route
          path="/challenges"
          exact
          render={() => window.location.replace("/curriculum")}
        />
        <Route path={["/jobapps", "/jobapps/:jobAppId"]} exact>
          <JobApplicationsContainer />
        </Route>
        <Route path={["/bookmarks"]} exact>
          <BookmarksContainer />
        </Route>
        <Route path={["/quiz"]} exact>
          <QuizContainer />
        </Route>
        {props.user ? null : (
          <div
            className="g-recaptcha d-none"
            data-sitekey="6LeyAtsZAAAAAJRM2wqd4Jz7hWXmx7tdlLccneOq"
            data-callback="signUp"
            data-size="iFnvisible"
          ></div>
        )}
      </div>
      {showTestPassed ? <TestsPassedModal /> : null}
    </BrowserRouter>
  );
}

export default MainApp;
