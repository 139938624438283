import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Ad from "./Ad";
import { HashLink } from "react-router-hash-link";
import JobApplicationsMenu from "./JobApplicationsMenu";
import { Link } from "react-router-dom";
import NotesMenu from "./NotesMenu";
import { actions } from "./redux/StateReducer";
import { toastNotSignedIn } from "./helpers/utils";

export default (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const challenge = useSelector((state) => state.challenge);
  const user = useSelector((state) => state.user);

  const {
    screen,
    screens,
    screenCompletions,
    tutorialType,
    tutorialSlug,
  } = props;

  const isShowingVideo = location.pathname.includes("video");

  const [jumpToOpen, setJumpToOpen] = useState(true);

  function adjustTutorialMenu() {
    const appNav = document.getElementById("algodaily-nav");

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const viewportMinusNavHt = (vh - appNav?.offsetHeight).toString() + "px";

    const tutorialScrollContainer = document.getElementById(
      "tutorial-nav-scroll"
    );
    if (tutorialScrollContainer) {
      tutorialScrollContainer.style.height = viewportMinusNavHt;
    }

    const tutorialNavContainer = document.getElementById(
      "tutorial-nav-container"
    );
    if (tutorialNavContainer) {
      tutorialNavContainer.style.width =
        document
          .getElementById("tutorial-menu-pillar")
          ?.clientWidth.toString() + "px";
      tutorialNavContainer.style.height = viewportMinusNavHt;
    }
  }

  React.useEffect(() => {
    adjustTutorialMenu();
  });

  React.useEffect(() => {
    window.addEventListener("resize", adjustTutorialMenu);

    if (!props.user) {
      window.addEventListener("load", function () {
        var ezstandalone = window.ezstandalone || {};
        ezstandalone.cmd = ezstandalone.cmd || [];
        ezstandalone.cmd.push(function () {
          ezstandalone.displayMore(109);
        });
      });
    }
  }, []);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -60;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const isSlidesContainer = location.pathname.includes("slides");
  const slidesPath =
    tutorialType === "lessons" ? "lesson_slides" : "challenge_slides";

  return (
    <nav id="tutorial-menu-pillar" className="bg-light col-lg-2 p-0">
      <div
        id="tutorial-nav-container"
        className="m-0 p-0 position-fixed overflow-auto bg-light overflow-auto"
        style={{ zIndex: 100 }}
      >
        <div
          id="tutorial-nav-header"
          className="w-100 d-flex justify-content-start align-items-center border-bottom px-4 pt-4 cursor-pointer"
          onClick={() => {
            setJumpToOpen(!jumpToOpen);
          }}
        >
          <i
            className={`fa ${
              jumpToOpen ? "fa-angle-double-down" : "fa-angle-double-right"
            } mr-2`}
          />{" "}
          <h3 className="py-2 my-0">Jump To</h3>
        </div>
        <div id="tutorial-nav-scroll" className="w-100">
          {!(user && user.paid_active) && (
            <Ad kind="narrow" />
            // <div id="ezoic-pub-ad-placeholder-109" className="w-100"></div>
          )}
          <div
            id="tutorial-nav-buttons"
            className="btn-group-vertical py-2 px-4 w-100"
          >
            {props.user &&
              props.user.admin &&
              (location.pathname.endsWith("/edit") ? (
                <button
                  className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
                  onClick={() =>
                    window.location.replace(`/${tutorialType}/${tutorialSlug}`)
                  }
                >
                  <i className="fa fa-edit text-ocean-blue pull-left"></i>{" "}
                  Preview Tutorial
                </button>
              ) : (
                <button
                  className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
                  onClick={() =>
                    history.push(`/${tutorialType}/${tutorialSlug}/edit`)
                  }
                >
                  <i className="fa fa-edit text-ocean-blue pull-left"></i> Edit
                  Tutorial
                </button>
              ))}
            <button
              className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
              onClick={() => {
                if (!user) {
                  return toastNotSignedIn("use the AI Chatbot Tutor");
                }

                if (window.cp?.visible) {
                  window.cp?.hide();
                } else {
                  window.cp?.show();
                }
              }}
            >
              <i className="fa fa-robot text-ocean-blue pull-left"></i> AI
              Chatbot Tutor
            </button>
            {!isSlidesContainer && (
              <button
                className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
                onClick={() =>
                  props.user
                    ? history.push(`/${slidesPath}/${tutorialSlug}`)
                    : toastNotSignedIn("view in Interactive Mode")
                }
              >
                <i className="fa fa-code text-ocean-blue pull-left"></i>{" "}
                Interactive Mode
              </button>
            )}
            {!isShowingVideo && challenge && challenge.video ? (
              <button
                className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
                onClick={() =>
                  history.push(
                    `/challenges/${tutorialSlug}/video?scrollToTop=true`
                  )
                }
              >
                <i className="fa fa-video text-ocean-blue pull-left"></i> Watch
                Video
              </button>
            ) : null}
            {isShowingVideo ? (
              <button
                className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
                onClick={() => {
                  history.push(`/challenges/${tutorialSlug}?scrollToTop=true`);
                }}
              >
                <i className="fa fa-book text-ocean-blue pull-left"></i>{" "}
                Tutorial
              </button>
            ) : null}
            {props.challenge &&
              props.challenge.has_trace &&
              !(props.challenge.locked && !props.user?.paid_active) && (
                <button
                  className="btn btn-sm shadow bg-gray rounded-lg px-3 my-1 w-100 tutorial-button"
                  onClick={() =>
                    dispatch({ type: actions.SET_VIZ_CONTAINER, payload: true })
                  }
                >
                  <i className="fa fa-eye text-ocean-blue pull-left"></i>{" "}
                  Visualize Solution
                </button>
              )}
          </div>

          {jumpToOpen ? (
            <>
              <ul className="list-group list-group-flush">
                {screens.map((s, i) => {
                  if (s.slug === "locked") {
                    return (
                      <li className="list-group-item border-0 bg-transparent px-4 py-2 small">
                        Locked Screen
                      </li>
                    );
                  } else {
                    const normalizedSlug = s.slug.replace(
                      /[^a-zA-Z0-9_-]/g,
                      ""
                    );

                    return (
                      <li
                        className={`list-group-item border-0 bg-transparent px-4 py-2 ${
                          screen.slug === s.slug
                            ? "text-ocean-blue font-weight-bold"
                            : ""
                        }`} // hack for active
                      >
                        <HashLink
                          smooth
                          id={`menu-${normalizedSlug}`}
                          to={`/${tutorialType}/${tutorialSlug}${
                            isSlidesContainer
                              ? "/"
                              : // FIXME: if it's in editor mode
                              props.user &&
                                props.user.admin &&
                                location.pathname.includes("/edit")
                              ? "/edit#"
                              : "#"
                          }${normalizedSlug}`}
                          className="small"
                          scroll={(el) => scrollWithOffset(el)}
                        >
                          {screenCompletions[i] ? (
                            <s>
                              {s.title} <i className="fa fa-check"></i>
                            </s>
                          ) : (
                            s.title
                          )}
                        </HashLink>
                        <a
                          href={`/${tutorialType}/${tutorialSlug}/${normalizedSlug}`}
                          className="small"
                          target="_blank"
                        >
                          <i className="fa fa-external-link-alt fa-xs ml-1"></i>
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
              {challenge && challenge.question ? (
                <div className="border-top m-0">
                  <ul className="list-group">
                    {/* <Link
                      className="small"
                      to={`/challenge_slides/${tutorialSlug}/tests`}
                    >
                      <li className="list-group-item border-0 bg-transparent px-4 py-2">
                        See Test Cases
                      </li>
                    </Link> */}
                    <Link
                      className="small"
                      to={`/challenge_slides/${tutorialSlug}/solutions`}
                    >
                      <li className="list-group-item border-0 bg-transparent px-4 py-2">
                        See Solution
                      </li>
                    </Link>
                    <Link
                      className="small"
                      to={`/challenge_slides/${tutorialSlug}/completions`}
                    >
                      <li className="list-group-item border-0 bg-transparent px-4 py-2">
                        Your Submissions
                      </li>
                    </Link>
                  </ul>
                </div>
              ) : null}
            </>
          ) : null}
          <NotesMenu
            notes={props.notes}
            setNotes={props.setNotes}
            user={props.user}
          />

          {/* <JobApplicationsMenu user={props.user} /> */}
        </div>
      </div>
    </nav>
  );
};
