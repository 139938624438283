import { Link, useHistory } from "react-router-dom";
import React, { useState } from "react";
import { extractOnlyText, isMobileDevice, titleize } from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import FooterLinks from "./FooterLinks";
import OnboardingFlow from "./OnboardingFlow";
import Skeleton from "react-loading-skeleton";
import { actions } from "./redux/StateReducer";

const Dashboard = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );
  const footerLinks = useSelector((state) => state.footerLinks);
  const user = useSelector((state) => state.user);
  const view = useSelector((state) => state.view);

  const [userInfo, setUserInfo] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  function updateSettings(e) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/settings`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({
        brochure: {
          language: $("#languageSwitch").val(),
          // huge hack, these should be separate functions
          view:
            e.target.checked !== undefined
              ? e.target.checked === true
                ? "interactive"
                : "article"
              : $("#viewSwitch").prop("checked")
              ? "interactive"
              : "article",
        },
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          // hack

          $("#languageSwitch").val(obj.language);

          if (obj.view === "interactive") {
            $("#viewSwitch").prop("checked", true);
          } else {
            $("#viewSwitch").prop("checked", false);
          }

          dispatch({
            type: actions.SET_VIEW,
            payload: obj.view,
          });
          dispatch({
            type: actions.SET_APP_LANGUAGE,
            payload: obj.language,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  const fetchUserInfo = () => {
    fetch(`/api/me`)
      .then((res) => {
        return res.json();
      })
      .then(
        (obj) => {
          setUserInfo(obj);
          setIsLoaded(true);

          if (document.querySelector("#bottom-left-cell")) {
            document.querySelector("#bottom-left-cell .card").style.height =
              document.querySelector("#bottom-right-cell .card").clientHeight +
              "px";
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // TO DO: handle errors
          // this.setState({
          //   isLoaded: true,
          //   error,
          // });
        }
      );
  };

  React.useEffect(() => {
    if (!user) {
      return window.location.replace("/users/sign_in");
    }

    fetchUserInfo();
  }, [user]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [userInfo]);

  if (!isLoaded) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          minHeight: "100vh",
        }}
      >
        <i className="fas fa-spinner fa-2x fa-pulse m-auto text-center"></i>
      </div>
    );
  }

  // kind of a hack - using first lesson completion as test for dashboard
  if (isLoaded && userInfo && !userInfo.onboarded) {
    return <OnboardingFlow fetchUserInfo={fetchUserInfo} userInfo={userInfo} />;
  }

  return (
    <>
      <div className={`${isMobileDevice() ? "p-2" : "p-0"} container`}>
        <div className="small mb-2">
          <strong>Home</strong> |{" "}
          <Link to={`/activity-log`}>
            <strong>Activity Log</strong>
          </Link>{" "}
          |{" "}
          <strong className="text-secondary">
            {(userInfo.points || 0).toString()}
          </strong>{" "}
          points
        </div>
        <h3
          className="mt-2 mb-4"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {userInfo ? (
            <>Welcome back, {userInfo.name}!</>
          ) : (
            <Skeleton></Skeleton>
          )}
        </h3>
        <>
          <div className="row">
            <div className="col-lg-8 mb-4 rounded-lg">
              <div className="row no-gutters rounded-lg shadow">
                <div
                  className="col-lg-6 bg-white d-none d-lg-flex align-items-center flex-column justify-content-center"
                  style={{ minHeight: "12rem", maxHeight: "16rem" }}
                >
                  <img
                    src={
                      userInfo &&
                      userInfo.section_on &&
                      userInfo.section_on[0].image_url
                    }
                    className="w-100"
                    style={{
                      objectFit: "cover",
                      height: "100%",
                    }}
                  />
                </div>
                <div className="card col-lg-6 border-0">
                  <div
                    className="card-body border-0 text-dark d-flex flex-column justify-content-center"
                    style={{
                      textShadow: "1px 1px #fff",
                    }}
                  >
                    {userInfo.section_on && userInfo.section_on.length ? (
                      <>
                        <p className="small mb-2 p-0">
                          You're currently working through:
                        </p>
                        <Link to={`/sections/${userInfo.section_on[0].slug}`}>
                          <h3 className="font-weight-bold mb-4">
                            {titleize(
                              userInfo.section_on[0].slug.replace(/-/g, " ")
                            )}
                          </h3>
                        </Link>
                      </>
                    ) : null}
                    <button
                      className="genric-btn px-2 px-lg-3 info w-100"
                      onClick={() => {
                        if (!userInfo.next) {
                          history.push("/sections/high-level");
                        }

                        const tutorialType = userInfo.next.question
                          ? "challenges"
                          : "lessons";
                        const tutorialSlug =
                          userInfo.next && userInfo.next.slug;

                        const nextTutorial = `/${tutorialType}/${tutorialSlug}`;

                        history.push(nextTutorial);
                      }}
                    >
                      <i className="fa fa-forward pull-left mr-2"></i>
                      Next Tutorial
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-4">
              <div className="card bg-white border-0 rounded-lg shadow h-100">
                <div className="card-body border-0 d-flex flex-column justify-content-center">
                  <h3 className="font-weight-bold">Learning Preferences</h3>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="viewSwitch"
                      onChange={updateSettings}
                      checked={view === "interactive"}
                    />
                    <label
                      className="form-check-label small"
                      htmlFor="viewSwitch"
                    >
                      Default to interactive view
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="customSwitch2"
                      checked={courseCurriculumOpen}
                      onChange={(e) => {
                        localStorage &&
                          localStorage.setItem(
                            "adCourseCurriculumOpen",
                            e.target.checked
                          );

                        dispatch({
                          type: actions.SET_COURSE_CURRICULUM_OPEN,
                          payload: e.target.checked,
                        });
                      }}
                    />
                    <label
                      className="form-check-label small"
                      htmlFor="customSwitch2"
                    >
                      Keep course menu open
                    </label>
                  </div>
                  <select
                    className="custom-select custom-select-sm"
                    id="languageSwitch"
                    value={userInfo.language}
                    onChange={updateSettings}
                  >
                    <option value="javascript">Default to Javascript</option>
                    <option value="python">Default to Python</option>
                    <option value="java">Default to Java</option>
                    <option value="go">Default to Go</option>
                    <option value="ruby">Default to Ruby</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <h3
            className="mt-2 mb-4"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {userInfo ? <>Your Progression</> : <Skeleton></Skeleton>}
          </h3>
          <div
            className="row no-gutters bg-white rounded-lg shadow mb-4 d-flex align-items-center"
            style={{ lineHeight: "0" }}
          >
            <div className="col-lg-3 col-12 p-4">
              <small>LESSONS COMPLETED</small>

              <div className="row d-flex align-items-center justify-content-center my-2">
                <div className="col-4">
                  <i className="fa fa-book fa-2x"></i>
                </div>
                <div className="col-8">
                  <h1 className="font-weight-bold">
                    {userInfo && userInfo.completed_lessons ? (
                      userInfo.completed_lessons.length
                    ) : (
                      <Skeleton />
                    )}
                  </h1>
                </div>
              </div>
              <a
                href="/curriculum"
                className="genric-btn px-2 px-lg-3 info w-100 mb-2"
              >
                All courses
              </a>
              <a href="/random-lesson">
                <button className="genric-btn px-2 px-lg-3 bg-white border w-100">
                  Random lesson
                </button>
              </a>
            </div>
            <div className="col-lg-3 col-12 p-4">
              <small>CHALLENGES COMPLETED</small>

              <div className="row d-flex align-items-center justify-content-center my-2">
                <div className="col-4">
                  <i className="fa fa-code fa-2x"></i>
                </div>
                <div className="col-8">
                  <h1 className="font-weight-bold">
                    {userInfo && userInfo.completed_challenges ? (
                      userInfo.completed_challenges.length
                    ) : (
                      <Skeleton />
                    )}
                  </h1>
                </div>
              </div>
              <a
                href="/challenges/daily"
                className="genric-btn px-2 px-lg-3 info w-100 mb-2"
              >
                All problems
              </a>
              <a href="/random-challenge">
                <button className="genric-btn px-2 px-lg-3 bg-white border w-100">
                  Random problem
                </button>
              </a>
            </div>
            <div className="col-lg-6">
              <img src="https://storage.googleapis.com/algodailyrandomassets/personalization/dashboard-mid.svg" />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-4 h-100" id="bottom-left-cell">
              <div
                className="card bg-white border-0 rounded-lg shadow cursor-pointer"
                style={{ height: "100%" }}
              >
                <div className="card-header bg-white">
                  <h3 className="font-weight-bold">Challenge Completions</h3>
                </div>
                <div className="card-body h-100" style={{ overflow: "scroll" }}>
                  {userInfo &&
                  userInfo.completed_challenges &&
                  userInfo.completed_challenges.length ? (
                    <ul className="list-group list-group-flush small">
                      {userInfo.completed_challenges.map((slug) => (
                        <li className="list-group-item pl-0">
                          <Link to={`/challenges/${slug}`}>
                            {titleize(slug.replace(/-/g, " "))}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <>
                      <p>
                        Start practicing some challenges! You can solve them
                        either right in the tutorials, or by going to{" "}
                        <strong>Interactive Mode</strong> on the right hand menu
                        of problems.
                      </p>
                      <a href="/challenges/reverse-a-string">
                        <button className="genric-btn px-4 info mb-2">
                          <i className="fa fa-forward"></i> See first problem
                        </button>
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div id="bottom-right-cell" className="col-lg-6 mb-4">
              <div className="card bg-white border-0 rounded-lg shadow">
                <div className="card-header bg-white">
                  <h3 className="font-weight-bold">Next Tutorial</h3>
                </div>
                <div className="card-body">
                  <h4>{userInfo.next ? userInfo.next.title : <Skeleton />}</h4>
                  <p>
                    {userInfo.next ? (
                      extractOnlyText(userInfo.next.excerpt) + "..."
                    ) : (
                      <Skeleton />
                    )}
                  </p>
                  {userInfo.next ? (
                    <p>
                      <Link
                        to={
                          userInfo.next.question
                            ? `/challenges/${userInfo.next.slug}`
                            : `/lessons/${userInfo.next.slug}`
                        }
                      >
                        <button className="genric-btn px-4 info mb-2">
                          <i className="fa fa-forward"></i> See what's next
                        </button>
                      </Link>
                    </p>
                  ) : (
                    <Skeleton />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
        <FooterLinks footerLinks={footerLinks} />
      </div>
    </>
  );
};

export default Dashboard;
