import "ChatWindow.css";

import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import { Rnd } from "react-rnd";
import { isMobileDevice } from "./helpers/utils";
import { parseSSEStream } from "./helpers/utils";
import remarkDisableTokenizers from "remark-disable-tokenizers";

export const scrollToBottom = () => {
  const element = document.getElementById("chat-prompt-scroll");
  console.log("element", element);
  if (element) {
    element.scrollTop = element.scrollHeight;
  }
};

const transformMsgs = (msgs, user) => {
  return msgs
    .map((msg) => {
      return {
        id: msg.id,
        message: msg.message,
        kind: msg.kind,
        user: msg.kind === "bot" ? "Tutor" : user.name,
        createdAt: new Date(msg.created_at),
      };
    })
    .sort((a, b) => a.createdAt - b.createdAt);
};

const flashFx = () => {
  // Make the background of the last message node flash
  const chatPromptScroll = document.getElementById(`chat-prompt-scroll`);

  if (chatPromptScroll) {
    const lastMsgNodes = chatPromptScroll.querySelectorAll(".chat-prompt-msg");
    const lastMsgNode = lastMsgNodes[lastMsgNodes.length - 1];

    if (lastMsgNode) {
      lastMsgNode.classList.add("flash");
      setTimeout(() => {
        lastMsgNode.classList.remove("flash");
      }, 500);
    }
  }
};

const ChatWindow = (props) => {
  if (!props.user) {
    return null;
  }

  const [isResponding, setIsResponding] = React.useState(false);
  const [messages, setMessages] = React.useState([
    {
      id: "test",
      message:
        "Hi there! I'm here to help you with any questions you have about the course, materials, or anything else! Enter something below to get started. Here's some examples of questions you can ask:\n\n- Create a 7 day interview plan for me.\n- What is the time complexity of a binary search?\n- What is AlgoDaily's refund policy?\n- What is the difference between a stack and a queue?",
      kind: "bot",
      user: props.user?.name,
      createdAt: new Date(),
    },
  ]);
  const [newMessage, setNewMessage] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState(
    "Ask a question! For example: " +
      [
        "What is the difference between a class and an object?",
        "How can I get better at SQL?",
        "Name some projects to help me with systems design.",
        "What is the difference between a linked list and a binary tree?",
        "What is the difference between a hash table and a binary search tree?",
        "How do I solve problems when I have no idea how to start?",
        "Give me the time complexity of a trie.",
        "",
      ][Math.floor(Math.random() * 3)]
  );

  const sendMsg = async (message) => {
    // Change when we have a better way to handle this
    if (messages.length > 400) {
      setMessages((prevMessages) => {
        return [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            message:
              "AlgoDaily's AI Private Tutor is a beta feature. You've reached the max number of messages for pre-release. Please email team@algodaiy.com if you'd like to continue chatting with our AI tutor.",
            kind: "bot",
            user: "Tutor",
            createdAt: new Date(),
          },
        ];
      });
      return;
    }

    setMessages((prevMessages) => {
      return [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          message,
          kind: "user",
          user: props.user.name,
          createdAt: new Date(),
        },
      ];
    });

    if (!props.user) {
      // if (!(props.user && props.user.paid_active)) {
      setMessages((prevMessages) => {
        return [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            message: "Please login to chat with our AI tutor.",
            // "You must be a premium member to chat with our AI tutor. Please visit https://algodaily.com/subscriptions/new to see our paid options.",
            kind: "bot",
            user: "Tutor",
            createdAt: new Date(),
          },
        ];
      });
      return;
    }

    if (messages.length) setIsResponding(true);

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;
      fetch("/chat_prompt", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": token,
        },
        body: JSON.stringify({
          kind: "tutor",
          message,
          previous_msg:
            messages.length && messages.length > 0
              ? messages[messages.length - 1].message
              : "",
        }),
      }).then(async (response) => {
        // Stream response object stream for status
        const reader = response.body.getReader();

        let res = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            console.log("done", done);
            flashFx();
            break;
          }

          // Convert Uint8Array to string
          const s = new TextDecoder().decode(value);
          const text = parseSSEStream(s);

          setMessages((prevMessages) => {
            // check if last prevMessage was the same as the current one
            let pm = prevMessages;
            if (pm.length > 0 && pm[pm.length - 1].message === text) {
              // remove last
              pm = pm.slice(0, pm.length - 1);
            }

            // if we're in the middle of processing new, handle
            if (pm.length > 0 && pm[pm.length - 1].id === "new") {
              // remove last
              pm = pm.slice(0, pm.length - 1);
            }

            res += text;

            return [
              ...pm,
              {
                id: "new",
                message: res,
                kind: "bot",
                user: "Tutor",
                createdAt: new Date(),
              },
            ];
          });

          scrollToBottom();
        }
        // if (!res.ok) {
        //   throw res;
        // }
        // return res.json();
      });
      // .then(async (respMsg) => {
      //   console.log([...messages]);

      //   setMessages((prevMessages) => {
      //     // check if last prevMessage was the same as the current one
      //     let pm = prevMessages;
      //     if (
      //       pm.length > 0 &&
      //       pm[pm.length - 1].message === respMsg.message
      //     ) {
      //       // remove last
      //       pm = pm.slice(0, pm.length - 1);
      //     }

      //     return [...pm, transformMsgs([respMsg], props.user)[0]];
      //   });

      //   scrollToBottom();
      //   setIsResponding(false);
      // });
    } catch (e) {
      console.log(e);
    }
  };

  function resizeHandler() {
    if (
      !props.floating &&
      document.getElementById("chat-prompt-scroll") &&
      document.getElementById("algodaily-nav") &&
      document.getElementById("chat-header") &&
      document.getElementById("entry-panel")
    ) {
      document.getElementById("chat-prompt-scroll").style.height =
        window.innerHeight -
        (document.getElementById("algodaily-nav").clientHeight +
          document.getElementById("chat-header").clientHeight +
          document.getElementById("entry-panel").clientHeight +
          24) +
        "px";

      document.getElementById("chat-prompt-scroll").style.maxHeight =
        window.innerHeight + "px";
    }
  }

  React.useEffect(() => {
    fetch(`/chat_prompt?full=${props.floating ? "false" : "true"}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((msgs) => {
        let ms = [...messages];

        if (msgs.length > 0 && props.floating) {
          // remove intro message with id 'test'
          ms = ms.filter((m) => m.id !== "test");
        }

        setMessages([...ms, ...transformMsgs(msgs, props.user)]);
        flashFx();
      })
      .catch((err) => {
        console.log(err);
      });

    addEventListener("resize", (event) => {
      resizeHandler();
    });
  }, []);

  React.useEffect(() => {
    resizeHandler();
  });

  React.useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const chatWindowContent = (
    <div id="chat-prompt" className="chat-prompt shadow-lg">
      <div className="w-100 p-0 bg-white">
        <div
          id="chat-header"
          className="d-flex align-items-center justify-content-between py-2 px-3 bg-ocean-blue text-white"
        >
          <div>
            <i className="fas fa-comment-dots mr-2"></i>
            <strong className="font-weight-bold m-0">AI Chatbot Tutor</strong>
          </div>
          {props.floating && (
            <div className="d-flex align-items-center">
              <a href="/ai-tutor">
                <button className="btn btn-sm btn-outline-light">
                  <i class="fas fa-expand"></i>
                </button>
              </a>
              <button
                className="ml-1 btn btn-sm btn-outline-light"
                onClick={() => {
                  // document.getElementById("chat-prompt").style.display = "none";
                  if (window.cp) {
                    window.cp.hide();
                  }
                }}
              >
                X
              </button>
            </div>
          )}
        </div>

        <div
          id="chat-prompt-scroll"
          style={{ overflow: "scroll", maxHeight: "50vh" }}
        >
          {props.floating && (
            <div className="row">
              <div className="col-12">
                <a href="/ai-tutor">
                  <button className="btn btn-sm shadow bg-gray rounded-lg p-2 w-100 m-auto tutorial-button">
                    <i class="fas fa-chalkboard-teacher mr-2"></i>
                    View AI Tutor history
                  </button>
                </a>
              </div>
            </div>
          )}
          {messages.map((messageObj) => (
            <div className="row">
              <div className="col-12">
                <div
                  className={`chat-prompt-msg card ${
                    messageObj.kind === "bot" ? "bg-light" : ""
                  }`}
                  id={`chat-prompt-msg-${messageObj.id}`}
                >
                  <div className="card-body">
                    <h5 className="card-title">
                      {messageObj.user}
                      {"  "}•{"  "}
                      <small>{messageObj.createdAt?.toLocaleString()}</small>
                    </h5>
                    {/* <p className="card-text"> */}
                    <ReactMarkdown
                      plugins={[
                        [
                          remarkDisableTokenizers,
                          {
                            inline: ["reference", "blockquote"],
                            block: [
                              "blockquote",
                              "atxHeading",
                              "setextHeading",
                            ],
                          },
                        ],
                      ]}
                      source={messageObj.message}
                      escapeHtml={false}
                    />
                    {/* </p> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row mt-2" id="entry-panel">
          <div className="col-12">
            <div class="form-group px-2">
              <textarea
                class="form-control"
                id="userMessage"
                rows="2"
                value={newMessage}
                // placeholder should be a random choice from different potential questions to ask
                placeholder={placeholder}
                onChange={(e) => {
                  setNewMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    if (newMessage.length > 0) {
                      sendMsg(newMessage);
                      scrollToBottom();
                      setNewMessage("");
                    }
                  }
                }}
              ></textarea>
              <div className="mt-2 d-flex align-items-center justify-content-end">
                {isResponding ? (
                  <i className="fas fa-spinner fa-pulse mr-2"></i>
                ) : null}

                <button
                  className="genric-btn px-2 px-lg-3 info"
                  onClick={() => {
                    sendMsg(newMessage);
                    scrollToBottom();
                    setNewMessage("");
                  }}
                >
                  Send <i class="far fa-paper-plane ml-1"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return props.floating ? (
    <Rnd
      default={{
        x: 10,
        y: 10 + (window.pageYOffset || document.documentElement.scrollTop || 0),
        width: isMobileDevice()
          ? "90%"
          : document.getElementById("curriculum-container")
          ? document.getElementById("curriculum-container").clientWidth
          : "25vw",
        height: "70vh",
      }}
      style={{
        zIndex: 9999,
        position: "fixed",
      }}
    >
      {chatWindowContent}
    </Rnd>
  ) : (
    chatWindowContent
  );
};

export default ChatWindow;
