import {
  getFormattedDate,
  isMobileDevice,
  tutorialContainerSize,
} from "./helpers/utils";
import { useDispatch, useSelector } from "react-redux";

import AppNav from "./AppNav";
import CourseCurriculum from "./CourseCurriculum";
import { Link } from "react-router-dom";
import NonTutorialMenu from "./NonTutorialMenu";
import React from "react";
import ReactTooltip from "react-tooltip";
import ScreenRenderer from "./ScreenRenderer";
import { actions } from "./redux/StateReducer";

const BookmarksContainer = (props) => {
  const dispatch = useDispatch();

  const courseCurriculumOpen = useSelector(
    (state) => state.courseCurriculumOpen
  );
  const notes = useSelector((state) => state.notes);
  const screenListOpen = useSelector((state) => state.screenListOpen);
  const user = useSelector((state) => state.user);

  const [selectedId, setSelectedId] = React.useState(
    (notes.length && notes[0]?.id) || null
  );

  React.useEffect(() => {
    // Fetching notes here since it'll only need to be dynamic in tutorials
    if (user) {
      fetch(`/api/notes/`)
        .then((res) => {
          return res.json();
        })
        .then(
          (obj) => {
            // fixme
            dispatch({
              type: actions.SET_NOTES,
              payload: obj,
            });
            setSelectedId(obj[0].id);
          },
          (error) => {
            console.log(error);
          }
        );
    }

    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    document.getElementById("tutorial-nav-container").style.height =
      vh - document.getElementById("algodaily-nav")?.offsetHeight + "px";

    window.scrollTo(0, 0);
  }, [user]);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <AppNav completed={false} title="My Bookmarks" />
      <div className="row no-gutters w-100">
        {courseCurriculumOpen || isMobileDevice() ? <CourseCurriculum /> : null}
        <ReactTooltip className="tooltip-z-index" />

        <div
          id="tutorial-container"
          className={`${tutorialContainerSize(
            courseCurriculumOpen,
            screenListOpen
          )} ${
            isMobileDevice()
              ? "p-2"
              : courseCurriculumOpen
              ? "p-4 px-5"
              : "py-4 px-6"
          } bg-light mh-100`}
        >
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="font-weight-bold text-center">My Bookmarks</h2>
          </div>
          <div className="card bg-white">
            <div className="card-body p-0">
              <p className="small m-4">
                <strong
                  data-tip="<div style='max-width: 24rem;'>The below bookmarks are screens added as you browsed through tutorials. You are encouraged to review them here, or save them as a PDF for offline prep.</div>"
                  data-html={true}
                >
                  How do I use the below bookmarks?{" "}
                  <i className="fas fa-info-circle"></i>
                </strong>
              </p>
              <div className="list-group">
                {notes && notes.length ? (
                  notes.map((n) => (
                    <>
                      <li
                        className={`list-group-item cursor-pointer d-flex justify-content-between align-items-center ${
                          selectedId === n.id ? "bg-info text-white" : ""
                        }`}
                        onClick={() =>
                          selectedId === n.id
                            ? setSelectedId(null)
                            : setSelectedId(n.id)
                        }
                      >
                        <strong>
                          {" "}
                          <i
                            className={`fa ${
                              selectedId === n.id
                                ? "fa-angle-double-down"
                                : "fa-angle-double-right"
                            } mr-2`}
                          />{" "}
                          {n.screen.title}
                        </strong>
                        <small>
                          Saved on {getFormattedDate(new Date(n.created_at))}
                        </small>
                      </li>

                      {n.id === selectedId ? (
                        <div className="p-4">
                          <ScreenRenderer screen={n.screen} screens={[]} />
                          <small>
                            <Link
                              to={
                                n.screen.lesson_id
                                  ? `/lessons/${
                                      n.tutorial_slug
                                    }#${n.screen.slug.replace(
                                      /[^a-zA-Z0-9_-]/g,
                                      ""
                                    )}`
                                  : `/challenges/${
                                      n.tutorial_slug
                                    }#${n.screen.slug.replace(
                                      /[^a-zA-Z0-9_-]/g,
                                      ""
                                    )}`
                              }
                            >
                              Navigate to original tutorial
                            </Link>
                          </small>
                        </div>
                      ) : null}
                    </>
                  ))
                ) : (
                  <p className="border-top p-4">
                    You currently have no bookmarks added. Hover over any
                    passage or screen in a tutorial, and then clip it here for
                    future reference.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {screenListOpen ? <NonTutorialMenu notes={notes} user={user} /> : null}
      </div>
    </>
  );
};

export default BookmarksContainer;
