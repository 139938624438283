import "./TestsPassedModal.css";

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ShareButtons from "./ShareButtons";
import { actions } from "./redux/StateReducer";
import { useHistory } from "react-router-dom";
import { wipeCompletionsFromLocalStorage } from "./helpers/utils";

function TestsPassedModal(props) {
  const dispatch = useDispatch();

  const challenge = useSelector((state) => state.challenge);
  const lesson = useSelector((state) => state.lesson);
  const user = useSelector((state) => state.user);
  const screens = lesson ? lesson.screens : challenge.screens;
  const nextArr = lesson ? lesson.nextArr : challenge.nextArr;

  React.useEffect(() => {
    const guide = challenge ? challenge : lesson;
    wipeCompletionsFromLocalStorage(guide, screens);
  }, []);

  const svgAttr = {
    version: "1.1",
    id: "Layer_1",
    xmlns: "http://www.w3.org/2000/svg",
    "xmlns:xlink": "http://www.w3.org/1999/xlink",
    x: "0px",
    y: "0px",
    viewBox: "0 0 98.5 98.5",
    "enable-background": "new 0 0 98.5 98.5",
    "xml:space": "preserve",
  };

  const history = useHistory();

  return (
    <div className="modal d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog shadow" role="document">
        <div
          className="modal-content text-white p-3 shadow-lg"
          style={{ backgroundColor: "#2d2d2d" }}
        >
          <div className="modal-header">
            <h4 className="modal-title m-auto">CONGRATS!</h4>
          </div>
          <div className="modal-body">
            <div className="wrapper">
              <svg {...svgAttr}>
                <path
                  className="checkmark"
                  fill="none"
                  stroke-width="8"
                  stroke-miterlimit="10"
                  d="M81.7,17.8C73.5,9.3,62,4,49.2,4
	C24.3,4,4,24.3,4,49.2s20.3,45.2,45.2,45.2s45.2-20.3,45.2-45.2c0-8.6-2.4-16.6-6.5-23.4l0,0L45.6,68.2L24.7,47.3"
                />
              </svg>
            </div>
            <p>
              You've completed the{" "}
              <strong>{challenge ? challenge.title : lesson.title}</strong>{" "}
              {challenge ? "challenge!" : "lesson!"} Amazing work.
            </p>
            {user ? (
              <>
                <p>
                  This has been{" "}
                  <a
                    href="/activity-log"
                    target="_blank"
                    class="text-white font-weight-bold"
                  >
                    saved as completed
                  </a>{" "}
                  to your profile. Let's move on to the next step of the
                  curriculum!
                </p>
                <p>
                  Want to share your progress? Click one of the below buttons!
                </p>
                <ShareButtons />
              </>
            ) : (
              <p className="text-danger font-weight-bold">
                Warning: This completion <em>was not</em> saved because you are
                not logged in! Please{" "}
                <a href="/users/sign_in" target="_blank">
                  sign in or sign up
                </a>{" "}
                to save your progress.
              </p>
            )}
          </div>
          <div className="modal-footer d-flex justify-content-between">
            <button
              type="button"
              className="genric-btn info my-2"
              data-dismiss="modal"
              onClick={() =>
                dispatch({
                  type: actions.SET_SHOW_TESTS_PASSED,
                  payload: false,
                })
              }
            >
              GO BACK
            </button>
            <button
              type="button"
              className="genric-btn info my-2"
              onClick={() => {
                dispatch({
                  type: actions.SET_SHOW_TESTS_PASSED,
                  payload: false,
                });
                history.push(
                  `/${nextArr[0].toLowerCase() + "s"}/${nextArr[1]}`
                );
              }}
            >
              NEXT STEP
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestsPassedModal;
