import { LANG_TO_ALGODAILY_ID, postCompletion } from "../helpers/utils";

export const defaultChallenge = {
  initialCode: "",
  solutionCode: "",
  slug: "",
  full_screen: false,
  kind: "question",
};

export const generateInitialState = (props) => {
  return {
    activeSections: [],
    challenge: {
      initialCode: "",
      solutionCode: "",
      slug: "",
    },
    course: {
      title: "Loading...",
      sections: [],
    },
    courses: [],
    courseCurriculumOpen:
      localStorage && localStorage.getItem("adCourseCurriculumOpen")
        ? localStorage.getItem("adCourseCurriculumOpen") === "true"
        : true,
    courseMode: (localStorage && localStorage.getItem("adLeftRail")) || "menu",
    crashCourse: props?.crashCourse || [],
    curriculum: [],
    jobApps: [],
    language: props?.language || "javascript",
    footerLinks: props?.footerLinks || { categories: [], links: [] },
    lesson: props?.lesson,
    notes: [],
    relatedChallenges: props?.relatedChallenges,
    screenCompletions: [], // may not be necessary
    screenListOpen: true,
    showTestPassed: false,
    user: props?.user,
    view: props?.user?.view || "article",
    vizContainerVisible: false,
  };
};

export default function StateReducer(state = generateInitialState(), action) {
  const { type, payload } = action;

  switch (type) {
    case "ADD_CHALLENGE_COMPLETION":
      const { challenge, language, user } = state;
      const { time } = payload;

      if (user) {
        const $codeMirrorEditor = $(".editor-fixed .CodeMirror");

        const userCode =
          $codeMirrorEditor.length >= 1
            ? $codeMirrorEditor[0].CodeMirror.getValue()
            : "";

        let body = JSON.stringify({
          completion: {
            userEmail: user.email,
            slug: challenge.slug,
            challenge_id: challenge.id,
            code: `\`\`\`${language}\n${userCode}\n\`\`\``,
            performance: time,
            language_id: LANG_TO_ALGODAILY_ID[language],
          },
        });

        postCompletion("challenges", challenge.slug, body, (response) => {
          return response.json();
        });
      }
      return state;
    case "ADD_SECTION":
      return {
        ...state,
        sections: [...state.sections, payload],
      };
    case "DELETE_JOB_APP":
      const { jobApps } = state;
      const newJobApps = jobApps.filter((j) => j.id !== parseInt(payload));

      return { ...state, jobApps: newJobApps };
    case "SET_APP_LANGUAGE":
      return { ...state, language: payload };
    case "SET_APP_LESSON":
      return { ...state, lesson: payload, challenge: defaultChallenge };
    case "SET_APP_CHALLENGE":
      return { ...state, challenge: payload, lesson: null };
    case "SET_ACTIVE_SECTIONS":
      return { ...state, activeSections: payload };
    case "SET_COURSE": {
      let course = payload;
      // Don't overwrite course w/ sections
      if (
        state.course.id === payload.id &&
        !payload.sections &&
        state.course.sections &&
        state.course.sections.length > 0
      ) {
        course = state.course;
      }

      // Make sure to overwrite course in courses to stay in sync
      const newCourses = state.courses.map((c) => {
        if (c.id === course.id) {
          return course;
        } else {
          return c;
        }
      });

      return { ...state, course: course, courses: newCourses };
    }
    case "SET_COURSES": {
      const existingCoursesWithSections = state.courses.filter(
        (c) => c.sections
      );
      const newCourses = payload.map((c) => {
        if (
          state.course &&
          state.course.id === c.id &&
          state.course.sections &&
          state.course.sections.length > 0
        ) {
          // If the current course has sections, make sure to keep them
          return state.course;
        } else if (
          existingCoursesWithSections.find((ec) => ec.slug === c.slug)
        ) {
          // Make sure to not overwrite courses with sections/more data
          return existingCoursesWithSections.find((ec) => ec.id === c.id);
        } else {
          return c;
        }
      });

      return { ...state, courses: newCourses };
    }
    case "SET_COURSE_CURRICULUM_OPEN":
      return { ...state, courseCurriculumOpen: payload };
    case "SET_COURSE_MODE":
      return { ...state, courseMode: payload };
    case "SET_JOB_APPS":
      return { ...state, jobApps: payload };
    case "SET_NOTES":
      return { ...state, notes: payload };
    case "SET_SCREEN_LIST_OPEN":
      return { ...state, screenListOpen: payload };
    case "SET_SHOW_TESTS_PASSED":
      return { ...state, showTestPassed: payload };
    case "SET_VIEW":
      return { ...state, view: payload };
    case "SET_VIZ_CONTAINER":
      return { ...state, vizContainerVisible: payload };
    default:
      return state;
  }
}

export const actions = {
  ADD_CHALLENGE_COMPLETION: "ADD_CHALLENGE_COMPLETION",
  ADD_SECTION: "ADD_SECTION",
  DELETE_JOB_APP: "DELETE_JOB_APP",
  SET_APP_LANGUAGE: "SET_APP_LANGUAGE",
  SET_APP_LESSON: "SET_APP_LESSON",
  SET_APP_CHALLENGE: "SET_APP_CHALLENGE",
  SET_ACTIVE_SECTIONS: "SET_ACTIVE_SECTIONS",
  SET_COURSE: "SET_COURSE",
  SET_COURSES: "SET_COURSES",
  SET_COURSE_CURRICULUM_OPEN: "SET_COURSE_CURRICULUM_OPEN",
  SET_COURSE_MODE: "SET_COURSE_MODE",
  SET_JOB_APPS: "SET_JOB_APPS",
  SET_NOTES: "SET_NOTES",
  SET_SCREEN_COMPLETIONS: "SET_SCREEN_COMPLETIONS",
  SET_SCREEN_LIST_OPEN: "SET_SCREEN_LIST_OPEN",
  SET_SHOW_TESTS_PASSED: "SET_SHOW_TESTS_PASSED",
  SET_VIEW: "SET_VIEW",
  SET_VIZ_CONTAINER: "SET_VIZ_CONTAINER",
};
