import StateReducer, { generateInitialState } from "./StateReducer";

import { configureStore } from "@reduxjs/toolkit";

export function assignPropsToStore(props) {
  const initialState = generateInitialState(props);
  const store = configureStore({
    reducer: StateReducer,
    preloadedState: initialState,
  });
  return store;
}
