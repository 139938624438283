import { Link, useParams } from "react-router-dom";
import React, { useState } from "react";
import { addSectionCompletion, isSectionComplete } from "./helpers/completions";
import { useDispatch, useSelector } from "react-redux";

import Ad from "./Ad";
import Badges from "./Badges";
import FooterLinks from "./FooterLinks";
import { Helmet } from "react-helmet";
import { RIETextArea } from "riek";
import ReactMarkdown from "react-markdown/with-html";
import SectionLessonCard from "./SectionLessonCard";
import Skeleton from "react-loading-skeleton";
import { actions } from "./redux/StateReducer";
import { isMobileDevice } from "./helpers/utils";

const Course = (props) => {
  const { courseId } = useParams();
  const dispatch = useDispatch();

  const [sectionCompletions, setSectionCompletions] = useState({});
  const [view, setView] = useState(isMobileDevice() ? "grid" : "list");

  const course = useSelector((state) => state.course);
  const courses = useSelector((state) => state.courses);
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    const existingCourse = courses.find(
      (c) => c.id === parseInt(courseId) || c.slug === courseId
    );
    if (existingCourse && existingCourse.sections?.length > 0) {
      dispatch({
        type: actions.SET_COURSE,
        payload: existingCourse,
      });
    } else {
      fetch(`/api/courses/${courseId}`)
        .then((res) => {
          return res.json();
        })
        .then(
          (obj) => {
            if (obj.locked && obj.redirect) {
              return window.location.replace(obj.redirect);
            }

            window.scrollTo(0, 0);

            dispatch({
              type: actions.SET_COURSE,
              payload: obj,
            });
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            // TO DO: handle errors
            // this.setState({
            //   isLoaded: true,
            //   error,
            // });
          }
        );
    }
  }, [courseId]);

  React.useEffect(() => {
    const newSectionCompletions = {};

    if (course && course.sections) {
      course.sections
        .filter((s) => s.course_id == course.id)
        .forEach((sec) => {
          newSectionCompletions[sec.id] = {
            lessons: sec.lessons.filter((l) => l.completed),
            challenges: sec.challenges.filter((c) => c.completed),
          };
        });

      setSectionCompletions(newSectionCompletions);
    }

    if (!user) {
      window.addEventListener("load", function () {
        var ezstandalone = window.ezstandalone || {};
        ezstandalone.cmd = ezstandalone.cmd || [];

        if (
          ezstandalone.placeholders.includes(129) &&
          ezstandalone.placeholders.includes(117)
        ) {
          ezstandalone.cmd.push(function () {
            ezstandalone.refresh();
          });
        } else {
          ezstandalone.cmd.push(function () {
            ezstandalone.displayMore(129, 117);
          });
        }
      });
    }
  }, [course]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function updateCourse(id, newCourse) {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    fetch(`/api/courses/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: JSON.stringify({ course: newCourse }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then(
        (obj) => {
          return true;
        },
        (error) => {
          console.log(error);
        }
      );
  }

  function updateRenderedLesson(section, les, flag) {
    // Update current section
    const newSectionCompletions = { ...sectionCompletions };
    const newLessons = [...newSectionCompletions[section.id].lessons];
    if (flag) {
      newLessons.push(les);
    } else {
      const index = newLessons.findIndex((l) => l.slug === les.slug);
      if (index > -1) {
        newLessons.splice(index, 1);
      }
    }
    newSectionCompletions[section.id].lessons = newLessons;

    setSectionCompletions(newSectionCompletions);

    // Update lesson in menu
    const newSections = JSON.parse(JSON.stringify(course.sections));
    const sectionIdx = newSections.findIndex((sec) => sec.id === section.id);
    const tutorialIdx = newSections[sectionIdx].lessons.findIndex(
      (t) => t.slug === les.slug
    );
    newSections[sectionIdx].lessons[tutorialIdx].completed = flag;

    // TODO: fix once challenges have a single section to reflect both
    if (flag) {
      // if completed = true
      if (isSectionComplete(newSections[sectionIdx])) {
        addSectionCompletion(newSections[sectionIdx]);
      }
    }

    dispatch({
      type: actions.SET_COURSE,
      payload: { ...course, sections: newSections },
    });
  }

  return (
    <>
      <Helmet>
        <title>
          {(course && course.title && "AlgoDaily - " + course.title) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."}
        </title>
        <meta
          name="description"
          content={
            (course && course.description) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:image"
          content={
            course && course.image_url
              ? course.image_url
              : `https://storage.googleapis.com/algodailyrandomassets/curriculum/${course.slug}/cover.jpg`
          }
        />
        <meta
          property="og:url"
          content={
            (course && `https://algodaily.com/courses/${course.slug}`) ||
            "https://algodaily.com"
          }
        />
        <meta
          property="og:title"
          content={
            (course && course.title && "AlgoDaily - " + course.title) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta
          property="og:description"
          content={
            (course && course.description) ||
            "Programming interview prep bootcamp with coding challenges and practice. Daily coding interview questions. Software interview prep made easy."
          }
        />
        <meta property="og:image" content={course && course.image_url} />
      </Helmet>
      <div
        className={`${
          isMobileDevice() ? "p-2" : "p-0"
        } container position-relative`}
      >
        {course ? (
          <div className="small mb-2">
            {user ? <Link to={`/dashboard`}>Home</Link> : <a href="/">Home</a>}{" "}
            >{" "}
            <Link to={`/courses/${course.slug}`}>
              <strong>{course.title || <Skeleton />}</strong>
            </Link>
          </div>
        ) : null}

        <h1 className="display-4 overflow-auto">
          {course.title || <Skeleton />}
        </h1>

        <div
          class="border bg-white no-gutters rounded-lg row d-flex align-items-stretch mb-4"
          style={{
            // webkit
            WebkitAlignItems: "stretch",
          }}
        >
          <div
            className="col-lg-4"
            style={{
              backgroundImage: `url(${course.image_url})`,
              backgroundSize: "cover",
              ...(isMobileDevice() ? { height: "12em" } : {}),
            }}
          >
            {/* {course.image_url ? (
              <img
                src={course.image_url}
                className="img-fluid"
                loading="lazy"
                style={{
                  objectFit: "cover",
                }}
              />
            ) : (
              <Skeleton count={10} />
            )} */}
          </div>
          <div className="col-lg-8 p-4">
            <h3 className="font-weight-bold">
              {course.kind == "personalized"
                ? ""
                : (course.users_taking &&
                    course.users_taking.toLocaleString() +
                      " users have taken this course.") || <Skeleton />}
            </h3>
            {course.description ? (
              <p>
                {user?.admin ? (
                  <RIETextArea
                    value={course.description}
                    change={(data) => {
                      const newCourse = {
                        ...course,
                        description: data.description,
                      };

                      updateCourse(course.slug, {
                        description: data.description,
                      });

                      dispatch({
                        type: actions.SET_COURSE,
                        payload: newCourse,
                      });
                    }}
                    classEditing="form-control"
                    propName="description"
                    rows={6}
                  />
                ) : (
                  <ReactMarkdown
                    source={course.description}
                    escapeHtml={false}
                  />
                )}
              </p>
            ) : (
              <Skeleton />
            )}
            <div className="w-75 d-flex flex-wrap mb-2">
              <Badges obj={course} />
            </div>
          </div>
        </div>

        {!(user && user.paid_active) && (
          <Ad kind="wide" />
          // <div id="ezoic-pub-ad-placeholder-129" className="w-100"></div>
        )}

        <div className="d-flex flex-row align-items-center justify-content-between">
          <h2 className="font-weight-bold mb-2 p-0">Course Curriculum</h2>
          {!isMobileDevice() && !course.mini_course ? (
            <div>
              <span
                className={`ml-2 cursor-pointer ${
                  view === "list" ? "font-weight-bold" : "text-silver"
                }`}
                onClick={() => setView("list")}
              >
                <i className="fa fa-list"></i> List
              </span>
              <span
                className={`ml-2 cursor-pointer ${
                  view !== "list" ? "font-weight-bold" : "text-silver"
                }`}
                onClick={() => setView("grid")}
              >
                <i className="fa fa-th"></i> Grid
              </span>
            </div>
          ) : null}
        </div>

        {course.sections && course.sections.length && course.sections.length < 2
          ? course.sections.map((s, idx) => {
              // currently on DS&A has challenges
              const compLes = sectionCompletions[s.id]?.lessons;
              const completedLesIds = compLes?.map((l) => l.id) || [];

              return (
                <>
                  <p className="mb-4">
                    <strong
                      data-tip="<div style='max-width: 24rem;'>This is a crash course. As opposed to full courses like Data Structures and Algorithms or Systems Design and Architecture, these mini-courses only comprise of a single section that encapsulates all the lessons. We display all lessons below for your convenience.</div>"
                      data-html={true}
                    >
                      <Link to={`/sections/${s.slug}`}>
                        Section {idx + 1}. {s.title}{" "}
                      </Link>{" "}
                      <i className="fas fa-info-circle"></i>
                    </strong>
                  </p>

                  <div className="row">
                    {s.lessons.map((l, idx) => (
                      <SectionLessonCard
                        completedLesIds={completedLesIds}
                        idx={idx}
                        key={idx}
                        lesson={l}
                        updateRenderedLesson={(lesson, flag) =>
                          updateRenderedLesson(s, lesson, flag)
                        }
                        user={user}
                      />
                    ))}
                  </div>
                </>
              );
            })
          : null}
        {course.sections &&
        course.sections.length &&
        course.sections.length >= 2 ? (
          <div className="row">
            {course.sections.map((s, idx) => {
              const compLesCount = sectionCompletions[s.id]?.lessons.length;
              const compChalCount = sectionCompletions[s.id]?.challenges.length;
              const lessonsLength = s.lessons.length;
              const challengesLength = s.challenges.length;

              const sectionComplete =
                compLesCount === lessonsLength &&
                compChalCount === challengesLength;

              const miniCourse = view === "list"; // || course.mini_course;

              return (
                <div
                  className={`${miniCourse ? "col-lg-12" : "col-lg-4"} mb-4`}
                  key={idx}
                >
                  <Link to={`/sections/${s.slug}`}>
                    <div
                      className={`card ${
                        sectionComplete ? "bg-light" : "bg-white text-dark "
                      } border-0 shadow rounded-lg position-relative ${
                        miniCourse ? "flex-lg-row" : ""
                      }`}
                      style={
                        sectionComplete
                          ? {
                              color: "silver",
                            }
                          : null
                      }
                    >
                      <img
                        src={s.image_url}
                        className="card-img-top rounded-top bg-white cursor-pointer"
                        style={
                          miniCourse
                            ? {
                                objectFit: "cover",
                                width: "12em",
                                justifySelf: "flex-start",
                                maxHeight: "16em",
                                opacity: sectionComplete ? 0.3 : 1,
                              }
                            : {
                                objectFit: "cover",
                                height: "12em",
                                opacity: sectionComplete ? 0.3 : 1,
                              }
                        }
                        loading="lazy"
                      />

                      <div className="card-body py-auto cursor-pointer">
                        <small>
                          SECTION {idx + 1}{" "}
                          {sectionComplete ? (
                            <i className="fa fa-check text-success ml-1" />
                          ) : null}
                        </small>
                        <h4
                          className="font-weight-bold mb-0"
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                          title={s.title}
                        >
                          {s.title}{" "}
                        </h4>

                        <div
                          className="my-2"
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebKitLineClamp: "4",
                            WebKitBoxOrient: "vertical",
                          }}
                        >
                          {s.description ? (
                            <p>
                              <ReactMarkdown
                                source={s.description}
                                escapeHtml={false}
                              />
                            </p>
                          ) : (
                            <Skeleton />
                          )}
                        </div>
                        <div className="d-flex mb-2 flex-wrap">
                          <Badges obj={s} sectionComplete={sectionComplete} />
                        </div>
                      </div>
                      <div
                        className="card-footer d-flex small align-items-center"
                        style={
                          miniCourse
                            ? {
                                width: "16rem",
                                flexBasis: "0",
                              }
                            : null
                        }
                      >
                        {user && (compLesCount || compChalCount) ? (
                          <span>
                            {sectionComplete ? (
                              "This section is completed!"
                            ) : (
                              <>
                                Completed {miniCourse ? <br /> : ""}
                                <strong>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: [
                                        compLesCount
                                          ? `${compLesCount}/${lessonsLength} lessons`
                                          : "",
                                        compChalCount
                                          ? `${compChalCount}/${challengesLength} challenges`
                                          : "",
                                      ]
                                        .filter(Boolean)
                                        .join(
                                          miniCourse ? "<br>and<br />" : " & "
                                        ),
                                    }}
                                  />
                                </strong>
                              </>
                            )}
                          </span>
                        ) : (
                          <span>No completed work yet.</span>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}{" "}
          </div>
        ) : null}
        {!(user && user.paid_active) && (
          <Ad kind="wide" />
          // <div id="ezoic-pub-ad-placeholder-117" className="w-100"></div>
        )}

        <FooterLinks footerLinks={props.footerLinks} />
      </div>
    </>
  );
};

export default Course;
